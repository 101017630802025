
import * as React from 'react'
import { Text, View, ScrollView, TouchableOpacity, Image, useWindowDimensions, Linking } from 'react-native'
import { useTheme, CommonActions } from '@react-navigation/native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import images from '../../assets'
import 'moment-lunar'
import { SlideAnimation, Modal } from 'react-native-modals'
import { setModalTripInfo } from '../redux/ActionCreators'
import moment from 'moment'

export default React.memo(function ModalTripInfo({ trip }) {
    const { colors, fontFamily } = useTheme()
    const insets = useSafeAreaInsets()
    const modal_trip_info = useSelector(state => state.modal_trip_info, shallowEqual)
    const dispatch = useDispatch()
    console.log(trip)

    const handleClick = (url) => {
        Linking.canOpenURL(url).then(supported => {
            if (supported) {
                Linking.openURL(url)
            } else {
                console.log("Don't know how to open URI: " + url)
            }
        });
    };

    return (
        <Modal
            visible={modal_trip_info}
            modalAnimation={new SlideAnimation({
                slideFrom: 'bottom',
            })}
            onTouchOutside={() => {
                dispatch(setModalTripInfo(false))
            }}
            width={1}
            height={1}
        >
            <View style={{ flex: 1, backgroundColor: 'white' }}>
                <View style={{ backgroundColor: colors.primary, paddingTop: insets.top ? insets.top : 0 }}>
                    <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', height: 48, paddingHorizontal: 8 }}>
                        <TouchableOpacity style={{ padding: 8 }} onPress={() => dispatch(setModalTripInfo(false))}>
                            <Image source={images.icons.close} style={{ width: 16, height: 16 }} />
                        </TouchableOpacity>
                        <Text style={{ flex: 1, textAlign: 'center', color: colors.text, fontFamily: 'Nunito_700Bold', fontSize: 18 }}>
                            Thông tin chuyến đi
                        </Text>
                        <TouchableOpacity style={{ padding: 8, opacity: 0 }}>
                            <Image source={images.icons.close} style={{ width: 16, height: 16 }} />
                        </TouchableOpacity>
                    </View>
                </View>
                <ScrollView showsVerticalScrollIndicator={false}>
                    <TouchableOpacity activeOpacity={.99} style={{ paddingVertical: 16 }}>
                        <View style={{ paddingHorizontal: 16 }}>
                            <Text style={{ fontSize: 16, fontFamily: fontFamily.bold, marginBottom: 8 }}>Thông tin chuyến đi</Text>
                            <View style={{ flexDirection: 'row' }}>
                                <Text style={{ width: 90 }}>Tên tuyến: </Text>
                                <Text style={{ fontFamily: fontFamily.semi_bold }}>{trip.route?.name}</Text>
                            </View>
                            <View style={{ flexDirection: 'row' }}>
                                <Text style={{ width: 90 }}>Khởi hành: </Text>
                                <Text style={{ fontFamily: fontFamily.semi_bold }}>{
                                    moment(trip.node_time).format('HH:mm DD/MM/YYYY')
                                }</Text>
                            </View>
                            {
                                trip.vehicle ? <View style={{ flexDirection: 'row' }}>
                                    <Text style={{ width: 90 }}>Biển kiểm soát: </Text>
                                    <Text style={{ fontFamily: fontFamily.semi_bold }}>{trip.vehicle}</Text>
                                </View> : null
                            }

                            <View style={{ flexDirection: 'row' }}>
                                <Text style={{ width: 90 }}>Loại xe: </Text>
                                <Text style={{ fontFamily: fontFamily.semi_bold }}>{trip.product?.name}</Text>
                            </View>
                            <View style={{ flexDirection: 'row' }}>
                                <Text style={{ width: 90 }}>Số ghế trống: </Text>
                                <Text style={{ fontFamily: fontFamily.semi_bold }}>{trip.available_seats}/{trip.total_seats}</Text>
                            </View>
                            {
                                trip.note ? <View style={{ flexDirection: 'row' }}>
                                    <Text style={{ width: 90 }}>Ghi chú: </Text>
                                    <Text style={{ fontFamily: fontFamily.semi_bold }}>{trip.note}</Text>
                                </View> : null
                            }
                            <View style={{ width: '100%', height: 1, backgroundColor: '#E9E9E9', marginVertical: 12 }} />
                        </View>

                        <View style={{ paddingHorizontal: 16 }}>
                            <Text style={{ fontSize: 16, fontFamily: fontFamily.bold, marginBottom: 8 }}>Thông tin liên hệ</Text>
                            <Text>Đầu mối liên hệ chăm sóc đại lý</Text>
                            <TouchableOpacity style={{
                                flexDirection: 'row', height: 40, justifyContent: 'center', alignItems: 'center',
                            }} onPress={() => handleClick('tel:19006763')}>
                                <Image source={images.icons.contact_phone} style={{ width: 16, height: 16, marginRight: 6 }} />
                                <Text style={{ fontFamily: fontFamily.semi_bold, flex: 1 }}>Cập nhật sau</Text>
                            </TouchableOpacity>

                            <Text>Hòm thư điện tử</Text>
                            <TouchableOpacity style={{
                                flexDirection: 'row', height: 40, justifyContent: 'center', alignItems: 'center',
                            }} onPress={() => handleClick('mailto:cskh@haivan.com')}>
                                <Image source={images.icons.contact_mail} style={{ width: 16, height: 16, marginRight: 6 }} />
                                <Text style={{ fontFamily: fontFamily.semi_bold, flex: 1 }}>cskh@haivan.com</Text>
                            </TouchableOpacity>


                            <View style={{ width: '100%', height: 1, backgroundColor: '#E9E9E9', marginTop: 6, marginBottom: 12 }} />
                        </View>

                        <View style={{ paddingHorizontal: 10 }}>
                            <Text style={{ fontSize: 16, fontFamily: fontFamily.bold, marginBottom: 8, marginHorizontal: 6 }}>Tiện ích trên xe</Text>
                            <View style={{ flexDirection: 'row' }}>
                                {
                                    trip.amenities?.map((item, idx) => {
                                        return <View key={idx} style={{ alignItems: 'center', width: 55, marginHorizontal: 6 }}>
                                            <Image source={{ uri: item.ame_link }} style={{ width: 32, height: 32 }} />
                                            <Text style={{ fontSize: 11, fontFamily: fontFamily.semi_bold, textAlign: 'center' }}>{item.ame_name}</Text>
                                        </View>
                                    })
                                }
                            </View>
                        </View>
                    </TouchableOpacity>
                </ScrollView>
            </View>
        </Modal>
    )
})