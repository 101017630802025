
import { StatusBar } from 'expo-status-bar'
import React from 'react'
import { Text, TextInput, View } from 'react-native'
import FlashMessage from "react-native-flash-message"
import { AccountManage, Booking, Contact, EditInfo, EditTransit, Login, Main, ModalCalendar, ModalPlace, Payment, Points, Seats, TicketInfo, Trips, UpdateTicket } from './src/screens'
import { createStackNavigator } from '@react-navigation/stack'
import { NavigationContainer, useNavigationContainerRef } from '@react-navigation/native'
import { ModalPortal } from 'react-native-modals'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import axios from 'axios'
import { setUserLogin } from './src/redux/ActionCreators'

const Stack = createStackNavigator()

const MyTheme = {
    colors: {
        primary: '#FFC20E',
        background: '#F0F2F5',
        text: '#050505'
    },
    fontFamily: {
        regular: 'Nunito_400Regular',
        semi_bold: 'Nunito_600SemiBold',
        bold: 'Nunito_700Bold',
        black: 'Nunito_900Black',
    }
}

export default function Route() {
    Text.defaultProps = Text.defaultProps || {}
    Text.defaultProps.allowFontScaling = false
    TextInput.defaultProps = TextInput.defaultProps || {}
    TextInput.defaultProps.allowFontScaling = false
    TextInput.defaultProps.underlineColorAndroid = 'rgba(0,0,0,0)'
    TextInput.defaultProps.selectionColor = '#FFC20E'
    const navigationRef = useNavigationContainerRef()
    const dispatch = useDispatch()
    const user_login = useSelector(state => state.user_login, shallowEqual)

    if (user_login) {
        axios.defaults.headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': user_login.token_type + ' ' + user_login.access_token
        }
        axios.interceptors.response.use(function (response) {
            return response;
        }, function (error) {
            if (error.response.status === 401) {
                dispatch(setUserLogin(null))
                navigationRef.resetRoot({
                    index: 0,
                    routes: [{ name: 'Login' }],
                });
            }
            return Promise.reject(error);
        });
    }

    const insets = useSafeAreaInsets()

    const linking = {
        config: {
            screens: {
                Login: 'login',
                Main: {
                    screens: {
                        TabHome: 'home',
                        TabTicket: 'ticket',
                        TabChange: 'balance-change',
                        TabAccount: 'account',
                    },
                },
                Booking: 'info',
                ModalCalendar: 'select-date',
                ModalPlace: 'select-place',
                Payment: 'payment',
                Points: 'change-point',
                Seats: 'choose-seat',
                Trips: 'trip',
                AccountManage: 'account-manage',
                TicketInfo: 'ticket-info',
                EditInfo: 'edit-info',
                Contact: 'contact',
            }
        },
    };

    return (
        // <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'center' }}>
        //     <View style={{ flex: 1, maxWidth: 768 }}>
        <>
            <StatusBar style="dark" />
            <NavigationContainer ref={navigationRef} theme={MyTheme} linking={linking}>
                <Stack.Navigator initialRouteName={user_login ? 'Main' : "Login"} screenOptions={{ presentation: 'card' }}>
                    <Stack.Group>
                        <Stack.Screen name="AccountManage" component={AccountManage} options={{ headerShown: false, headerMode: 'float' }} />
                        <Stack.Screen name="Booking" component={Booking} options={{ headerShown: false, headerMode: 'float' }} />
                        <Stack.Screen name="Contact" component={Contact} options={{ headerShown: false, headerMode: 'float' }} />
                        <Stack.Screen name="EditInfo" component={EditInfo} options={{ headerShown: false, headerMode: 'float' }} />
                        <Stack.Screen name="EditTransit" component={EditTransit} options={{ headerShown: false, headerMode: 'float' }} />
                        <Stack.Screen name="Login" component={Login} options={{ headerShown: false, headerMode: 'float' }} />
                        <Stack.Screen name="Main" component={Main} options={{ headerShown: false, headerMode: 'float' }} />
                        <Stack.Screen name="Payment" component={Payment} options={{ headerShown: false, headerMode: 'float' }} />
                        <Stack.Screen name="Points" component={Points} options={{ headerShown: false, headerMode: 'float' }} />
                        <Stack.Screen name="Seats" component={Seats} options={{ headerShown: false, headerMode: 'float' }} />
                        <Stack.Screen name="TicketInfo" component={TicketInfo} options={{ headerShown: false, headerMode: 'float' }} />
                        <Stack.Screen name="Trips" component={Trips} options={{ headerShown: false, headerMode: 'float' }} />
                        <Stack.Screen name="UpdateTicket" component={UpdateTicket} options={{ headerShown: false, headerMode: 'float' }} />
                    </Stack.Group>
                    <Stack.Group screenOptions={{ presentation: 'modal' }}>
                        <Stack.Screen name="ModalCalendar" component={ModalCalendar} options={{ headerShown: false }} />
                        <Stack.Screen name="ModalPlace" component={ModalPlace} options={{ headerShown: false }} />
                    </Stack.Group>
                </Stack.Navigator>
            </NavigationContainer>
            <ModalPortal />
            <FlashMessage position="top" floating={true} statusBarHeight={insets.top ? insets.top - 16 : 0} />
        </>
        //     </View>
        // </View>
    )
}