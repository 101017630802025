
import * as React from 'react'
import { Text, View, TouchableOpacity, Image } from 'react-native'
import { useTheme } from '@react-navigation/native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import images from '../../assets'
import { BottomModal } from 'react-native-modals'
import { Switch } from 'react-native-switch'
import moment from 'moment'
import _ from 'lodash'

export default React.memo(function SortFilterTrip({ callback, sort_filter }) {
    const { colors, fontFamily } = useTheme()
    const insets = useSafeAreaInsets()
    const [data, setData] = React.useState({
        visible: false,
        filter: sort_filter?.filter,
        backup_filter: sort_filter?.filter
    })
    const [sort, setSort] = React.useState(sort_filter?.sort ? sort_filter?.sort : { sort_by: 'time', sort_mode: 'asc' })
    // sortby: time, price - sortmode: asc, desc
    const list_time = [{ name: 'Tất cả', key: 0 }, { name: 'Buổi sáng (0h-12h)', key: 1 },
    { name: 'Buổi chiều (12h-18h)', key: 2 }, { name: 'Buổi tối (18h-24h)', key: 3 }]

    return (
        <>
            <View style={{ paddingHorizontal: 16, paddingTop: 8, paddingBottom: 8 + (insets.bottom ? insets.bottom - 16 : 0), backgroundColor: 'white', flexDirection: 'row' }}>
                <TouchableOpacity style={{
                    backgroundColor: sort.sort_by === 'time' ? colors.primary : '#F0F2F5', height: 32, alignItems: 'center', flexDirection: 'row',
                    borderRadius: 4, paddingHorizontal: 12
                }} onPress={() => {
                    if (sort.sort_by === 'price') {
                        sort.sort_by = 'time'
                        sort.sort_mode = 'asc'
                        setSort({ ...sort })
                        callback({
                            filter: {
                                available_seats: data.backup_filter.available_seats ? 1 : 0,
                                pickup_time_range: data.backup_filter.pickup_time_range
                            },
                            sort
                        })
                    }
                    else {
                        sort.sort_by = 'time'
                        sort.sort_mode = sort.sort_mode === 'asc' ? 'desc' : 'asc'
                        setSort({ ...sort })
                        callback({
                            filter: {
                                available_seats: data.backup_filter.available_seats ? 1 : 0,
                                pickup_time_range: data.backup_filter.pickup_time_range
                            },
                            sort
                        })
                    }
                }}>
                    <Text style={{ fontSize: 14, marginRight: 6, fontFamily: fontFamily.regular }}>Giờ xuất bến</Text>
                    <Image source={images.icons.sort} style={{
                        width: 10, height: 6, marginTop: 1,
                        transform: sort.sort_by === 'time' ? [{ rotate: sort.sort_mode === 'asc' ? '0deg' : '180deg' }] : []
                    }} />
                </TouchableOpacity>
                <TouchableOpacity style={{
                    backgroundColor: sort.sort_by === 'price' ? colors.primary : '#F0F2F5', height: 32, alignItems: 'center', flexDirection: 'row',
                    borderRadius: 4, paddingHorizontal: 12, marginLeft: 12
                }} onPress={() => {
                    if (sort.sort_by === 'time') {
                        sort.sort_by = 'price'
                        sort.sort_mode = 'asc'
                        setSort({ ...sort })
                        callback({
                            filter: {
                                available_seats: data.backup_filter.available_seats ? 1 : 0,
                                pickup_time_range: data.backup_filter.pickup_time_range
                            },
                            sort
                        })
                    }
                    else {
                        setSort({ sort_by: 'price', sort_mode: sort.sort_mode === 'asc' ? 'desc' : 'asc' })
                        sort.sort_by = 'price'
                        sort.sort_mode = sort.sort_mode === 'asc' ? 'desc' : 'asc'
                        setSort({ ...sort })
                        callback({
                            filter: {
                                available_seats: data.backup_filter.available_seats ? 1 : 0,
                                pickup_time_range: data.backup_filter.pickup_time_range
                            },
                            sort
                        })
                    }
                }}>
                    <Text style={{ fontSize: 14, marginRight: 6, fontFamily: fontFamily.regular }}>Giá vé</Text>
                    <Image source={images.icons.sort} style={{
                        width: 10, height: 6, marginTop: 1,
                        transform: sort.sort_by === 'price' ? [{ rotate: sort.sort_mode === 'asc' ? '0deg' : '180deg' }] : []
                    }} />
                </TouchableOpacity>
                <View style={{ flex: 1 }} />
                <TouchableOpacity style={{ justifyContent: 'center', alignItems: 'center', paddingLeft: 12 }}
                    onPress={() => setData({ ...data, visible: true })}>
                    <Image source={images.icons.filter} style={{ width: 24, height: 24 }} />
                </TouchableOpacity>
            </View>

            <BottomModal
                visible={data.visible}
                onTouchOutside={() => {
                    data.visible = false
                    data.backup_filter = { ...data.filter }
                    setData({ ...data })
                }}
                width={1}
                onSwipeOut={() => {
                    data.visible = false
                    data.backup_filter = { ...data.filter }
                    setData({ ...data })
                }}
                modalTitle={
                    <View style={{ backgroundColor: 'fff' }}>
                        <View style={{ alignItems: 'center', marginTop: 4 }}>
                            <View style={{ width: 37, height: 4, backgroundColor: '#CACBD0', borderRadius: 6 }} />
                        </View>
                        <Text style={{ marginTop: 16, fontSize: 22, paddingHorizontal: 16, fontFamily: fontFamily.regular }}>Lọc kết quả</Text>
                    </View>
                }
            >
                <View style={{ backgroundColor: 'fff', paddingBottom: insets.bottom }}>
                    <Text style={{ fontFamily: fontFamily.bold, fontSize: 16, marginTop: 16, paddingHorizontal: 16 }}>Giờ chạy</Text>
                    <View style={{ flexDirection: 'row', flexWrap: 'wrap', marginTop: 7, paddingHorizontal: 11 }}>
                        {
                            list_time.map((item, idx) => {
                                return <TouchableOpacity key={idx} onPress={() => {
                                    data.backup_filter.pickup_time_range = item.key
                                    setData({ ...data })
                                }}
                                    style={{
                                        margin: 4, borderRadius: 4, backgroundColor: idx === data.backup_filter.pickup_time_range ? colors.primary : 'white',
                                        borderColor: idx === data.backup_filter.pickup_time_range ? colors.primary : colors.text, borderWidth: 1
                                    }}>
                                    <Text style={{ paddingVertical: 5, paddingHorizontal: 10, fontFamily: fontFamily.regular }}>{item.name}</Text>
                                </TouchableOpacity>
                            })
                        }
                    </View>

                    <View style={{ flexDirection: 'row', marginTop: 18, marginBottom: 8, paddingHorizontal: 16 }}>
                        <Switch
                            value={data.backup_filter.available_seats ? false : true}
                            onValueChange={(available_seats) => {
                                data.backup_filter.available_seats = available_seats ? 0 : 1
                                setData({ ...data })
                            }}
                            circleSize={20}
                            barHeight={20}
                            circleBorderWidth={2}
                            backgroundActive={colors.primary}
                            backgroundInactive={'#CACBD0'}
                            circleActiveColor={'white'}
                            circleInActiveColor={'white'}
                            renderActiveText={false}
                            renderInActiveText={false}
                            switchLeftPx={2}
                            switchRightPx={2}
                            switchWidthMultiplier={2}
                            switchBorderRadius={10}
                        />
                        <Text style={{ marginLeft: 16, fontFamily: fontFamily.regular }}>Hiển thị cả chuyến hết chỗ</Text>
                    </View>

                    <TouchableOpacity style={{
                        height: 45, backgroundColor: colors.primary, margin: 16,
                        justifyContent: 'center', alignItems: 'center', borderRadius: 6
                    }} onPress={() => {
                        data.filter = { ...data.backup_filter }
                        data.visible = false
                        setData({ ...data })
                        callback({
                            filter: {
                                available_seats: data.backup_filter.available_seats ? 1 : 0,
                                pickup_time_range: data.backup_filter.pickup_time_range
                            },
                            sort
                        })
                    }}>
                        <Text style={{ fontSize: 16, fontFamily: fontFamily.semi_bold }}>Tìm chuyến</Text>
                    </TouchableOpacity>
                </View>
            </BottomModal>
        </>
    )
})
