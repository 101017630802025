
import * as React from 'react'
import { Text, View, ScrollView, TouchableOpacity, Image, ActivityIndicator, TextInput } from 'react-native'
import { useTheme } from '@react-navigation/native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { ModalJourney, Step } from '../components'
import images from '../../assets'
import axios from 'axios'
import { showMessage } from "react-native-flash-message"
import moment from 'moment'
import Collapsible from 'react-native-collapsible'
import CountDown from '../libs/countdown'
import { Modal, SlideAnimation } from 'react-native-modals'
import _ from 'lodash'
import qs from 'qs'

export default React.memo(function Points({ navigation, route }) {
    const { colors, fontFamily } = useTheme()
    const insets = useSafeAreaInsets()
    const [list_start_end, setListStartEnd] = React.useState({})
    const [collapse, setCollapse] = React.useState({
        start: false,
        end: false
    })
    const [collapse_trip, setCollapseTrip] = React.useState(false)
    const [progress, setProgress] = React.useState(false)
    const { trip, seats, booking } = route.params
    const reducer = (accumulator, currentValue) => accumulator + currentValue
    const [modal_search, setModalSearch] = React.useState({
        visible: false,
        is_start: true,
        search: null,
    })
    const [list_search, setListSearch] = React.useState([])

    React.useEffect(() => {
        axios.get(`trip-detail?trip_id=${trip.id}`).then(res => {
            console.log(res)
            let journey = res.data.journey
            let list_start = journey.filter(item => item.parent_place_id === trip.from.place_id)
            let list_end = journey.filter(item => item.parent_place_id === trip.to.place_id)
            setListStartEnd({ ...list_start_end, list_start, list_end, start: trip.from, end: trip.to, journey })
            console.log(list_start, list_end)
        })
    }, [])

    const toPayment = () => {
        const { pick_up, drop_off } = list_start_end
        const transit_start = list_start_end?.start, transit_end = list_start_end?.end
        if (transit_start && transit_end)
            navigation.navigate('Payment', { ...route.params, transit_start, transit_end, pick_up, drop_off })
    }

    const cancelBooking = () => {
        navigation.navigate({
            name: 'Trips',
            merge: true,
        })
    }

    const searchPlace = React.useCallback(_.debounce((value) => {
        console.log(value)
        if (value && value.trim().length > 3) {
            axios.get(`https://apigw.haivan.com/api/maps/places?input=${value}&channel=WEBHAIVAN`)
                .then(res => {
                    console.log(res)
                    if (res.status === 200 && res.data.data)
                        setListSearch(res.data.data)
                    else setListSearch([])
                })
        } else setListSearch([])
    }, 500), [])

    const detailPlace = (id) => {
        setModalSearch({ ...modal_search, visible: false })
        setProgress(true)
        axios.get(`https://apigw.haivan.com/api/maps/places/detail?place_id=${id}&channel=WEBHAIVAN`)
            .then(res => {
                console.log(res)
                if (res.status === 200) {
                    let place_ids = modal_search.is_start ? list_start_end.list_start.map(item => {
                        return item.place_id
                    }) : list_start_end.list_end.map(item => {
                        return item.place_id
                    })
                    let location = `${res.data.location.lat},${res.data.location.lng}`
                    let type = modal_search.is_start ? 1 : 2
                    let trip_id = trip.id

                    axios.get(`is_transit?${qs.stringify({
                        place_ids, location, type, trip_id
                    })}`)
                        .then(response => {
                            console.log(response)
                            if (modal_search.is_start) {
                                let pick_up = {
                                    "address": res.data.name,
                                    "coordinates": res.data.location,
                                    "is_transit": response.data?.data?.length > 0 ? true : false
                                }
                                setListStartEnd({ ...list_start_end, pick_up })
                            }
                            else {
                                let drop_off = {
                                    "address": res.data.name,
                                    "coordinates": res.data.location,
                                    "is_transit": response.data?.data?.length > 0 ? true : false
                                }
                                setListStartEnd({ ...list_start_end, drop_off })
                            }
                        })
                        .catch(err => console.log(err.response))
                        .finally(() => setProgress(false))
                }
            })
    }

    return (
        <View style={{ flex: 1, position: 'relative' }}>
            <View style={{ backgroundColor: colors.primary, paddingTop: insets.top ? insets.top : 0 }}>
                <View style={{
                    flexDirection: 'row', justifyContent: 'center', alignItems: 'center', height: 48,
                    position: 'relative',
                }}>
                    <Text style={{ fontFamily: 'Nunito_700Bold', fontSize: 18 }}>Chọn điểm đi - điểm đến</Text>
                    <View style={{ position: 'absolute', height: '100%', left: 0, top: 0 }}>
                        <TouchableOpacity onPress={() => navigation.pop()}
                            style={{ height: '100%', justifyContent: 'center', alignItems: 'center', paddingLeft: 16 }}>
                            <Image source={images.icons.back} style={{ width: 16, height: 16 }} />
                        </TouchableOpacity>
                    </View>
                    <View style={{ position: 'absolute', height: '100%', right: 16, top: 0, justifyContent: 'center', alignItems: 'center' }}>
                        <CountDown
                            until={moment(booking.expire_time, 'DD-MM-YYYY HH:mm:ss').unix() - moment().unix()}
                            size={14}
                            onFinish={cancelBooking}
                            digitStyle={{ backgroundColor: 'transparent', borderWidth: 0, marginHorizontal: 0 }}
                            digitTxtStyle={{ color: 'black', fontFamily: fontFamily.semi_bold }}
                            timeToShow={['M', 'S']}
                            timeLabels={{ m: null, s: null }}
                            separatorStyle={{ marginBottom: 2, fontFamily: fontFamily.semi_bold }}
                            showSeparator
                        />
                    </View>
                </View>
            </View>
            <View>
                <Step step={4} />
            </View>

            <ScrollView showsVerticalScrollIndicator={false}>
                <View style={{ backgroundColor: 'white' }}>
                    <TouchableOpacity style={{
                        flexDirection: 'row', paddingHorizontal: 16, paddingTop: 8, paddingBottom: 6,
                        justifyContent: 'center', alignItems: 'center'
                    }} onPress={() => setCollapseTrip(!collapse_trip)}>
                        <Image source={images.icons.back} style={{ width: 16, height: 16, marginRight: 8, transform: [{ rotate: collapse_trip ? '90deg' : '-90deg' }] }} />
                        <Text style={{ fontFamily: fontFamily.bold, flex: 1 }} numberOfLines={1}>{trip.route?.name}</Text>
                        <Text style={{ fontFamily: fontFamily.bold, marginLeft: 8 }}>{moment(trip.date).format('DD/MM/YYYY')}</Text>
                    </TouchableOpacity>
                    {
                        <Collapsible collapsed={!collapse_trip}>
                            <View style={{ width: '100%', height: 1, backgroundColor: 'rgba(202, 203, 208, 0.5)' }} />
                            <View style={{ flexDirection: 'column', paddingHorizontal: 16, paddingTop: 6, paddingBottom: 8 }}>
                                <View style={{ flexDirection: 'row' }}>
                                    <View style={{ flexDirection: 'column', alignItems: 'center', paddingTop: 6, paddingBottom: 4 }}>
                                        <View style={{ width: 6, height: 6, backgroundColor: '#050505', borderRadius: 3 }} />
                                        <View style={{ backgroundColor: '#050505', width: 2, height: 20, marginVertical: 4 }} />
                                        <View style={{ width: 6, height: 6, backgroundColor: '#050505' }} />
                                    </View>
                                    <View style={{ flexDirection: 'column', justifyContent: 'space-between', marginLeft: 8, marginRight: 16 }}>
                                        <Text style={{ fontSize: 16, fontFamily: fontFamily.bold, marginBottom: 8 }}>{moment(trip.from.reached_at).format('HH:mm')}</Text>
                                        <Text style={{ fontSize: 16, fontFamily: fontFamily.bold }}>{moment(trip.to.reached_at).format('HH:mm')}</Text>
                                    </View>
                                    <View style={{ flexDirection: 'column', justifyContent: 'space-between', flex: 1 }}>
                                        <Text style={{ fontSize: 16, fontFamily: fontFamily.bold }} numberOfLines={1}>{trip.from.place_name}</Text>
                                        <Text style={{ fontSize: 16, fontFamily: fontFamily.bold }} numberOfLines={1}>{trip.to.place_name}</Text>
                                    </View>
                                </View>
                            </View>
                            {
                                trip.node_time !== trip.from?.reached_at ? <>
                                    <View style={{ width: '100%', height: 1, backgroundColor: 'rgba(202, 203, 208, 0.5)' }} />
                                    <View style={{ flexDirection: 'row', paddingHorizontal: 16, paddingVertical: 6 }}>
                                        <Text style={{ color: '#CF1820', fontFamily: fontFamily.bold }}>*Khởi hành lúc {moment(trip.node_time).format('HH:mm - DD/MM')}</Text>
                                    </View>
                                </> : null
                            }
                        </Collapsible>
                    }
                </View>

                {
                    list_start_end?.list_start || list_start_end?.list_end ? <>
                        <View style={{ marginTop: 6 }}>
                            {
                                list_start_end?.list_start?.length > 0 ? <>
                                    <TouchableOpacity style={{
                                        height: 45, backgroundColor: 'white', paddingHorizontal: 16, alignItems: 'center', flexDirection: 'row',
                                        borderBottomColor: 'rgba(202, 203, 208, 0.5)', borderBottomWidth: 1
                                    }} onPress={() => {
                                        collapse.start = !collapse.start
                                        setCollapse({ ...collapse })
                                    }}>
                                        <View style={{ width: 16, height: 16, backgroundColor: colors.primary, borderRadius: 8 }} />
                                        <Text style={{ marginLeft: 18, flex: 1, fontSize: 16, fontFamily: fontFamily.semi_bold }}>Điểm đi</Text>
                                        <Image source={images.icons.back} style={{ width: 16, height: 16, transform: [{ rotate: !collapse.start ? '-90deg' : '90deg' }] }} />
                                    </TouchableOpacity>
                                    <Collapsible collapsed={!collapse.start}>
                                        <View>
                                            <TouchableOpacity style={{
                                                flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center',
                                                paddingHorizontal: 16, paddingVertical: 12, borderBottomColor: 'rgba(202, 203, 208, 0.5)', borderBottomWidth: 1
                                            }} onPress={() => setModalSearch({ ...modal_search, visible: true, is_start: true })}>
                                                <Image source={images.icons.search} style={{ width: 16, height: 16, tintColor: '#223DA4' }} />
                                                <Text style={{ marginLeft: 8, color: '#223DA4', fontFamily: fontFamily.bold }}>Tìm điểm chính xác</Text>
                                            </TouchableOpacity>
                                            {
                                                list_start_end?.list_start?.map((item, index) => {
                                                    return <TouchableOpacity key={index} onPress={() => {
                                                        setListStartEnd({ ...list_start_end, start: item })
                                                        setCollapse({ start: false, end: true })
                                                    }}>
                                                        <View style={{
                                                            flexDirection: 'row', height: 52, alignItems: 'center', paddingHorizontal: 16,
                                                            borderBottomColor: 'rgba(202, 203, 208, 0.5)', borderBottomWidth: 1
                                                        }}>
                                                            <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                                                <Image source={images.icons.pin} style={{ width: 16, height: 16 }} />
                                                                <Text style={{ marginLeft: 8, fontSize: 16, fontFamily: fontFamily.semi_bold }}>{moment(item.place_time).format('HH:mm')}</Text>
                                                            </View>
                                                            <View style={{ justifyContent: 'center', paddingHorizontal: 8, flex: 1 }}>
                                                                <Text style={{ fontSize: 16, fontFamily: fontFamily.semi_bold }} numberOfLines={1}>{item.place_name}</Text>
                                                            </View>
                                                            <View style={{ justifyContent: 'center', alignItems: 'center' }}>
                                                                <Image source={item.place_id === list_start_end.start.place_id ? images.icons.radio_on : images.icons.radio_off} style={{ width: 16, height: 16 }} />
                                                            </View>
                                                        </View>
                                                    </TouchableOpacity>
                                                })
                                            }
                                        </View>
                                    </Collapsible>
                                </> : null
                            }

                            {
                                list_start_end?.list_end?.length > 0 ? <>
                                    <TouchableOpacity style={{
                                        height: 45, backgroundColor: 'white', paddingHorizontal: 16, alignItems: 'center', flexDirection: 'row',
                                        borderBottomColor: 'rgba(202, 203, 208, 0.5)', borderBottomWidth: 1
                                    }} onPress={() => {
                                        collapse.end = !collapse.end
                                        setCollapse({ ...collapse })
                                    }}>
                                        <View style={{ width: 16, height: 16, backgroundColor: colors.primary }} />
                                        <Text style={{ marginLeft: 18, flex: 1, fontSize: 16, fontFamily: fontFamily.semi_bold }}>Điểm đến</Text>
                                        <Image source={images.icons.back} style={[{ width: 16, height: 16, transform: [{ rotate: !collapse.end ? '-90deg' : '90deg' }] }]} />
                                    </TouchableOpacity>
                                    <Collapsible collapsed={!collapse.end}>
                                        <View>
                                            <TouchableOpacity style={{
                                                flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center',
                                                paddingHorizontal: 16, paddingVertical: 12, borderBottomColor: 'rgba(202, 203, 208, 0.5)', borderBottomWidth: 1
                                            }} onPress={() => setModalSearch({ ...modal_search, visible: true, is_start: false })}>
                                                <Image source={images.icons.search} style={{ width: 16, height: 16, tintColor: '#223DA4' }} />
                                                <Text style={{ marginLeft: 8, color: '#223DA4', fontFamily: fontFamily.bold }}>Tìm điểm chính xác</Text>
                                            </TouchableOpacity>
                                            {
                                                list_start_end?.list_end?.map((item, index) => {
                                                    return <TouchableOpacity key={index} onPress={() => {
                                                        setListStartEnd({ ...list_start_end, end: item })
                                                        setCollapse({ ...collapse, end: false })
                                                    }}>
                                                        <View style={{
                                                            flexDirection: 'row', height: 52, alignItems: 'center', paddingHorizontal: 16,
                                                            borderBottomColor: 'rgba(202, 203, 208, 0.5)', borderBottomWidth: 1
                                                        }}>
                                                            <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                                                <Image source={images.icons.pin} style={{ width: 16, height: 16 }} />
                                                                <Text style={{ marginLeft: 8, fontSize: 16, fontFamily: fontFamily.semi_bold }}>{moment(item.place_time).format('HH:mm')}</Text>
                                                            </View>
                                                            <View style={{ justifyContent: 'center', paddingHorizontal: 8, flex: 1 }}>
                                                                <Text style={{ fontSize: 16, fontFamily: fontFamily.semi_bold }} numberOfLines={1}>{item.place_name}</Text>
                                                            </View>
                                                            <View style={{ justifyContent: 'center', alignItems: 'center' }}>
                                                                <Image source={item.place_id === list_start_end.end.place_id ? images.icons.radio_on : images.icons.radio_off} style={{ width: 16, height: 16 }} />
                                                            </View>
                                                        </View>
                                                    </TouchableOpacity>
                                                })
                                            }
                                        </View>
                                    </Collapsible>
                                </> : null
                            }
                        </View>
                    </> : <View style={{ justifyContent: 'center', alignItems: 'center', marginTop: 16 }}>
                        <ActivityIndicator color={colors.primary} />
                    </View>
                }
            </ScrollView>

            <View style={{
                paddingHorizontal: 16, paddingTop: 12, paddingBottom: 12 + insets.bottom, backgroundColor: 'white',
                borderTopWidth: 1, borderTopColor: '#CACBD0', flexDirection: 'column'
            }}>
                {
                    Object.values(list_start_end).length > 0 && (list_start_end.start?.place_id !== trip.from?.place_id || list_start_end.end?.place_id !== trip.to?.place_id
                        || list_start_end.pick_up || list_start_end.drop_off) ?
                        <View style={{ flexDirection: 'row', borderBottomColor: '#CACBD0', borderBottomWidth: 1, paddingBottom: 8 }}>
                            <View style={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: 16, paddingTop: 1 }}>
                                {
                                    list_start_end.pick_up ? <>
                                        <View style={{ borderColor: '#050505', width: 6, height: 6, borderRadius: 3, borderWidth: 1, marginTop: 2 }} />
                                        <View style={{ backgroundColor: '#050505', width: 2, height: 1, marginTop: 4 }} />
                                        <View style={{ backgroundColor: '#050505', width: 2, height: 2, marginVertical: 2 }} />
                                        <View style={{ backgroundColor: '#050505', width: 2, height: 1, marginBottom: 4 }} />
                                    </> : null
                                }
                                <View style={{ backgroundColor: '#050505', width: 6, height: 6, borderRadius: 3 }} />
                                <View style={{ backgroundColor: '#050505', width: 2, height: 18, marginVertical: 2 }} />
                                <View style={{ backgroundColor: '#050505', width: 6, height: 6, marginBottom: 4 }} />
                                {
                                    list_start_end.drop_off ? <>
                                        <View style={{ backgroundColor: '#050505', width: 2, height: 1 }} />
                                        <View style={{ backgroundColor: '#050505', width: 2, height: 2, marginVertical: 2 }} />
                                        <View style={{ backgroundColor: '#050505', width: 2, height: 1, marginBottom: 4 }} />
                                        <View style={{ borderColor: '#050505', width: 6, height: 6, borderWidth: 1, marginBottom: 2 }} />
                                    </> : null
                                }
                            </View>
                            <View style={{ flex: 1, marginLeft: 6 }}>
                                {
                                    list_start_end.pick_up ? <View style={{ flexDirection: 'row', marginBottom: 3, justifyContent: 'center', alignItems: 'center' }}>
                                        <Text style={{ fontSize: 14, fontFamily: fontFamily.bold }}>
                                            {list_start_end.pick_up?.is_transit ? 'Trung chuyển: ' : 'Tự di chuyển: '}
                                        </Text>
                                        <Text style={{ fontSize: 14, fontFamily: fontFamily.semi_regular, flex: 1 }} numberOfLines={1}>{list_start_end.pick_up?.address}</Text>
                                        <TouchableOpacity style={{ justifyContent: 'center', alignItems: 'center', paddingLeft: 4 }}
                                            onPress={() => {
                                                let pick_up = null
                                                setListStartEnd({ ...list_start_end, pick_up })
                                            }}>
                                            <Image source={images.icons.clear} style={{ width: 14, height: 14, marginBottom: 2 }} />
                                        </TouchableOpacity>
                                    </View> : null
                                }

                                <View style={{ flexDirection: 'row' }}>
                                    <Text style={{ fontSize: 16, fontFamily: fontFamily.bold }} numberOfLines={1}>
                                        {moment(list_start_end.start?.place_time ? list_start_end.start?.place_time : list_start_end.start?.reached_at).format('HH:mm')}
                                    </Text>
                                    <Text style={{ fontSize: 16, fontFamily: fontFamily.bold, marginLeft: 8, flex: 1 }} numberOfLines={1}>{list_start_end.start?.place_name}</Text>
                                </View>
                                <View style={{ flexDirection: 'row', marginTop: 4 }}>
                                    <Text style={{ fontSize: 16, fontFamily: fontFamily.bold }} numberOfLines={1}>
                                        {moment(list_start_end.end?.place_time ? list_start_end.end?.place_time : list_start_end.end?.reached_at).format('HH:mm')}
                                    </Text>
                                    <Text style={{ fontSize: 16, fontFamily: fontFamily.bold, marginLeft: 8, flex: 1 }} numberOfLines={1}>{list_start_end.end?.place_name}</Text>
                                </View>

                                {
                                    list_start_end.drop_off ? <View style={{ flexDirection: 'row', marginTop: 3, justifyContent: 'center', alignItems: 'center' }}>
                                        <Text style={{ fontSize: 14, fontFamily: fontFamily.bold }}>
                                            {list_start_end.drop_off?.is_transit ? 'Trung chuyển: ' : 'Tự di chuyển: '}
                                        </Text>
                                        <Text style={{ fontSize: 14, fontFamily: fontFamily.semi_regular, flex: 1 }} numberOfLines={1}>{list_start_end.drop_off?.address}</Text>
                                        <TouchableOpacity style={{ justifyContent: 'center', alignItems: 'center', paddingLeft: 4 }}
                                            onPress={() => {
                                                let drop_off = null
                                                setListStartEnd({ ...list_start_end, drop_off })
                                            }}>
                                            <Image source={images.icons.clear} style={{ width: 14, height: 14, marginBottom: 2 }} />
                                        </TouchableOpacity>
                                    </View> : null
                                }
                            </View>
                        </View> : null
                }
                {
                    Object.values(seats).length > 0 ? <>
                        <View style={{ flexDirection: 'row', justifyContent: 'center', marginTop: 8 }}>
                            <Text style={{ flex: 1, fontFamily: 'Nunito_400Regular' }}>Số ghế/giường</Text>
                            <Text style={{ fontSize: 16, fontFamily: fontFamily.semi_bold }}>
                                {
                                    Object.values(seats).map(item => item.name).join(',')
                                }
                            </Text>
                        </View>
                        <View style={{ flexDirection: 'row', justifyContent: 'center', marginTop: 4 }}>
                            <Text style={{ flex: 1, fontFamily: 'Nunito_400Regular' }}>Tổng tiền</Text>
                            <Text style={{ fontSize: 16, fontFamily: fontFamily.semi_bold }}>{
                                (Intl.NumberFormat('vi-VN').format(Object.values(seats).map(item => item.price).reduce(reducer)))
                            }đ</Text>
                        </View>
                    </> : null
                }
                <TouchableOpacity style={{
                    height: 45, backgroundColor: colors.primary, marginTop: 8,
                    justifyContent: 'center', alignItems: 'center', borderRadius: 6
                }} onPress={toPayment}>
                    <Text style={{ fontSize: 16, fontFamily: fontFamily.semi_bold }}>Tiếp tục</Text>
                </TouchableOpacity>
            </View>

            {
                progress ? <View style={{
                    top: 0, left: 0, width: '100%', height: '100%',
                    justifyContent: "center", alignItems: "center", position: 'absolute', backgroundColor: '#FFFFFF80'
                }}>
                    <ActivityIndicator color={colors.primary} />
                </View> : null
            }

            <Modal
                visible={modal_search.visible}
                modalAnimation={new SlideAnimation({
                    slideFrom: 'bottom',
                })}
                onTouchOutside={() => setModalSearch({ ...modal_search, visible: false })}
                width={1}
                height={1}
            >
                <View style={{ flex: 1, backgroundColor: '#E5E5E5' }}>
                    <View style={{ backgroundColor: colors.primary, paddingTop: insets.top ? insets.top : 0 }}>
                        <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', height: 48, paddingHorizontal: 8 }}>
                            <TouchableOpacity style={{ padding: 8 }} onPress={() => setModalSearch({ ...modal_search, visible: false })}>
                                <Image source={images.icons.close} style={{ width: 16, height: 16 }} />
                            </TouchableOpacity>
                            <Text style={{ flex: 1, textAlign: 'center', color: colors.text, fontFamily: 'Nunito_700Bold', fontSize: 18 }}>
                                {modal_search.is_start ? 'Tìm điểm đi' : 'Tìm điểm đến'}
                            </Text>
                            <TouchableOpacity style={{ padding: 8, opacity: 0 }}>
                                <Image source={images.icons.close} style={{ width: 16, height: 16 }} />
                            </TouchableOpacity>
                        </View>
                    </View>

                    <View style={{ position: 'relative', marginTop: 12, backgroundColor: 'white', marginHorizontal: 16, borderRadius: 4 }}>
                        <View style={{ height: '100%', position: 'absolute', justifyContent: 'center', alignItems: 'center' }}>
                            <Image source={images.icons.search} style={{ width: 16, height: 16, marginLeft: 12 }} />
                        </View>
                        <TextInput style={{
                            paddingRight: 12, paddingLeft: 36, fontFamily: fontFamily.semi_bold, height: 40,
                        }} placeholder='Nhập vào điểm'
                            value={modal_search.search}
                            onChangeText={search => {
                                setModalSearch({ ...modal_search, search })
                                setListSearch(null)
                                searchPlace(search)
                            }} autoFocus autoCorrect={false} />
                    </View>

                    <ScrollView showsVerticalScrollIndicator={false} keyboardShouldPersistTaps='handled'>
                        <TouchableOpacity activeOpacity={.99} style={{ paddingVertical: 8 }}>
                            {
                                list_search ? list_search?.map(item => {
                                    return <TouchableOpacity style={{
                                        paddingHorizontal: 16, paddingVertical: 8,
                                        borderBottomWidth: 1, borderBottomColor: 'rgba(202, 203, 208, 0.5)'
                                    }} key={item.id} onPress={() => detailPlace(item.id)}>
                                        <Text style={{ fontFamily: fontFamily.semi_bold }} numberOfLines={1}>{item.name}</Text>
                                        <Text style={{ fontFamily: fontFamily.regular }} numberOfLines={1}>{item.description}</Text>
                                    </TouchableOpacity>
                                }) : <View style={{
                                    justifyContent: "center", alignItems: "center", marginTop: 16
                                }}>
                                    <ActivityIndicator color={colors.primary} />
                                </View>
                            }
                        </TouchableOpacity>
                    </ScrollView>
                </View>
            </Modal>
        </View>
    )
})
