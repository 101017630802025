
import * as React from 'react'
import { Text, View, ScrollView, TouchableOpacity, Image, ActivityIndicator, TextInput } from 'react-native'
import { useTheme } from '@react-navigation/native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { Step } from '../components'
import images from '../../assets'
import moment from 'moment'
import axios from 'axios'
import { showMessage } from "react-native-flash-message"
import CountDown from '../libs/countdown'

export default React.memo(function Booking({ navigation, route }) {
    const { colors, fontFamily } = useTheme()
    const insets = useSafeAreaInsets()
    const [data, setData] = React.useState()
    const ref_name = React.useRef()
    const ref_phone = React.useRef()
    const ref_email = React.useRef()
    const ref_note = React.useRef()

    const { trip, booking, seats } = route.params
    const reducer = (accumulator, currentValue) => accumulator + currentValue

    const requestBooking = () => {
        let regex_phone = /(84|0[3|5|7|8|9])+([0-9]{8})\b/g

        if (!data?.name) return showMessage({
            message: "Thông báo",
            description: "Bạn chưa nhập vào họ tên!",
            type: "warning",
        })
        if (data?.phone) {
            if (!regex_phone.test(data?.phone))
                return showMessage({
                    message: "Thông báo",
                    description: "Số điện thoại bạn nhập vào không đúng!",
                    type: "warning",
                })
        } else return showMessage({
            message: "Thông báo",
            description: "Bạn chưa nhập vào số điện thoại!",
            type: "warning",
        })

        let regex_email = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g

        if (data?.email && !regex_email.test(data?.email)) {
            return showMessage({
                message: "Thông báo",
                description: "Email bạn nhập vào không đúng!",
                type: "warning",
            })
        }
        navigation.navigate('Points', { ...route.params, info: data })
    }

    const cancelBooking = () => {
        if (navigation.canGoBack())
            navigation.navigate({
                name: 'Seats',
                params: { reload: moment().unix() },
                merge: true,
            })
        else window.history.back()
    }

    return (
        <View style={{ flex: 1, position: 'relative' }}>
            <View style={{ backgroundColor: colors.primary, paddingTop: insets.top ? insets.top : 0 }}>
                <View style={{
                    flexDirection: 'row', justifyContent: 'center', alignItems: 'center', height: 48,
                    position: 'relative',
                }}>
                    <Text style={{ fontFamily: 'Nunito_700Bold', fontSize: 18 }}>Thông tin vé xe</Text>
                    <View style={{ position: 'absolute', height: '100%', left: 0, top: 0 }}>
                        <TouchableOpacity onPress={cancelBooking}
                            style={{ height: '100%', justifyContent: 'center', alignItems: 'center', paddingLeft: 16 }}>
                            <Image source={images.icons.back} style={{ width: 16, height: 16 }} />
                        </TouchableOpacity>
                    </View>
                    <View style={{ position: 'absolute', height: '100%', right: 16, top: 0, justifyContent: 'center', alignItems: 'center' }}>
                        <CountDown
                            until={moment(booking.expire_time, 'DD-MM-YYYY HH:mm:ss').unix() - moment().unix()}
                            size={14}
                            onFinish={cancelBooking}
                            digitStyle={{ backgroundColor: 'transparent', borderWidth: 0, marginHorizontal: 0 }}
                            digitTxtStyle={{ color: 'black', fontFamily: fontFamily.semi_bold }}
                            timeToShow={['M', 'S']}
                            timeLabels={{ m: null, s: null }}
                            separatorStyle={{ marginBottom: 2, fontFamily: fontFamily.semi_bold }}
                            showSeparator
                        />
                    </View>
                </View>
            </View>
            <View>
                <Step step={3} />
            </View>

            <ScrollView showsVerticalScrollIndicator={false}>
                <View style={{ padding: 16, backgroundColor: 'white' }}>
                    <Text style={{ fontSize: 16, fontFamily: fontFamily.bold }}>Thông tin của bạn</Text>

                    <Text style={{ fontFamily: fontFamily.bold, marginTop: 16 }}>Họ và tên <Text style={{ color: 'red' }}>*</Text></Text>
                    <TextInput
                        ref={ref_name}
                        returnKeyType='next' autoCorrect={false}
                        style={{ borderRadius: 4, backgroundColor: '#F0F2F5', height: 45, paddingHorizontal: 16, marginTop: 5, fontFamily: fontFamily.regular }}
                        placeholder='Nhập họ tên của người đi' onChangeText={name => setData({ ...data, name })}
                        onSubmitEditing={() => ref_phone.current.focus()} />

                    <Text style={{ fontFamily: fontFamily.bold, marginTop: 16 }}>Số điện thoại <Text style={{ color: 'red' }}>*</Text></Text>
                    <TextInput
                        ref={ref_phone}
                        returnKeyType='next' autoCorrect={false}
                        style={{ borderRadius: 4, backgroundColor: '#F0F2F5', height: 45, paddingHorizontal: 16, marginTop: 5, fontFamily: fontFamily.regular }}
                        placeholder='Nhập số điện thoại của người đi' onChangeText={phone => setData({ ...data, phone })}
                        onSubmitEditing={() => ref_email.current.focus()} />

                    <Text style={{ fontFamily: fontFamily.bold, marginTop: 16 }}>Email</Text>
                    <TextInput
                        ref={ref_email}
                        returnKeyType='next' autoCorrect={false}
                        style={{ borderRadius: 4, backgroundColor: '#F0F2F5', height: 45, paddingHorizontal: 16, marginTop: 5, fontFamily: fontFamily.regular }}
                        placeholder='Nhập địa chỉ e-mail của người đi' onChangeText={email => setData({ ...data, email })}
                        onSubmitEditing={() => ref_note.current.focus()} />

                    <Text style={{ fontFamily: fontFamily.bold, marginTop: 16 }}>Ghi chú</Text>
                    <TextInput
                        ref={ref_note}
                        returnKeyType='done' autoCorrect={false}
                        style={{ borderRadius: 4, backgroundColor: '#F0F2F5', height: 45, paddingHorizontal: 16, marginTop: 5, fontFamily: fontFamily.regular }}
                        placeholder='Nhập ghi chú của người đi' onChangeText={note => setData({ ...data, note })} />
                </View>

                <View style={{ padding: 16, backgroundColor: 'white' }}>
                    <Text style={{ fontSize: 16, fontFamily: fontFamily.bold }}>Thông tin vé</Text>
                    <View style={{ flexDirection: 'row', justifyContent: 'center', marginTop: 12 }}>
                        <Text style={{ color: '#514F4F', flex: 4, fontFamily: 'Nunito_400Regular' }}>Mã vé</Text>
                        <Text style={{ color: '#1F76E5', fontFamily: fontFamily.bold, fontSize: 16, flex: 5 }}>{booking.code ? booking.code : booking.tickets[0]?.code}</Text>
                    </View>
                    <View style={{ flexDirection: 'row', justifyContent: 'center', marginTop: 8 }}>
                        <Text style={{ color: '#514F4F', flex: 4, fontFamily: 'Nunito_400Regular' }}>Ngày khởi hành</Text>
                        <Text style={{ fontFamily: fontFamily.semi_bold, flex: 5 }}>{moment(trip.date).format('DD/MM/YYYY')}</Text>
                    </View>
                    <View style={{ flexDirection: 'row', justifyContent: 'center', marginTop: 8 }}>
                        <Text style={{ color: '#514F4F', flex: 4, fontFamily: 'Nunito_400Regular' }}>Điểm lên xe</Text>
                        <Text style={{ fontFamily: fontFamily.semi_bold, flex: 5 }}>
                            {moment(trip.from.reached_at).format('HH:mm')} - {trip.from.place_name}
                        </Text>
                    </View>
                    <View style={{ flexDirection: 'row', justifyContent: 'center', marginTop: 8 }}>
                        <Text style={{ color: '#514F4F', flex: 4, fontFamily: 'Nunito_400Regular' }}>Điểm xuống xe</Text>
                        <Text style={{ fontFamily: fontFamily.semi_bold, flex: 5 }}>
                            {moment(trip.to.reached_at).format('HH:mm')} - {trip.to.place_name}
                        </Text>
                    </View>

                    <View style={{ width: '100%', backgroundColor: '#E9E9E9', height: 1, marginVertical: 8 }} />
                    <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
                        <Text style={{ color: '#514F4F', flex: 4, fontFamily: 'Nunito_400Regular' }}>Tên tuyến</Text>
                        <Text style={{ fontFamily: fontFamily.semi_bold, flex: 5 }}>{trip.route.name}</Text>
                    </View>
                    <View style={{ flexDirection: 'row', justifyContent: 'center', marginTop: 8 }}>
                        <Text style={{ color: '#514F4F', flex: 4, fontFamily: 'Nunito_400Regular' }}>Hạng xe</Text>
                        <Text style={{ fontFamily: fontFamily.semi_bold, flex: 5 }}>{trip.product.name}</Text>
                    </View>
                    <View style={{ flexDirection: 'row', justifyContent: 'center', marginTop: 8 }}>
                        <Text style={{ color: '#514F4F', flex: 4, fontFamily: 'Nunito_400Regular' }}>Số ghế/giường</Text>
                        <Text style={{ fontFamily: fontFamily.semi_bold, flex: 5 }}>
                            {
                                seats.map(item => item.name).join(',')
                            }
                        </Text>
                    </View>
                </View>
            </ScrollView>

            <View style={{
                paddingHorizontal: 16, paddingTop: 12, paddingBottom: 12 + (insets.bottom ? insets.bottom - 16 : 0), backgroundColor: 'white',
                borderTopWidth: 1, borderTopColor: '#CACBD0'
            }}>
                <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
                    <Text style={{ flex: 1, fontFamily: 'Nunito_400Regular' }}>Tổng tiền</Text>
                    <Text style={{ fontSize: 16, fontFamily: fontFamily.semi_bold }}>{
                        (Intl.NumberFormat('vi-VN').format(Object.values(seats).map(item => item.price).reduce(reducer)))
                    }đ</Text>
                </View>
                <TouchableOpacity style={{
                    height: 45, backgroundColor: colors.primary, marginTop: 12,
                    justifyContent: 'center', alignItems: 'center', borderRadius: 6
                }} onPress={requestBooking}>
                    <Text style={{ fontSize: 16, fontFamily: fontFamily.semi_bold }}>Tiếp tục</Text>
                </TouchableOpacity>
            </View>
        </View>
    )
})
