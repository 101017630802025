
import * as React from 'react'
import { Text, View, ScrollView, TouchableOpacity, Image, ActivityIndicator } from 'react-native'
import { useTheme } from '@react-navigation/native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import images from '../../assets'
import axios from 'axios'

export default React.memo(function AccountManage({ navigation, route }) {
    const { colors, fontFamily } = useTheme()
    const insets = useSafeAreaInsets()
    const [info, setInfo] = React.useState({})

    React.useEffect(() => {
        fetchInfoAgency()
    }, [])

    const fetchInfoAgency = () => {
        axios.get(`agencies`)
            .then(res => {
                console.log(res)
                setInfo(res.data.data)
            })
            .catch(err => console.log(err.response))
    }

    return (
        <View style={{ flex: 1 }}>
            <View style={{ backgroundColor: colors.primary, paddingTop: insets.top ? insets.top : 0 }}>
                <View style={{
                    flexDirection: 'row', justifyContent: 'center', alignItems: 'center', height: 48,
                    position: 'relative'
                }}>
                    <Text style={{ fontFamily: fontFamily.bold, fontSize: 18 }}>Danh sách tài khoản</Text>
                    <View style={{ position: 'absolute', height: '100%', left: 0, top: 0 }}>
                        <TouchableOpacity onPress={() => {
                            if (navigation.canGoBack())
                                navigation.pop()
                            else window.history.back()
                        }}
                            style={{ height: '100%', justifyContent: 'center', alignItems: 'center', paddingLeft: 16 }}>
                            <Image source={images.icons.back} style={{ width: 16, height: 16 }} />
                        </TouchableOpacity>
                    </View>
                </View>
            </View>
            <ScrollView showsVerticalScrollIndicator={false}>
                <View style={{ paddingVertical: 6 }}>
                    {
                        info.accounts ? <>
                            {
                                info.accounts.map((item, idx) => {
                                    return <View key={idx} style={{ marginVertical: 6 }}>
                                        <View style={{
                                            flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
                                            backgroundColor: 'white', paddingHorizontal: 16, paddingVertical: 12
                                        }}>
                                            <View style={{ flex: 1 }}>
                                                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                                    <Image source={images.icons.tab4_} style={{ width: 16, height: 16, tintColor: '#58595B' }} />
                                                    <Text style={{ color: '#58595B', fontSize: 16, marginLeft: 6 }}>Tên</Text>
                                                </View>
                                                <Text style={{ marginTop: 8 }}>{item.name}</Text>
                                            </View>
                                            <View style={{ flex: 1, paddingLeft: 6 }}>
                                                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                                    <Image source={images.icons.setting} style={{ width: 16, height: 16, tintColor: '#58595B' }} />
                                                    <Text style={{ color: '#58595B', fontSize: 16, marginLeft: 6 }}>Chức vụ</Text>
                                                </View>
                                                <Text style={{ marginTop: 8 }}>{
                                                    item.role.map(itm => {
                                                        return itm.name
                                                    }).toString()
                                                }</Text>
                                            </View>
                                        </View>
                                    </View>
                                })
                            }
                        </> : <View style={{ alignItems: 'center', marginTop: 24 }}>
                            <ActivityIndicator color={colors.primary} />
                        </View>
                    }
                </View>
            </ScrollView>
        </View>
    )
})
