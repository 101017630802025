
import * as React from 'react'
import { Text, View, ScrollView, TouchableOpacity, Image, ActivityIndicator } from 'react-native'
import { useTheme } from '@react-navigation/native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { Step } from '../components'
import images from '../../assets'
import axios from 'axios'
import { showMessage } from "react-native-flash-message"
import moment from 'moment'

export default React.memo(function Payment({ navigation, route }) {
    const { colors, fontFamily } = useTheme()
    const insets = useSafeAreaInsets()
    const [progress, setProgress] = React.useState(false)

    const { booking } = route.params

    console.log(booking)

    const updateBooking = () => {
        setProgress(true)
        axios.put(`tickets/${booking.code}`, {
            "note": booking.note,
            "pick_up": booking.pick_up,
            "drop_off": booking.drop_off,
            "customer": booking.customer,
            "transit": {
                "departure": booking.pick_up?.is_transit ? 1 : 0,
                "destination": booking.drop_off?.is_transit ? 1 : 0,
            },
        }).then(res => {
            console.log(res)
            setProgress(false)
            if (res.status === 200) {
                navigation.navigate({
                    name: 'Seats',
                    params: { reload: moment().unix() },
                    merge: true,
                })
                showMessage({
                    message: "Thông báo",
                    description: "Cập nhật thông tin thành công!",
                    type: "info",
                })
            } else {
                showMessage({
                    message: "Thông báo",
                    description: res.data?.message ? res.data?.message : "Có lỗi xảy ra trong quá trình cập nhật!",
                    type: "warning",
                })
            }
        }).catch(err => {
            setProgress(false)
            showMessage({
                message: "Thông báo",
                description: err.response?.data?.message ? err.response?.data?.message : "Có lỗi xảy ra trong quá trình cập nhật!",
                type: "warning",
            })
            console.log(err.response)
        })
    }

    return (
        <View style={{ flex: 1, position: 'relative' }}>
            <View style={{ backgroundColor: colors.primary, paddingTop: insets.top ? insets.top : 0 }}>
                <View style={{
                    flexDirection: 'row', justifyContent: 'center', alignItems: 'center', height: 48,
                    position: 'relative',
                }}>
                    <Text style={{ fontFamily: 'Nunito_700Bold', fontSize: 18 }}>Chọn điểm đi - điểm đến</Text>
                    <View style={{ position: 'absolute', height: '100%', left: 0, top: 0 }}>
                        <TouchableOpacity onPress={() => navigation.pop()}
                            style={{ height: '100%', justifyContent: 'center', alignItems: 'center', paddingLeft: 16 }}>
                            <Image source={images.icons.back} style={{ width: 16, height: 16 }} />
                        </TouchableOpacity>
                    </View>
                </View>
            </View>
            <View>
                <Step step={5} />
            </View>

            <ScrollView showsVerticalScrollIndicator={false}>
                <View style={{ padding: 16 }}>
                    <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
                        <Text style={{ color: '#514F4F', flex: 4, fontFamily: 'Nunito_400Regular' }}>Hành khách</Text>
                        <Text style={{ fontFamily: fontFamily.semi_bold, flex: 5 }}>{booking.customer?.name}</Text>
                    </View>
                    <View style={{ flexDirection: 'row', justifyContent: 'center', marginTop: 8 }}>
                        <Text style={{ color: '#514F4F', flex: 4, fontFamily: 'Nunito_400Regular' }}>Số điện thoại</Text>
                        <Text style={{ fontFamily: fontFamily.semi_bold, flex: 5 }}>{booking.customer?.phone}</Text>
                    </View>
                    {
                        booking.customer?.email ? <View style={{ flexDirection: 'row', justifyContent: 'center', marginTop: 8 }}>
                            <Text style={{ color: '#514F4F', flex: 4, fontFamily: 'Nunito_400Regular' }}>Email</Text>
                            <Text style={{ fontFamily: fontFamily.semi_bold, flex: 5 }}>{booking.customer?.email}</Text>
                        </View> : null
                    }
                    {
                        booking.note ? <View style={{ flexDirection: 'row', justifyContent: 'center', marginTop: 8 }}>
                            <Text style={{ color: '#514F4F', flex: 4, fontFamily: 'Nunito_400Regular' }}>Ghi chú</Text>
                            <Text style={{ fontFamily: fontFamily.semi_bold, flex: 5 }}>{booking.note}</Text>
                        </View> : null
                    }
                    <View style={{ width: '100%', backgroundColor: '#E9E9E9', height: 1, marginVertical: 8 }} />
                    <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
                        <Text style={{ color: '#514F4F', flex: 4, fontFamily: 'Nunito_400Regular' }}>Mã vé</Text>
                        <Text style={{ color: '#1F76E5', fontFamily: fontFamily.bold, fontSize: 16, flex: 5 }}>{booking.code}</Text>
                    </View>
                    <View style={{ flexDirection: 'row', justifyContent: 'center', marginTop: 8 }}>
                        <Text style={{ color: '#514F4F', flex: 4, fontFamily: 'Nunito_400Regular' }}>Ngày khởi hành</Text>
                        <Text style={{ fontFamily: fontFamily.semi_bold, flex: 5 }}>{moment(booking.trip?.time, 'DD-MM-YYYY HH:mm').format('HH:mm DD/MM/YYYY')}</Text>
                    </View>
                    <View style={{ flexDirection: 'row', justifyContent: 'center', marginTop: 8 }}>
                        <Text style={{ color: '#514F4F', flex: 4, fontFamily: 'Nunito_400Regular' }}>Điểm lên xe</Text>
                        <Text style={{ fontFamily: fontFamily.semi_bold, flex: 5 }}>
                            {booking.pick_up?.address}
                        </Text>
                    </View>
                    <View style={{ flexDirection: 'row', justifyContent: 'center', marginTop: 8 }}>
                        <Text style={{ color: '#514F4F', flex: 4, fontFamily: 'Nunito_400Regular' }}>Điểm xuống xe</Text>
                        <Text style={{ fontFamily: fontFamily.semi_bold, flex: 5 }}>
                            {booking.drop_off?.address}
                        </Text>
                    </View>

                    <View style={{ flexDirection: 'row', justifyContent: 'center', marginTop: 8 }}>
                        <Text style={{ color: '#514F4F', flex: 4, fontFamily: 'Nunito_400Regular' }}>Tên tuyến</Text>
                        <Text style={{ fontFamily: fontFamily.semi_bold, flex: 5 }}>{booking.trip?.node?.route?.name}</Text>
                    </View>
                    <View style={{ flexDirection: 'row', justifyContent: 'center', marginTop: 8 }}>
                        <Text style={{ color: '#514F4F', flex: 4, fontFamily: 'Nunito_400Regular' }}>Hạng xe</Text>
                        <Text style={{ fontFamily: fontFamily.semi_bold, flex: 5 }}>{booking.trip?.product?.name}</Text>
                    </View>
                    <View style={{ flexDirection: 'row', justifyContent: 'center', marginTop: 8 }}>
                        <Text style={{ color: '#514F4F', flex: 4, fontFamily: 'Nunito_400Regular' }}>Số ghế/giường</Text>
                        <Text style={{ fontFamily: fontFamily.semi_bold, flex: 5 }}>{booking?.name}</Text>
                    </View>
                    <View style={{ width: '100%', backgroundColor: '#E9E9E9', height: 1, marginVertical: 8 }} />
                    <View style={{ flexDirection: 'row' }}>
                        <Text style={{ fontSize: 16, fontFamily: fontFamily.bold, flex: 1 }}>Tổng tiền</Text>
                        <View style={{ flexDirection: 'column', alignItems: 'flex-end' }}>
                            <Text style={{ fontSize: 20, fontFamily: fontFamily.bold, color: booking?.is_payment === 1 ? '#1F76E5' : colors.text }}>{
                                (Intl.NumberFormat('vi-VN').format(booking?.price))
                            }đ</Text>
                            <Text style={{ fontSize: 16, fontFamily: fontFamily.regular, color: booking?.is_payment === 1 ? '#1F76E5' : colors.text }}>{booking.payment?.type_name}</Text>
                        </View>
                    </View>
                </View>
            </ScrollView>

            <View style={{
                paddingHorizontal: 16, paddingBottom: 12 + insets.bottom, flexDirection: 'row'
            }}>
                <TouchableOpacity style={{
                    height: 45, backgroundColor: colors.primary, marginTop: 8,
                    justifyContent: 'center', alignItems: 'center', borderRadius: 6, flex: 1
                }} onPress={updateBooking}>
                    <Text style={{ fontSize: 16, fontFamily: fontFamily.semi_bold }}>Cập nhật thông tin</Text>
                </TouchableOpacity>
            </View>

            {
                progress ? <View style={{
                    top: 0, left: 0, width: '100%', height: '100%',
                    justifyContent: "center", alignItems: "center", position: 'absolute', backgroundColor: '#FFFFFF80'
                }}>
                    <ActivityIndicator color={colors.primary} />
                </View> : null
            }
        </View>
    )
})
