
import * as React from 'react'
import { Text, View, ScrollView, TouchableOpacity, Image, useWindowDimensions } from 'react-native'
import { useTheme, CommonActions } from '@react-navigation/native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import images from '../../assets'
import 'moment-lunar'
import { SlideAnimation, Modal, ModalContent } from 'react-native-modals'
import { setModalJourney } from '../redux/ActionCreators'
import moment from 'moment'
import axios from 'axios'

export default React.memo(function ModalJourney({ trip }) {
    const { colors, fontFamily } = useTheme()
    const insets = useSafeAreaInsets()
    const [journey, setJourney] = React.useState([])
    const modal_journey = useSelector(state => state.modal_journey, shallowEqual)
    const dispatch = useDispatch()

    React.useEffect(() => {
        axios.get(`trip-detail?trip_id=${trip.id}`).then(res => {
            setJourney(res.data.journey)
            let tmp = res.data.journey
            let journey = [], is_start = false
            for (let index = 0; index < tmp.length; index++) {
                const element = tmp[index]
                if (element.place_id === trip.from.place_id) {
                    is_start = true
                }
                if (is_start) journey.push(element)
                if (element.place_id === trip.to.place_id) break;
            }
            setJourney(journey)
        }).catch(err => console.log(err))
    }, [])

    // console.log(trip, journey)

    return (
        <Modal
            visible={modal_journey}
            modalAnimation={new SlideAnimation({
                slideFrom: 'bottom',
            })}
            onTouchOutside={() => {
                dispatch(setModalJourney(false))
            }}
            width={1}
            height={1}
        >
            <View style={{ flex: 1, backgroundColor: 'white' }}>
                <View style={{ backgroundColor: colors.primary, paddingTop: insets.top ? insets.top : 0 }}>
                    <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', height: 48, paddingHorizontal: 8 }}>
                        <TouchableOpacity style={{ padding: 8 }} onPress={() => dispatch(setModalJourney(false))}>
                            <Image source={images.icons.close} style={{ width: 16, height: 16 }} />
                        </TouchableOpacity>
                        <Text style={{ flex: 1, textAlign: 'center', color: colors.text, fontFamily: 'Nunito_700Bold', fontSize: 18 }}>
                            Lộ trình di chuyển
                        </Text>
                        <TouchableOpacity style={{ padding: 8, opacity: 0 }}>
                            <Image source={images.icons.close} style={{ width: 16, height: 16 }} />
                        </TouchableOpacity>
                    </View>
                </View>
                <ScrollView showsVerticalScrollIndicator={false}>
                    <TouchableOpacity style={{ padding: 16 }} activeOpacity={.99}>
                        <View style={{ flexDirection: 'row', justifyContent: 'center', marginBottom: 16 }}>
                            <Image source={images.icons.bus} style={{ width: 22, height: 22 }} />
                            <View style={{ alignItems: 'center' }}>
                                <Text style={{ fontSize: 16, fontFamily: fontFamily.bold, marginLeft: 16 }}>{trip.route?.name}</Text>
                                <Text>{moment(trip.date).format('DD/MM/YYYY')}</Text>
                            </View>
                        </View>

                        {
                            journey.length === 0 ? null :
                                journey?.map((item, idx) => {
                                    return <View key={idx} style={{ flexDirection: 'row' }}>
                                        <Text style={{ width: 50, textAlign: 'right', fontFamily: fontFamily.semi_bold, marginBottom: 16 }}>{moment(item.place_time).format('HH:mm')}</Text>
                                        {
                                            idx === 0 ? <View style={{ height: '100%', flexDirection: 'column', paddingTop: 6 }}>
                                                <View style={{ flex: 1, width: 2, backgroundColor: colors.text, marginLeft: 8, marginRight: 16, position: 'relative' }}>
                                                    <View style={{ position: 'relative', top: 0, left: -2, width: 6, height: 6, backgroundColor: colors.text, borderRadius: 3 }} />
                                                </View>
                                            </View> : (
                                                idx === journey.length - 1 ? <View style={{ height: 10, width: 2, backgroundColor: colors.text, marginLeft: 8, marginRight: 16, position: 'relative' }}>
                                                    <View style={{ position: 'relative', top: 8, left: -2, width: 6, height: 6, backgroundColor: colors.text }} />
                                                </View> : <View style={{ height: '100%', width: 2, backgroundColor: colors.text, marginLeft: 8, marginRight: 16, position: 'relative' }}>
                                                    <View style={{ position: 'relative', top: 8, left: 0, width: 4, height: 2, backgroundColor: colors.text }} />
                                                </View>
                                            )
                                        }
                                        <Text numberOfLines={2} style={{ flex: 1, fontFamily: fontFamily.bold }}>{item.place_name}</Text>
                                    </View>
                                })
                        }
                    </TouchableOpacity>
                </ScrollView>
            </View>
        </Modal>
    )
})