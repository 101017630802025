
import * as React from 'react'
import { Text, View, ScrollView, TouchableOpacity, Image, FlatList, ActivityIndicator, Keyboard, useWindowDimensions } from 'react-native'
import { useTheme } from '@react-navigation/native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import images from '../../assets'
import Collapsible from 'react-native-collapsible'
import TabTicketSeach from '../components/TabTicketSearch'
import axios from 'axios'
import qs from 'qs'
import moment from 'moment'
import { useDispatch } from 'react-redux'
import { setTrip } from '../redux/ActionCreators'
import _ from 'lodash'
import { RefreshControl } from 'react-native-web-refresh-control'

const start_week = moment().startOf('week').format('YYYY-MM-DD')

export default React.memo(function TabTicket({ navigation }) {
    const dispatch = useDispatch()
    const { colors, fontFamily } = useTheme()
    const insets = useSafeAreaInsets()
    const [progress, setProgress] = React.useState(false)
    const [data, setData] = React.useState({})
    const [filter, setFilter] = React.useState({
        start_date: start_week,
        end_date: moment().format('YYYY-MM-DD'),
        phone: '',
        agency_use_id: ''
    })
    const [refreshing, setRefreshing] = React.useState(true)
    const { width } = useWindowDimensions()

    React.useEffect(() => {
        fetchTransaction()
    }, [filter.start_date, filter.end_date, filter.phone, filter.agency_use_id])

    const onRefresh = React.useCallback(() => {
        fetchTransaction()
    }, [])

    React.useEffect(() => {
        if (data.meta?.current_page === 1) fetchMoreTransaction()
    }, [data])

    const fetchTransaction = () => {
        setRefreshing(true)
        axios.get(`tickets?${qs.stringify({
            'date_from': filter.start_date,
            'date_to': filter.end_date,
            'phone': filter.phone,
            'agency_user_id': filter.agency_use_id,
        })}`)
            .then(res => {
                console.log(res)
                setData(res.data)
            })
            .catch(err => console.log(err.response))
            .finally(() =>
                setRefreshing(false)
            )
    }

    const fetchMoreTransaction = _.debounce(() => {
        let page = data.meta?.current_page
        try {
            if (data.meta?.current_page === data.meta?.last_page) return
            else page++
        } catch (error) {
        }
        axios.get(`tickets?${qs.stringify({
            'date_from': filter.start_date,
            'date_to': filter.end_date,
            'phone': filter.phone,
            'agency_user_id': filter.agency_use_id,
            page
        })}`)
            .then(res => {
                console.log(res)
                setData(res.data.data)
                if (data?.data?.length > 0) {
                    let tmp_data = {
                        data: [...data.data, ...res.data.data],
                        meta: res.data.meta,
                        aggregate: res.data.aggregate
                    }
                    setData(tmp_data)
                } else setData(res.data)
            })
            .catch(err => console.log(err.response))
    }, 500)

    const callbackSearch = (filter) => {
        console.log(filter)
        Keyboard.dismiss()
        setFilter(filter)
    }

    const keyExtractor = React.useCallback((item, idx) => `${idx}`)

    const renderItem = React.useCallback(({ item }) => (
        <View style={{ flex: 0.5 }}>
            <View style={{ backgroundColor: 'white', borderRadius: 4, marginHorizontal: 8, marginVertical: 4 }}>
                <TouchableOpacity onPress={() => {
                    item.collapsed = !item.collapsed
                    setData({ ...data })
                }}>
                    <View style={{ flexDirection: 'row', paddingHorizontal: 16, paddingVertical: 12 }}>
                        <View style={{ flex: 1 }}>
                            <Text style={{ color: 'rgba(5, 5, 5, 0.5)', fontFamily: fontFamily.semi_bold }}>Mã đơn hàng</Text>
                            <Text style={{ fontFamily: fontFamily.semi_bold, marginTop: 2 }}>{item.booking?.code}</Text>
                        </View>
                        <View style={{ alignItems: 'flex-end' }}>
                            <Text style={{ color: 'rgba(5, 5, 5, 0.5)', fontFamily: fontFamily.semi_bold }}>Số điện thoại</Text>
                            <Text style={{ fontFamily: fontFamily.semi_bold, marginTop: 2 }}>{item.booking.custmer_phone}</Text>
                        </View>
                        <View style={{ justifyContent: 'center', alignItems: 'center', paddingLeft: 16 }}>
                            <Image source={images.icons.back} style={{ width: 16, height: 16, transform: [{ rotate: item.collapsed ? '90deg' : '-90deg' }] }} />
                        </View>
                    </View>
                </TouchableOpacity>
                <Collapsible collapsed={!item.collapsed}>
                    <View style={{
                        backgroundColor: '#F8F8F8', paddingHorizontal: 8, paddingVertical: 4, borderBottomLeftRadius: 4, borderBottomRightRadius: 4,
                        borderColor: 'rgba(202, 203, 208, 0.5)', borderWidth: 1
                    }}>
                        {
                            item.booking_ticket?.map((ticket, idx) => {
                                return <View style={{ marginVertical: 4, backgroundColor: 'white', borderRadius: 4, padding: 8 }} key={idx}>
                                    <View style={{ flexDirection: 'row' }}>
                                        <View style={{ flex: 1 }}>
                                            <Text style={{ color: 'rgba(5, 5, 5, 0.5)', fontFamily: fontFamily.semi_bold }}>Mã vé</Text>
                                            <Text style={{ fontFamily: fontFamily.semi_bold, marginTop: 2 }}>{ticket.code}</Text>
                                        </View>
                                        <View style={{ alignItems: 'flex-end' }}>
                                            <Text style={{ color: 'rgba(5, 5, 5, 0.5)', fontFamily: fontFamily.semi_bold }}>Giá vé</Text>
                                            <Text style={{ fontFamily: fontFamily.semi_bold, marginTop: 2 }}>{Intl.NumberFormat('vi-VN').format(ticket.money)}</Text>
                                        </View>
                                    </View>
                                    <View style={{ flexDirection: 'row', marginTop: 6 }}>
                                        <View style={{ flex: 1 }}>
                                            <Text style={{ color: 'rgba(5, 5, 5, 0.5)', fontFamily: fontFamily.semi_bold }}>Tên tuyến</Text>
                                            <Text style={{ fontFamily: fontFamily.semi_bold, marginTop: 2 }} numberOfLines={1}>{ticket.route.name}</Text>
                                        </View>
                                        <TouchableOpacity style={{ alignItems: 'flex-end' }}
                                            onPress={() => {
                                                setProgress(true)
                                                axios.get(`trip-detail?sdg=1&trip_id=${item.prefix}${ticket.trip_id}&place_id_a=${ticket.from.id}&place_id_b=${ticket.to.id}`)
                                                    .then(res => {
                                                        console.log(res.data.data)
                                                        if (res.status === 200 && res.data.data) {
                                                            let trip = res.data.data
                                                            console.log(trip)
                                                            dispatch(setTrip(trip))
                                                            navigation.navigate('Seats')
                                                        }
                                                    }).finally(() => {
                                                        setProgress(false)
                                                    })

                                            }}>
                                            <Text style={{ color: 'rgba(5, 5, 5, 0.5)', fontFamily: fontFamily.semi_bold }}>Số ghế</Text>
                                            <Text style={{ fontFamily: fontFamily.semi_bold, marginTop: 2, color: '#1F76E5' }}>{ticket.ticket_no}</Text>
                                        </TouchableOpacity>
                                    </View>
                                    <View style={{ flexDirection: 'row', marginTop: 6 }}>
                                        <View style={{ flex: 1 }}>
                                            <Text style={{ color: 'rgba(5, 5, 5, 0.5)', fontFamily: fontFamily.semi_bold }}>Thời gian đặt</Text>
                                            <Text style={{ fontFamily: fontFamily.semi_bold, marginTop: 2 }}>{moment(ticket.created_at * 1000).format('HH:mm DD/MM')}</Text>
                                        </View>
                                        <View style={{ alignItems: 'flex-end' }}>
                                            <Text style={{ color: 'rgba(5, 5, 5, 0.5)', fontFamily: fontFamily.semi_bold }}>Thời gian đi</Text>
                                            <Text style={{ fontFamily: fontFamily.semi_bold, marginTop: 2 }}>{moment(ticket.start_datetime).format('HH:mm DD/MM')}</Text>
                                        </View>
                                    </View>
                                    <View style={{ flexDirection: 'row', marginTop: 6 }}>
                                        <View style={{ flex: 1 }}>
                                            <Text style={{ color: 'rgba(5, 5, 5, 0.5)', fontFamily: fontFamily.semi_bold }}>Người thu</Text>
                                            <Text style={{ fontFamily: fontFamily.semi_bold, marginTop: 2 }}>{ticket.agency?.is_payment === 1 ? 'Đại lý thu' : 'Đơn vị thu'}</Text>
                                        </View>
                                        <View style={{ alignItems: 'flex-end' }}>
                                            <Text style={{ color: 'rgba(5, 5, 5, 0.5)', fontFamily: fontFamily.semi_bold }}>Trạng thái vé</Text>
                                            <Text style={{ fontFamily: fontFamily.semi_bold, marginTop: 2 }}>{ticket.status_text}</Text>
                                        </View>
                                    </View>
                                    <View style={{ flexDirection: 'row', marginTop: 6 }}>
                                        <View style={{ flex: 1 }}>
                                            <Text style={{ color: 'rgba(5, 5, 5, 0.5)', fontFamily: fontFamily.semi_bold }}>Nhân viên</Text>
                                            <Text style={{ fontFamily: fontFamily.semi_bold, marginTop: 2 }}>{ticket.agency?.agency_user_name}</Text>
                                        </View>
                                    </View>
                                </View>
                            })
                        }
                    </View>
                </Collapsible>
            </View>
        </View >
    ))

    const renderHeaderList = () => {
        return <View style={{ paddingHorizontal: 16 }}>
            {
                data.aggregate ? <>
                    <View style={{ flexDirection: 'row' }}>
                        <View style={{ flexDirection: 'row', alignItems: 'center', flex: 1 }}>
                            <Text>Doanh thu: </Text>
                            <Text style={{ fontFamily: fontFamily.bold, color: '#178C00' }}>{Intl.NumberFormat('vi-VN').format(data?.aggregate?.revenue)}</Text>
                        </View>
                        <View style={{ flexDirection: 'row', alignItems: 'center', flex: 1 }}>
                            <Text>Số vé: </Text>
                            <Text style={{ fontFamily: fontFamily.bold }}><Text style={{ color: '#178C00' }}>{Intl.NumberFormat('vi-VN').format(data?.aggregate?.count_tickets_s)}</Text>/{Intl.NumberFormat('vi-VN').format(data?.aggregate?.count_tickets)}</Text>
                        </View>
                    </View>
                    <View style={{ flexDirection: 'row', marginTop: 4 }}>
                        <View style={{ flexDirection: 'row', alignItems: 'center', flex: 1 }}>
                            <Text>Đại lý thu: </Text>
                            <Text style={{ fontFamily: fontFamily.bold }}>{Intl.NumberFormat('vi-VN').format(data?.aggregate?.agency_reveunue)}</Text>
                        </View>
                        <View style={{ flexDirection: 'row', alignItems: 'center', flex: 1 }}>
                            <Text>Nhà xe thu: </Text>
                            <Text style={{ fontFamily: fontFamily.bold }}>{Intl.NumberFormat('vi-VN').format(data?.aggregate?.mechant_reveunue)}</Text>
                        </View>
                    </View>
                    <View style={{ flexDirection: 'row', marginTop: 4 }}>
                        <View style={{ flexDirection: 'row', alignItems: 'center', flex: 1 }}>
                            <Text>Phạt huỷ: </Text>
                            <Text style={{ fontFamily: fontFamily.bold, color: '#D11315' }}>{Intl.NumberFormat('vi-VN').format(data?.aggregate?.fined_amount)}</Text>
                        </View>
                    </View>
                    <View style={{ height: 1, backgroundColor: 'rgba(202, 203, 208, 0.5)', marginTop: 8, marginBottom: 8 }} />
                </> : null
            }
        </View>
    }

    const renderFooterList = () => {
        return <View style={{ padding: 4, justifyContent: 'center', alignItems: 'center' }}>
            {
                data.data?.length === 0 ? <Text>Chưa có thông tin vé đã bán</Text> : null
            }
            {
                data?.meta?.current_page === data?.meta?.last_page ? null : <ActivityIndicator color={colors.primary} />
            }
        </View>
    }

    return (
        <View style={{ flex: 1, position: 'relative' }}>
            <View style={{ backgroundColor: colors.primary, paddingTop: insets.top ? insets.top : 0 }}>
                <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', height: 48, position: 'relative' }}>
                    <Text style={{ fontFamily: fontFamily.bold, fontSize: 18 }}>Vé đã bán</Text>
                </View>
            </View>
            <View style={{ paddingHorizontal: 16, paddingTop: 8 }}>
                <TabTicketSeach callback={callbackSearch} />
            </View>
            {
                width > 1024 ? <FlatList
                    key={'_'}
                    numColumns={2}
                    style={{ paddingHorizontal: 8 }}
                    refreshControl={
                        <RefreshControl
                            refreshing={refreshing}
                            onRefresh={onRefresh}
                            colors={[colors.primary]}
                        />
                    }
                    keyboardShouldPersistTaps='handled'
                    ListHeaderComponent={renderHeaderList}
                    ListFooterComponent={renderFooterList}
                    data={data.data}
                    renderItem={renderItem}
                    keyExtractor={keyExtractor}
                    onEndReached={fetchMoreTransaction}
                    onEndReachedThreshold={1} /> :
                    <FlatList
                        key={'#'}
                        numColumns={1}
                        style={{ paddingHorizontal: 8 }}
                        refreshControl={
                            <RefreshControl
                                refreshing={refreshing}
                                onRefresh={onRefresh}
                                colors={[colors.primary]}
                            />
                        }
                        keyboardShouldPersistTaps='handled'
                        ListHeaderComponent={renderHeaderList}
                        ListFooterComponent={renderFooterList}
                        data={data.data}
                        renderItem={renderItem}
                        keyExtractor={keyExtractor}
                        onEndReached={fetchMoreTransaction}
                        onEndReachedThreshold={1} />
            }

            {
                progress ? <View style={{
                    top: 0, left: 0, width: '100%', height: '100%',
                    justifyContent: "center", alignItems: "center", position: 'absolute', backgroundColor: '#FFFFFF80'
                }}>
                    <ActivityIndicator color={colors.primary} />
                </View> : null
            }

        </View>
    )
})
