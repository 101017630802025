
import * as React from 'react'
import { Text, View, ScrollView, Image, Dimensions, TouchableOpacity, Platform, FlatList } from 'react-native'
import { useTheme, useNavigation } from '@react-navigation/native'
import images from '../../assets'
import moment from 'moment'
import 'moment-lunar'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { setDate } from '../redux/ActionCreators'

const width = Dimensions.get('window').width

export default React.memo(function DateSelect() {
    const { colors } = useTheme()
    const navigation = useNavigation()
    const ref = React.useRef(null)
    const [arrayDate, setArrayDate] = React.useState([])
    let start_date = moment().add(-1, 'week')
    let end_date = moment().add(1, 'month').endOf('month')
    const date = useSelector(state => state.date, shallowEqual)
    const dispatch = useDispatch()

    console.log(date)

    React.useEffect(() => {
        let array_date = []
        let step = 0
        for (let d = start_date; d < end_date; d.add(1, 'day')) {
            if (d.format('YYYY-MM-DD') === date) step = array_date.length
            array_date.push(d.clone())
        }
        setArrayDate(array_date)
        setTimeout(() => {
            try {
                ref.current.scrollToIndex({ animated: true, index: step, viewPosition: 0.5 })
            } catch (error) {
            }
        }, 500)
    }, [date])

    const renderItem = ({ item, idx }) => {
        return <TouchableOpacity
            style={{ flexDirection: 'column', justifyContent: 'flex-end' }} onPress={() => {
                if (moment().add(-1, 'day').isBefore(item)) {
                    dispatch(setDate(item.format('YYYY-MM-DD')))
                    navigation.navigate({
                        name: 'Trips',
                        params: { date: item.format('YYYY-MM-DD') },
                        merge: true,
                    })
                }
            }}>
            <View style={{
                width: 48, height: 44, backgroundColor: date === item.format('YYYY-MM-DD') ? colors.primary : 'white', borderTopRightRadius: 4,
                borderTopLeftRadius: 4, position: 'relative', opacity: moment().add(-1, 'day').isBefore(item) ? 1 : 0.3
            }}>
                <Text style={{ position: 'absolute', top: 4, left: 10, fontSize: 16, lineHeight: 22, fontFamily: 'Nunito_400Regular' }}>
                    {item.format('D') === '1' ? `${item.format('D')}/${item.format('M')}` : item.format('D')}
                </Text>
                <Text style={{ position: 'absolute', bottom: 7, right: 10, fontSize: 11, opacity: 0.4, lineHeight: 15, fontFamily: 'Nunito_400Regular' }}>
                    {moment(item).lunar().format('D') === '1' ? `${moment(item).lunar().format('D')}/${moment(item).lunar().format('M')}` : moment(item).lunar().format('D')}
                </Text>
            </View>
        </TouchableOpacity>
    }

    return <View style={{ position: 'relative' }}>
        <View style={{ height: 48, backgroundColor: 'white' }}>
            <FlatList
                contentContainerStyle={{ paddingRight: 45, paddingLeft: 20 }}
                showsVerticalScrollIndicator={false}
                showsHorizontalScrollIndicator={false}
                data={arrayDate}
                renderItem={renderItem}
                keyExtractor={(item) => item.format('DD/MM/YYYY')}
                horizontal={true}
                ref={ref}
            />
        </View>
        <View style={{ position: 'absolute', top: 0, left: 0, height: '100%', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <View style={{ position: 'relative' }}>
                <View style={{
                    transform: [{ rotate: '-90deg' }], position: 'absolute', top: Platform.OS === 'web' ? -25 : 0,
                    justifyContent: 'center', alignItems: 'center', width: 20, height: 50,
                }}>
                    <View style={{
                        backgroundColor: colors.primary, width: 50, height: 20,
                        justifyContent: 'center', alignItems: 'center'
                    }}>
                        <Text style={{ fontSize: 10, textAlign: 'center', fontFamily: 'Nunito_600SemiBold' }} numberOfLines={1}>Tháng {moment(date).format('M')}</Text>
                    </View>
                </View>
            </View>
        </View>
        <TouchableOpacity style={{
            position: 'absolute', right: 0, top: 0, height: '100%', width: 45, flexDirection: 'row'
        }} onPress={() => { navigation.navigate('ModalCalendar', { from_route: 'Trips' }) }}>
            <View style={{ height: '100%', width: 1, backgroundColor: 'rgba(202, 203, 208, 0.5)' }} />
            <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', backgroundColor: 'white' }}>
                <Image source={images.icons.calendar} style={{ width: 24, height: 24 }} />
            </View>
        </TouchableOpacity>
        <View style={{ backgroundColor: colors.primary, height: 2, width: '100%' }} />
    </View>
})
