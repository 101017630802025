
import * as React from 'react'
import { Text, View, ScrollView, TouchableOpacity, Image, Dimensions, Linking } from 'react-native'
import { useTheme } from '@react-navigation/native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import images from '../../assets'

const width = Dimensions.get('window').width

export default React.memo(function Contact({ navigation, route }) {
    const { colors, fontFamily } = useTheme()
    const insets = useSafeAreaInsets()

    const handleClick = (url) => {
        Linking.canOpenURL(url).then(supported => {
            if (supported) {
                Linking.openURL(url)
            } else {
                console.log("Don't know how to open URI: " + url)
            }
        });
    };

    return (
        <View style={{ flex: 1 }}>
            <View style={{ backgroundColor: colors.primary, paddingTop: insets.top ? insets.top : 0 }}>
                <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', height: 48, position: 'relative' }}>
                    <Text style={{ fontFamily: fontFamily.bold, fontSize: 18 }}>Liên hệ</Text>
                    <View style={{ position: 'absolute', height: '100%', left: 0, top: 0 }}>
                        <TouchableOpacity onPress={() => {
                            if (navigation.canGoBack())
                                navigation.pop()
                            else window.history.back()
                        }}
                            style={{ height: '100%', justifyContent: 'center', alignItems: 'center', paddingLeft: 16 }}>
                            <Image source={images.icons.back} style={{ width: 16, height: 16 }} />
                        </TouchableOpacity>
                    </View>
                </View>
            </View>
            <ScrollView showsVerticalScrollIndicator={false}>
                <Image source={images.contact} style={{ width: width, height: width * 196 / 375, marginTop: 12 }} resizeMode='contain' />
                <View style={{ paddingHorizontal: 16, paddingVertical: 12 }}>
                    <Text style={{ fontSize: 18, fontFamily: fontFamily.bold }}>Liên hệ với chúng tôi</Text>
                    <Text style={{ marginTop: 12 }}>Đầu mối liên hệ chăm sóc đại lý</Text>
                    <TouchableOpacity style={{
                        flexDirection: 'row', height: 40, justifyContent: 'center', alignItems: 'center',
                        borderBottomWidth: 1, borderBottomColor: 'rgba(202, 203, 208, 0.5)'
                    }} onPress={() => handleClick('tel:19006763')}>
                        <Image source={images.icons.contact_phone} style={{ width: 16, height: 16, marginRight: 6 }} />
                        <Text style={{ fontFamily: fontFamily.semi_bold, flex: 1 }}>Cập nhật sau</Text>
                    </TouchableOpacity>

                    <Text style={{ marginTop: 12 }}>Hòm thư điện tử</Text>
                    <TouchableOpacity style={{
                        flexDirection: 'row', height: 40, justifyContent: 'center', alignItems: 'center',
                        borderBottomWidth: 1, borderBottomColor: 'rgba(202, 203, 208, 0.5)'
                    }} onPress={() => handleClick('mailto:cskh@haivan.com')}>
                        <Image source={images.icons.contact_mail} style={{ width: 16, height: 16, marginRight: 6 }} />
                        <Text style={{ fontFamily: fontFamily.semi_bold, flex: 1 }}>cskh@haivan.com</Text>
                    </TouchableOpacity>

                    <Text style={{ marginTop: 12 }}>Website</Text>
                    <TouchableOpacity style={{
                        flexDirection: 'row', height: 40, justifyContent: 'center', alignItems: 'center',
                        borderBottomWidth: 1, borderBottomColor: 'rgba(202, 203, 208, 0.5)'
                    }} onPress={() => handleClick('https://haivan.com')}>
                        <Image source={images.icons.contact_web} style={{ width: 16, height: 16, marginRight: 6 }} />
                        <Text style={{ fontFamily: fontFamily.semi_bold, flex: 1 }}>haivan.com</Text>
                    </TouchableOpacity>
                </View>
            </ScrollView>
        </View>
    )
})
