
import 'react-native-gesture-handler'
import React from 'react'
import { Nunito_400Regular, Nunito_600SemiBold, Nunito_700Bold, Nunito_900Black, useFonts } from '@expo-google-fonts/nunito'
import AppLoading from 'expo-app-loading'
import { SafeAreaProvider } from 'react-native-safe-area-context'
import { LocaleConfig } from 'react-native-calendars'
import moment from 'moment'
import 'moment/locale/vi'
import axios from 'axios'
import Route from './Route'
import { PersistGate } from 'redux-persist/integration/react'
import { Provider } from 'react-redux'
import configureStore from './src/redux/configureStore'
const { persistor, store } = configureStore()
moment.locale('vi')

LocaleConfig.locales['vi'] = {
  monthNames: ['Tháng Một', 'Tháng Hai', 'Tháng Ba', 'Tháng Tư', 'Tháng Năm', 'Tháng Sáu', 'Tháng Bảy', 'Tháng Tám', 'Tháng Chín', 'Tháng Mười', 'Tháng Mười Một', 'Tháng Mười Hai'],
  monthNamesShort: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
  dayNames: ['Chủ nhật', 'Thứ 2', 'Thứ 3', 'Thứ 4', 'Thứ 5', 'Thứ 6', 'Thứ 7'],
  dayNamesShort: ['CN', 'T2', 'T3', 'T4', 'T5', 'T6', 'T7'],
  today: 'Hôm nay'
}
LocaleConfig.defaultLocale = 'vi'

export default function App() {
  axios.defaults.headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }

  // axios.defaults.baseURL = 'https://test.api.sanbox.mybus.vn/api/'
  // axios.defaults.baseURL = 'https://apigw-ota.mybus.vn/api/'
  axios.defaults.baseURL = 'https://api.ta.haivan.com/api/'

  let [fontsLoaded] = useFonts({
    Nunito_400Regular, Nunito_600SemiBold, Nunito_700Bold, Nunito_900Black
  })

  if (!fontsLoaded) return <AppLoading />

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <SafeAreaProvider>
          <Route />
        </SafeAreaProvider>
      </PersistGate>
    </Provider>
  )
}