
import * as React from 'react'
import { Text, View, ScrollView, TouchableOpacity, Image, useWindowDimensions, Linking } from 'react-native'
import { useTheme, CommonActions } from '@react-navigation/native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import images from '../../assets'
import 'moment-lunar'
import { SlideAnimation, Modal } from 'react-native-modals'
import { setModalFilterChange } from '../redux/ActionCreators'
import moment from 'moment'
import { TextInputMask } from 'react-native-masked-text'

export default React.memo(function ModalFilterChange({ filter, visible, callback }) {
    const { colors, fontFamily } = useTheme()
    const insets = useSafeAreaInsets()
    const [inner_filter, setInnerFilter] = React.useState({})

    const list_type = [
        'Khai tăng',
        'Khai giảm',
        'Cộng hoa hồng',
        'Mua vé',
        'Hoàn hủy vé',
        'Thu hồi tiền hoa hồng',
        'Phạt hủy vé'
    ]

    React.useEffect(() => {
        setInnerFilter({
            ...filter,
            start_date: moment(filter.start_date).format('DD/MM/YYYY'),
            end_date: moment(filter.end_date).format('DD/MM/YYYY'),
            types: [...filter.types]
        })
    }, [filter.start_date, filter.end_date, filter.types])

    return (
        <Modal
            visible={visible}
            modalAnimation={new SlideAnimation({
                slideFrom: 'bottom',
            })}
            onTouchOutside={() => {
                setInnerFilter({
                    ...filter,
                    start_date: moment(filter.start_date).format('DD/MM/YYYY'),
                    end_date: moment(filter.end_date).format('DD/MM/YYYY')
                })
                callback({ visible: false })
            }}
            width={1}
            height={1}
        >
            <View style={{ flex: 1, backgroundColor: 'white' }}>
                <View style={{ paddingTop: insets.top ? insets.top : 0 }}>
                    <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', height: 48, paddingHorizontal: 8 }}>
                        <TouchableOpacity style={{ padding: 8 }} onPress={() => {
                            setInnerFilter({
                                ...filter,
                                start_date: moment(filter.start_date).format('DD/MM/YYYY'),
                                end_date: moment(filter.end_date).format('DD/MM/YYYY')
                            })
                            callback({ visible: false })
                        }}>
                            <Image source={images.icons.close} style={{ width: 16, height: 16 }} />
                        </TouchableOpacity>
                        <Text style={{ flex: 1, textAlign: 'center', color: colors.text, fontFamily: 'Nunito_700Bold', fontSize: 18 }}>
                            Bộ lọc
                        </Text>
                        <TouchableOpacity style={{ padding: 8, opacity: 0 }}>
                            <Image source={images.icons.close} style={{ width: 16, height: 16 }} />
                        </TouchableOpacity>
                    </View>
                </View>
                <ScrollView showsVerticalScrollIndicator={false} keyboardShouldPersistTaps='handled'>
                    <TouchableOpacity activeOpacity={.99}>
                        <Text style={{ fontFamily: fontFamily.bold, marginBottom: 4, marginTop: 8, marginHorizontal: 16 }}>Tác vụ</Text>
                        <View style={{ flexDirection: 'row', flexWrap: 'wrap', paddingHorizontal: 11 }}>
                            {
                                list_type.map((item, idx) => {
                                    try {
                                        return <TouchableOpacity key={idx} onPress={() => {
                                            if (inner_filter.types?.includes(idx + 1)) {
                                                inner_filter.types.splice(inner_filter.types?.indexOf(idx + 1), 1)
                                                setInnerFilter({ ...inner_filter })
                                            }
                                            else setInnerFilter({ ...inner_filter, types: [...inner_filter.types, idx + 1] })
                                        }}
                                            style={{
                                                margin: 4, borderRadius: 4, backgroundColor: inner_filter.types.includes(idx + 1) ? colors.primary : 'white',
                                                borderColor: inner_filter.types.includes(idx + 1) ? colors.primary : colors.text, borderWidth: 1
                                            }}>
                                            <Text style={{ paddingVertical: 5, paddingHorizontal: 10, fontFamily: fontFamily.regular }}>{item}</Text>
                                        </TouchableOpacity>
                                    } catch (error) {
                                        console.log(error)
                                    }
                                })
                            }
                        </View>
                        <Text style={{ fontFamily: fontFamily.bold, marginBottom: 4, marginTop: 12, marginHorizontal: 16 }}>Thời gian</Text>
                        <View style={{ flexDirection: 'row', paddingHorizontal: 16 }}>
                            <View style={{ flex: 1, position: 'relative', backgroundColor: 'white', borderRadius: 4, backgroundColor: '#F0F2F5' }}>
                                <View style={{ height: '100%', position: 'absolute', justifyContent: 'center', alignItems: 'center' }}>
                                    <Image source={images.icons.calendar} style={{ width: 16, height: 16, marginLeft: 12 }} />
                                </View>
                                <TextInputMask
                                    type={'datetime'}
                                    options={{ format: 'DD/MM/YYYY' }}
                                    style={{
                                        height: 40, fontFamily: fontFamily.semi_bold, paddingLeft: 36, paddingRight: 8,
                                        borderRadius: 4
                                    }}
                                    value={inner_filter.start_date}
                                    onChangeText={start_date => {
                                        setInnerFilter({ ...inner_filter, start_date })
                                    }}
                                    onBlur={() => {
                                        if (inner_filter.start_date && moment(inner_filter.start_date, "DD/MM/YYYY", true).isValid()) { }
                                        else setInnerFilter({ ...inner_filter, start_date: moment().format('DD/MM/YYYY') })
                                    }}
                                />
                            </View>
                            <View style={{ padding: 4 }} />
                            <View style={{ flex: 1, position: 'relative', backgroundColor: 'white', borderRadius: 4, backgroundColor: '#F0F2F5' }}>
                                <View style={{ height: '100%', position: 'absolute', justifyContent: 'center', alignItems: 'center' }}>
                                    <Image source={images.icons.calendar} style={{ width: 16, height: 16, marginLeft: 12 }} />
                                </View>
                                <TextInputMask
                                    type={'datetime'}
                                    options={{ format: 'DD/MM/YYYY' }}
                                    style={{
                                        height: 40, fontFamily: fontFamily.semi_bold, paddingLeft: 36, paddingRight: 8,
                                        borderRadius: 4
                                    }}
                                    value={inner_filter.end_date}
                                    onChangeText={end_date => {
                                        setInnerFilter({ ...inner_filter, end_date })
                                    }}
                                    onBlur={() => {
                                        if (inner_filter.end_date && moment(inner_filter.end_date, "DD/MM/YYYY", true).isValid()) { }
                                        else setInnerFilter({ ...inner_filter, end_date: moment().format('DD/MM/YYYY') })
                                    }}
                                />
                            </View>
                        </View>
                    </TouchableOpacity>
                </ScrollView>
                <TouchableOpacity style={{
                    height: 45, backgroundColor: colors.primary, margin: 16, marginBottom: 16 + insets.bottom,
                    justifyContent: 'center', alignItems: 'center', borderRadius: 6
                }} onPress={() => {
                    let data = {
                        ...inner_filter,
                        start_date: moment(inner_filter.start_date, 'DD/MM/YYYY').format('YYYY-MM-DD'),
                        end_date: moment(inner_filter.end_date, 'DD/MM/YYYY').format('YYYY-MM-DD'),
                    }
                    callback({ visible: false, filter: data })
                }}>
                    <Text style={{ fontSize: 16, fontFamily: fontFamily.semi_bold }}>Áp dụng</Text>
                </TouchableOpacity>
            </View>
        </Modal>
    )
})