
import * as React from 'react'
import { Text, View, ScrollView, TouchableOpacity, Image, ActivityIndicator } from 'react-native'
import { useTheme } from '@react-navigation/native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { Step } from '../components'
import images from '../../assets'
import axios from 'axios'
import { showMessage } from "react-native-flash-message"
import moment from 'moment'
import CountDown from '../libs/countdown'

export default React.memo(function Payment({ navigation, route }) {
    const { colors, fontFamily } = useTheme()
    const insets = useSafeAreaInsets()
    const [progress, setProgress] = React.useState(false)

    const { trip, booking, seats, transit_start, transit_end, info, pick_up, drop_off } = route.params
    const reducer = (accumulator, currentValue) => accumulator + currentValue

    const requestBooking = (is_payment) => {
        setProgress(true)
        axios.post('bookings', {
            "pick_up": {
                "address": pick_up?.address ? pick_up.address : transit_start.place_name,
                "id": transit_start.place_id,
                "coordinates": pick_up?.coordinates ? pick_up.coordinates : null
            },
            "drop_off": {
                "address": drop_off?.address ? drop_off.address : transit_end.place_name,
                "id": transit_end.place_id,
                "coordinates": drop_off?.coordinates ? drop_off.coordinates : null
            },
            "trip": {
                "trip_id": trip.id,
                "departure_id": trip.from.place_id,
                "destination_id": trip.to.place_id
            },
            "seats": seats,
            "order_code": booking.order_code,
            "note": info.note,
            "customer": { ...info },
            "transit": {
                departure: pick_up?.is_transit ? 1 : 0,
                destination: drop_off?.is_transit ? 1 : 0
            },
            is_payment
        }).then(res => {
            console.log(res)
            setProgress(false)
            if (res.status === 200) {
                navigation.navigate({
                    name: 'Seats',
                    params: { reload: moment().unix() },
                    merge: true,
                })
                showMessage({
                    message: "Thông báo",
                    description: "Đặt vé thành công!",
                    type: "info",
                })
            } else {
                showMessage({
                    message: "Thông báo",
                    description: res.data?.message ? res.data?.message : "Có lỗi xảy ra trong quá trình đặt vé!",
                    type: "warning",
                })
            }
        }).catch(err => {
            setProgress(false)
            showMessage({
                message: "Thông báo",
                description: err.response?.data?.message ? err.response?.data?.message : "Có lỗi xảy ra trong quá trình đặt vé!",
                type: "warning",
            })
            console.log(err.response)
        })
    }

    const cancelBooking = () => {
        navigation.navigate({
            name: 'Trips',
            merge: true,
        })
    }

    console.log(trip)

    return (
        <View style={{ flex: 1, position: 'relative' }}>
            <View style={{ backgroundColor: colors.primary, paddingTop: insets.top ? insets.top : 0 }}>
                <View style={{
                    flexDirection: 'row', justifyContent: 'center', alignItems: 'center', height: 48,
                    position: 'relative',
                }}>
                    <Text style={{ fontFamily: 'Nunito_700Bold', fontSize: 18 }}>Thanh toán</Text>
                    <View style={{ position: 'absolute', height: '100%', left: 0, top: 0 }}>
                        <TouchableOpacity onPress={() => navigation.pop()}
                            style={{ height: '100%', justifyContent: 'center', alignItems: 'center', paddingLeft: 16 }}>
                            <Image source={images.icons.back} style={{ width: 16, height: 16 }} />
                        </TouchableOpacity>
                    </View>
                    <View style={{ position: 'absolute', height: '100%', right: 16, top: 0, justifyContent: 'center', alignItems: 'center' }}>
                        <CountDown
                            until={moment(booking.expire_time, 'DD-MM-YYYY HH:mm:ss').unix() - moment().unix()}
                            size={14}
                            onFinish={cancelBooking}
                            digitStyle={{ backgroundColor: 'transparent', borderWidth: 0, marginHorizontal: 0 }}
                            digitTxtStyle={{ color: 'black', fontFamily: fontFamily.semi_bold }}
                            timeToShow={['M', 'S']}
                            timeLabels={{ m: null, s: null }}
                            separatorStyle={{ marginBottom: 2, fontFamily: fontFamily.semi_bold }}
                            showSeparator
                        />
                    </View>
                </View>
            </View>
            <View>
                <Step step={5} />
            </View>

            <ScrollView showsVerticalScrollIndicator={false}>
                <View style={{ padding: 16, backgroundColor: 'white' }}>
                    <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
                        <Text style={{ color: '#514F4F', flex: 4, fontFamily: 'Nunito_400Regular' }}>Hành khách</Text>
                        <Text style={{ fontFamily: fontFamily.semi_bold, flex: 5 }}>{info.name}</Text>
                    </View>
                    <View style={{ flexDirection: 'row', justifyContent: 'center', marginTop: 8 }}>
                        <Text style={{ color: '#514F4F', flex: 4, fontFamily: 'Nunito_400Regular' }}>Số điện thoại</Text>
                        <Text style={{ fontFamily: fontFamily.semi_bold, flex: 5 }}>{info.phone}</Text>
                    </View>
                    {
                        info.email ? <View style={{ flexDirection: 'row', justifyContent: 'center', marginTop: 8 }}>
                            <Text style={{ color: '#514F4F', flex: 4, fontFamily: 'Nunito_400Regular' }}>Email</Text>
                            <Text style={{ fontFamily: fontFamily.semi_bold, flex: 5 }}>{info.email}</Text>
                        </View> : null
                    }
                    {
                        info.note ? <View style={{ flexDirection: 'row', justifyContent: 'center', marginTop: 8 }}>
                            <Text style={{ color: '#514F4F', flex: 4, fontFamily: 'Nunito_400Regular' }}>Ghi chú</Text>
                            <Text style={{ fontFamily: fontFamily.semi_bold, flex: 5 }}>{info.note}</Text>
                        </View> : null
                    }
                    <View style={{ width: '100%', backgroundColor: '#E9E9E9', height: 1, marginVertical: 8 }} />
                    <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
                        <Text style={{ color: '#514F4F', flex: 4, fontFamily: 'Nunito_400Regular' }}>Mã vé</Text>
                        <Text style={{ color: '#1F76E5', fontFamily: fontFamily.bold, fontSize: 16, flex: 5 }}>{booking.code ? booking.code : booking.tickets[0]?.code}</Text>
                    </View>
                    <View style={{ flexDirection: 'row', justifyContent: 'center', marginTop: 8 }}>
                        <Text style={{ color: '#514F4F', flex: 4, fontFamily: 'Nunito_400Regular' }}>Ngày khởi hành</Text>
                        <Text style={{ fontFamily: fontFamily.semi_bold, flex: 5 }}>{trip.time} {moment(trip.date).format('DD/MM/YYYY')}</Text>
                    </View>
                    <View style={{ flexDirection: 'row', justifyContent: 'center', marginTop: 8 }}>
                        <Text style={{ color: '#514F4F', flex: 4, fontFamily: 'Nunito_400Regular' }}>Điểm lên xe</Text>
                        <Text style={{ fontFamily: fontFamily.semi_bold, flex: 5 }}>
                            {moment(transit_start?.place_time ? transit_start.place_time : transit_start.reached_at).format('HH:mm')} - {transit_start.place_name}
                        </Text>
                    </View>
                    <View style={{ flexDirection: 'row', justifyContent: 'center', marginTop: 8 }}>
                        <Text style={{ color: '#514F4F', flex: 4, fontFamily: 'Nunito_400Regular' }}>Điểm xuống xe</Text>
                        <Text style={{ fontFamily: fontFamily.semi_bold, flex: 5 }}>
                            {moment(transit_end?.place_time ? transit_end.place_time : transit_end.reached_at).format('HH:mm')} - {transit_end.place_name}
                        </Text>
                    </View>

                    <View style={{ width: '100%', backgroundColor: '#E9E9E9', height: 1, marginVertical: 8 }} />
                    <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
                        <Text style={{ color: '#514F4F', flex: 4, fontFamily: 'Nunito_400Regular' }}>Tên tuyến</Text>
                        <Text style={{ fontFamily: fontFamily.semi_bold, flex: 5 }}>{trip.route.name}</Text>
                    </View>
                    <View style={{ flexDirection: 'row', justifyContent: 'center', marginTop: 8 }}>
                        <Text style={{ color: '#514F4F', flex: 4, fontFamily: 'Nunito_400Regular' }}>Hạng xe</Text>
                        <Text style={{ fontFamily: fontFamily.semi_bold, flex: 5 }}>{trip.product.name}</Text>
                    </View>
                    <View style={{ flexDirection: 'row', justifyContent: 'center', marginTop: 8 }}>
                        <Text style={{ color: '#514F4F', flex: 4, fontFamily: 'Nunito_400Regular' }}>Số ghế/giường</Text>
                        <Text style={{ fontFamily: fontFamily.semi_bold, flex: 5 }}>
                            {
                                seats.map(item => item.name).join(',')
                            }
                        </Text>
                    </View>
                    <View style={{ width: '100%', backgroundColor: '#E9E9E9', height: 1, marginVertical: 8 }} />
                    <View style={{ flexDirection: 'row', alignItems: 'flex-end' }}>
                        <Text style={{ fontSize: 16, fontFamily: fontFamily.bold, flex: 1 }}>Tổng tiền</Text>
                        <Text style={{ fontSize: 20, fontFamily: fontFamily.bold, color: '#1F76E5' }}>{
                            (Intl.NumberFormat('vi-VN').format(Object.values(seats).map(item => item.price).reduce(reducer)))
                        }đ</Text>
                    </View>
                </View>
            </ScrollView>

            <View style={{
                paddingHorizontal: 16, paddingBottom: 12 + insets.bottom, flexDirection: 'row'
            }}>
                <TouchableOpacity style={{
                    height: 45, backgroundColor: colors.primary, marginTop: 8,
                    justifyContent: 'center', alignItems: 'center', borderRadius: 6, flex: 1
                }} onPress={() => requestBooking(1)}>
                    <Text style={{ fontSize: 16, fontFamily: fontFamily.semi_bold }}>Đại lý thu</Text>
                </TouchableOpacity>
                <View style={{ paddingHorizontal: 5 }} />
                <TouchableOpacity style={{
                    height: 45, backgroundColor: 'rgba(202, 203, 208, 0.5)', marginTop: 8,
                    justifyContent: 'center', alignItems: 'center', borderRadius: 6, flex: 1
                }} onPress={() => requestBooking(2)}>
                    <Text style={{ fontSize: 16, fontFamily: fontFamily.semi_bold }}>Đơn vị thu</Text>
                </TouchableOpacity>
            </View>

            {
                progress ? <View style={{
                    top: 0, left: 0, width: '100%', height: '100%',
                    justifyContent: "center", alignItems: "center", position: 'absolute', backgroundColor: '#FFFFFF80'
                }}>
                    <ActivityIndicator color={colors.primary} />
                </View> : null
            }
        </View>
    )
})
