
import * as React from 'react'
import { Text, View, TouchableOpacity, Image, FlatList, ActivityIndicator, useWindowDimensions, Platform } from 'react-native'
import { useTheme } from '@react-navigation/native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { Step, DateSelect, SortFilterTrip } from '../components'
import images from '../../assets'
import axios from 'axios'
import qs from 'qs'
import moment from 'moment'
import _ from 'lodash'
import { setTrip, setDate } from "../redux/ActionCreators"
import { useDispatch, useSelector, shallowEqual } from 'react-redux'

export default React.memo(function Trips({ navigation, route }) {
    const { colors, fontFamily } = useTheme()
    const insets = useSafeAreaInsets()
    const dispatch = useDispatch()
    const [data, setData] = React.useState({})
    const date = useSelector(state => state.date, shallowEqual)
    let { from, to } = route.params
    const sort_filter = React.useRef({
        filter: {
            pickup_time_range: 0,
            available_seats: 0,
        },
        sort: {
            sort_by: 'time',
            sort_mode: 'asc'
        }
    })
    const { width } = useWindowDimensions()

    React.useEffect(() => {
        dispatch(setDate(moment(route.params.date, 'YYYY-MM-DD').format('YYYY-MM-DD')))
    }, [route.params.date])

    React.useEffect(() => {
        setData({})
        fetchTrips()
    }, [date])

    const changePickTime = (select) => {
        let format_day = moment().format(date)
        switch (select) {
            case 0: return ''
            case 1: return `${format_day} 00:00:00|${format_day} 12:00:00`
            case 2: return `${format_day} 12:00:00|${format_day} 18:00:00`
            case 3: return `${format_day} 18:00:00|${format_day} 23:59:59`
            default: return ''
        }
    }

    const fetchTrips = _.debounce(() => {
        console.log(route.params)
        let page = 1
        axios.get(`trips?${qs.stringify({
            'filter[from]': from,
            'filter[to]': to,
            'filter[date]': moment(date).format('YYYY-MM-DD'),
            'filter[time_range]': changePickTime(sort_filter.current.filter?.pickup_time_range),
            'filter[seat_available]': sort_filter.current.filter?.available_seats,
            'sort[time_price]': sort_filter.current.sort?.sort_by,
            'sort[order]': sort_filter.current.sort?.sort_mode,
            page
        })}`).then(res => {
            console.log(res)
            if (res.status === 200 && res.data) {
                setData(res.data)
            }
        }).catch(err => console.log(err.response))
    }, 250)

    const fetchMoreTrips = _.debounce(() => {
        let page = data?.meta?.current_page
        try {
            if (data?.meta?.current_page === data?.meta?.last_page) return
            else page++
        } catch (error) {
        }

        axios.get(`trips?${qs.stringify({
            'filter[from]': from,
            'filter[to]': to,
            'filter[date]': moment(date).format('YYYY-MM-DD'),
            'filter[time_range]': changePickTime(sort_filter.current.filter?.pickup_time_range),
            'filter[seat_available]': sort_filter.current.filter?.available_seats,
            'sort[time_price]': sort_filter.current.sort?.sort_by,
            'sort[order]': sort_filter.current.sort?.sort_mode,
            page
        })}`).then(res => {
            console.log(res)
            if (res.status === 200 && res.data) {
                if (data?.data?.length > 0) {
                    let tmp_data = {
                        data: [...data.data, ...res.data.data],
                        meta: res.data.meta
                    }
                    setData(tmp_data)
                } else setData(res.data)
            }
        })
    }, 250)

    const callbackSortFilter = (data) => {
        console.log(data)
        sort_filter.current = { ...data }
        setData({})
        fetchTrips()
    }

    const renderItem = React.useCallback(({ item }) => (
        <View style={{ paddingVertical: 8, flex: 0.5 }}>
            <TouchableOpacity
                style={{
                    marginHorizontal: 8, backgroundColor: 'white', borderRadius: 8, opacity: item.available_seats ? 1 : 0.5
                }} onPress={() => {
                    dispatch(setTrip(item))
                    navigation.navigate('Seats')
                }}>
                <View style={{ flexDirection: 'row', paddingHorizontal: 16, paddingTop: 8, paddingBottom: 6 }}>
                    <Text style={{ fontFamily: fontFamily.bold, flex: 1 }} numberOfLines={1}>{item.route?.name}</Text>
                    <Text style={{ fontFamily: fontFamily.bold, marginLeft: 8 }} numberOfLines={1}>
                        {item.price?.max !== item.price?.min ? `Từ ${Intl.NumberFormat('vi-VN').format(item.price?.min)} ` : Intl.NumberFormat('vi-VN').format(item.price?.max)}đ
                    </Text>
                </View>
                <View style={{ width: '100%', height: 1, backgroundColor: 'rgba(202, 203, 208, 0.5)' }} />
                <View style={{ flexDirection: 'column', paddingHorizontal: 16, paddingTop: 6, paddingBottom: 8 }}>
                    <View style={{ flexDirection: 'row' }}>
                        <View style={{ flexDirection: 'column', alignItems: 'center', paddingTop: 6, paddingBottom: 4 }}>
                            <View style={{ width: 6, height: 6, backgroundColor: '#050505', borderRadius: 3 }} />
                            <View style={{ backgroundColor: '#050505', width: 2, height: 20, marginVertical: 4 }} />
                            <View style={{ width: 6, height: 6, backgroundColor: '#050505' }} />
                        </View>
                        <View style={{ flexDirection: 'column', justifyContent: 'space-between', marginLeft: 8, marginRight: 16 }}>
                            <Text style={{ fontSize: 16, fontFamily: fontFamily.bold, marginBottom: 8 }}>{moment(item.from?.reached_at).format('HH:mm')}</Text>
                            <Text style={{ fontSize: 16, fontFamily: fontFamily.bold }}>{moment(item.to?.reached_at).format('HH:mm')}</Text>
                        </View>
                        <View style={{ flexDirection: 'column', justifyContent: 'space-between', flex: 1 }}>
                            <Text style={{ fontSize: 16, fontFamily: fontFamily.bold }} numberOfLines={1}>{item.from?.place_name}</Text>
                            <Text style={{ fontSize: 16, fontFamily: fontFamily.bold }} numberOfLines={1}>{item.to?.place_name}</Text>
                        </View>
                    </View>
                </View>
                <View style={{ width: '100%', height: 1, backgroundColor: 'rgba(202, 203, 208, 0.5)' }} />
                <View style={{ flexDirection: 'row', paddingHorizontal: 16, paddingVertical: 6 }}>
                    <View style={{ flexDirection: 'row', alignItems: 'center', flex: 1 }}>
                        <Text style={{ fontFamily: fontFamily.bold }} numberOfLines={1}>{item.product?.name}</Text>
                        <View style={{ width: 4, height: 4, borderRadius: 2, marginHorizontal: 4, backgroundColor: '#050505' }} />
                        <Text style={{
                            fontFamily: fontFamily.regular, fontFamily: item.available_seats > 3 ? fontFamily.normal : fontFamily.bold,
                            color: item.available_seats > 3 ? colors.text : '#D11315'
                        }} numberOfLines={1}>{item.available_seats === 0 ? 'Hết chỗ' : `${item.available_seats} chỗ trống`}</Text>
                    </View>
                    {
                        item.node_time !== item.from?.reached_at ? <Text style={{ color: '#CF1820', fontFamily: fontFamily.bold }}>*Khởi hành lúc {moment(item.node_time).format('HH:mm')}</Text>
                            : null
                    }
                </View>
            </TouchableOpacity>
        </View>
    ))

    const keyExtractor = React.useCallback((item, index) => `${index}`)

    return (
        <View style={{ flex: 1 }}>
            <View style={{ backgroundColor: colors.primary, paddingTop: insets.top ? insets.top : 0 }}>
                <View style={{
                    flexDirection: 'row', justifyContent: 'center', alignItems: 'center', height: 48,
                    position: 'relative',
                }}>
                    <Text style={{ fontFamily: fontFamily.bold, fontSize: 18 }}>Danh sách chuyến đi</Text>
                    <View style={{ position: 'absolute', height: '100%', left: 0, top: 0 }}>
                        <TouchableOpacity onPress={() => {
                            if (Platform.OS === 'web') window.history.back()
                            else navigation.pop()
                        }}
                            style={{ height: '100%', justifyContent: 'center', alignItems: 'center', paddingLeft: 16 }}>
                            <Image source={images.icons.back} style={{ width: 16, height: 16 }} />
                        </TouchableOpacity>
                    </View>
                </View>
            </View>
            <View>
                <Step step={1} />
            </View>
            <View>
                <DateSelect />
            </View>

            {
                data?.data ?
                    data?.data?.length === 0 ? <View style={{ flex: 1, alignItems: 'center', marginTop: 80 }}>
                        <Image source={images.trips_empty} style={{ width: 160, height: 160 }} />
                        <Text style={{ marginTop: 26, fontFamily: fontFamily.regular }}>Không tìm thấy chuyến đi phù hợp!</Text>
                    </View> : (
                        width > 1024 ? <FlatList
                            key={'_'}
                            style={{ paddingHorizontal: 8 }}
                            numColumns={2}
                            initialNumToRender={10}
                            ListHeaderComponent={() => <View style={{ marginBottom: 8 }} />}
                            ListFooterComponent={() => <View style={{ padding: 4, justifyContent: 'center', alignItems: 'center' }}>
                                {
                                    data?.meta?.current_page === data?.meta?.last_page ? null : <ActivityIndicator color={colors.primary} />
                                }
                            </View>}
                            data={data.data}
                            renderItem={renderItem}
                            keyExtractor={keyExtractor}
                            onEndReached={fetchMoreTrips}
                            onEndReachedThreshold={0.5} /> :
                            <FlatList
                                key={'#'}
                                style={{ paddingHorizontal: 8 }}
                                numColumns={1}
                                initialNumToRender={10}
                                ListHeaderComponent={() => <View style={{ marginBottom: 8 }} />}
                                ListFooterComponent={() => <View style={{ padding: 4, justifyContent: 'center', alignItems: 'center' }}>
                                    {
                                        data?.meta?.current_page === data?.meta?.last_page ? null : <ActivityIndicator color={colors.primary} />
                                    }
                                </View>}
                                data={data.data}
                                renderItem={renderItem}
                                keyExtractor={keyExtractor}
                                onEndReached={fetchMoreTrips}
                                onEndReachedThreshold={0.5} />
                    )
                    : <View style={{ alignItems: 'center', flex: 1, marginTop: 24 }}>
                        <ActivityIndicator color={colors.primary} />
                    </View>
            }

            {
                data?.data?.length > 0 ? <SortFilterTrip callback={callbackSortFilter} sort_filter={sort_filter.current} date={date} /> : null
            }
        </View>
    )
})
