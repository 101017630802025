import * as React from 'react'
import { View, Text, TouchableOpacity, Image } from 'react-native'
import { useTheme } from '@react-navigation/native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'

export default function MyTabBar({ state, descriptors, navigation }) {
    const { colors, fontFamily } = useTheme()
    const insets = useSafeAreaInsets()

    return (
        <View style={{ flexDirection: 'row', backgroundColor: 'white', paddingBottom: insets.bottom ? insets.bottom - 16 : 0 }}>
            {state.routes.map((route, index) => {
                const { options } = descriptors[route.key];
                const label =
                    options.tabBarLabel !== undefined
                        ? options.tabBarLabel
                        : options.title !== undefined
                            ? options.title
                            : route.name

                const isFocused = state.index === index

                const onPress = () => {
                    const event = navigation.emit({
                        type: 'tabPress',
                        target: route.key,
                        canPreventDefault: true,
                    })

                    if (!isFocused && !event.defaultPrevented) {
                        navigation.navigate(route.name);
                    }
                };

                const onLongPress = () => {
                    navigation.emit({
                        type: 'tabLongPress',
                        target: route.key,
                    })
                }

                return (
                    <TouchableOpacity
                        key={index}
                        accessibilityRole="button"
                        accessibilityState={isFocused ? { selected: true } : {}}
                        accessibilityLabel={options.tabBarAccessibilityLabel}
                        testID={options.tabBarTestID}
                        onPress={onPress}
                        onLongPress={onLongPress}
                        style={{ flex: 1 }}
                    >
                        <View style={{ height: 60, justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                            <Image source={isFocused ? options.tabBarImage[1] : options.tabBarImage[0]} style={{ width: 24, height: 24 }} resizeMode='contain' />
                            <Text style={{ color: isFocused ? colors.primary : 'rgba(5, 5, 5, 0.5)', fontSize: 10, marginTop: 4, fontFamily: fontFamily.bold }}>
                                {label}
                            </Text>
                        </View>
                    </TouchableOpacity>
                )
            })}
        </View>
    );
}