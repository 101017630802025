import * as React from 'react'
import { View, TextInput, TouchableOpacity, Image, Text, ActivityIndicator } from 'react-native'
import { useTheme } from '@react-navigation/native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import images from '../../assets'
import moment from 'moment'
import { TextInputMask } from 'react-native-masked-text'
import { Modal } from 'react-native-modals'
import axios from 'axios'
import { useSelector, shallowEqual } from 'react-redux'

const start_week = moment().startOf('week').format('DD/MM/YYYY')

export default function TabTicketSeach({ callback }) {
    const { colors, fontFamily } = useTheme()
    const insets = useSafeAreaInsets()
    const [info, setInfo] = React.useState({})
    const [modal_filter, setModalFilter] = React.useState(false)
    const user_login = useSelector(state => state.user_login, shallowEqual)
    const [data, setData] = React.useState({
        start_date: start_week,
        end_date: moment().format('DD/MM/YYYY'),
        phone: '',
        agency_use_id: ''
    })

    React.useEffect(() => {
        fetchInfoAgency()
    }, [])

    const fetchInfoAgency = () => {
        axios.get(`agencies`)
            .then(res => {
                console.log(res)
                setInfo(res.data.data)
            })
            .catch(err => console.log(err.response))
    }

    return (
        <>
            <View style={{ flexDirection: 'row' }}>
                <View style={{ flex: 1, position: 'relative', backgroundColor: 'white', borderRadius: 4 }}>
                    <View style={{ height: '100%', position: 'absolute', justifyContent: 'center', alignItems: 'center' }}>
                        <Image source={images.icons.calendar} style={{ width: 16, height: 16, marginLeft: 12 }} />
                    </View>
                    <TextInputMask
                        type={'datetime'}
                        options={{ format: 'DD/MM/YYYY' }}
                        style={{
                            height: 40, fontFamily: fontFamily.semi_bold, paddingLeft: 36, paddingRight: 8
                        }}
                        value={data.start_date}
                        onChangeText={start_date => {
                            setData({ ...data, start_date })
                        }}
                        onBlur={() => {
                            if (data.start_date && moment(data.start_date, "DD/MM/YYYY", true).isValid()) { }
                            else setData({ ...data, start_date: moment().format('DD/MM/YYYY') })
                        }}
                    />
                </View>
                <View style={{ padding: 4 }} />
                <View style={{ flex: 1, position: 'relative', backgroundColor: 'white', borderRadius: 4 }}>
                    <View style={{ height: '100%', position: 'absolute', justifyContent: 'center', alignItems: 'center' }}>
                        <Image source={images.icons.calendar} style={{ width: 16, height: 16, marginLeft: 12 }} />
                    </View>
                    <TextInputMask
                        type={'datetime'}
                        options={{ format: 'DD/MM/YYYY' }}
                        style={{
                            height: 40, fontFamily: fontFamily.semi_bold, paddingLeft: 36, paddingRight: 8
                        }}
                        value={data.end_date}
                        onChangeText={end_date => {
                            setData({ ...data, end_date })
                        }}
                        onBlur={() => {
                            if (data.end_date && moment(data.end_date, "DD/MM/YYYY", true).isValid()) { }
                            else setData({ ...data, end_date: moment().format('DD/MM/YYYY') })
                        }}
                    />
                </View>
            </View>
            <View style={{ flexDirection: 'row' }}>
                <View style={{ flex: 1, position: 'relative', marginTop: 8, backgroundColor: 'white', borderRadius: 4 }}>
                    <View style={{ height: '100%', position: 'absolute', justifyContent: 'center', alignItems: 'center' }}>
                        <Image source={images.icons.search} style={{ width: 16, height: 16, marginLeft: 12 }} />
                    </View>
                    <TextInput style={{
                        borderRadius: 4, paddingRight: 12, paddingLeft: 36, fontFamily: fontFamily.semi_bold, height: 40,
                    }} placeholder='SĐT người đi'
                        keyboardType={'phone-pad'}
                        value={data.phone}
                        onChangeText={phone => {
                            setData({ ...data, phone })
                        }} />
                </View>
                {
                    user_login?.is_admin ? <>
                        <View style={{ padding: 4 }} />
                        <TouchableOpacity style={{ flex: 1, marginTop: 8, backgroundColor: 'white', borderRadius: 4 }} onPress={() => setModalFilter(true)}>
                            <View style={{
                                borderRadius: 4, fontFamily: fontFamily.semi_bold, height: 40, flexDirection: 'row',
                                justifyContent: 'center', alignItems: 'center', paddingHorizontal: 16
                            }}>
                                <Text style={{ flex: 1, opacity: data.agency_use_id ? 1 : 0.5 }}>
                                    {data.agency_use_id ? info?.accounts?.find(element => element.id === data.agency_use_id).name : 'Lọc người bán'}
                                </Text>
                                <Image source={images.icons.back} style={{ width: 16, height: 16, transform: [{ rotate: '-90deg' }], marginBottom: 2 }} />
                            </View>
                        </TouchableOpacity>
                    </> : null
                }

            </View>

            <TouchableOpacity style={{
                height: 45, backgroundColor: colors.primary, marginTop: 8,
                justifyContent: 'center', alignItems: 'center', borderRadius: 6
            }} onPress={() => {
                let filter = {
                    start_date: moment(data.start_date, 'DD/MM/YYYY').format('YYYY-MM-DD'),
                    end_date: moment(data.end_date, 'DD/MM/YYYY').format('YYYY-MM-DD'),
                    phone: data.phone,
                    agency_use_id: data.agency_use_id,
                }
                callback(filter)
            }}>
                <Text style={{ fontSize: 16, fontFamily: fontFamily.semi_bold }}>Tìm kiếm</Text>
            </TouchableOpacity>
            <View style={{ height: 1, backgroundColor: 'rgba(202, 203, 208, 0.5)', marginTop: 12, marginBottom: 8 }} />

            <Modal
                visible={modal_filter}
                style={{ zIndex: 1000 }}
                onTouchOutside={() => setModalFilter(false)}
            >
                <View style={{ backgroundColor: 'white', borderRadius: 8, width: 300 }}>
                    <Text style={{ fontSize: 16, fontFamily: fontFamily.bold, textAlign: 'center', paddingVertical: 8 }}>Chọn nhân viên bán</Text>
                    <View style={{ width: '100%', height: 1, backgroundColor: '#CACBD0' }} />
                    <View style={{ paddingVertical: 6, paddingHorizontal: 16 }}>
                        <TouchableOpacity onPress={() => {
                            setModalFilter(false)
                            setData({ ...data, agency_use_id: '' })
                        }}>
                            <View style={{
                                flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
                                backgroundColor: 'white', paddingVertical: 12, borderBottomColor: 'rgba(202, 203, 208, 0.5)',
                                borderBottomWidth: 1,
                            }}>
                                <View style={{ flex: 1 }}>
                                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                        <Image source={images.icons.tab4_} style={{ width: 16, height: 16, tintColor: '#58595B' }} />
                                        <Text style={{ color: '#58595B', fontSize: 16, marginLeft: 6 }}>Tất cả</Text>
                                    </View>
                                </View>
                            </View>
                        </TouchableOpacity>
                        {
                            info.accounts?.map((item, idx) => {
                                return <TouchableOpacity key={idx} onPress={() => {
                                    setModalFilter(false)
                                    setData({ ...data, agency_use_id: item.id })
                                }}>
                                    <View style={{
                                        flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
                                        backgroundColor: 'white', paddingVertical: 12, borderBottomColor: 'rgba(202, 203, 208, 0.5)',
                                        borderBottomWidth: idx === info.accounts?.length - 1 ? 0 : 1,
                                    }}>
                                        <View style={{ flex: 1 }}>
                                            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                                <Image source={images.icons.tab4_} style={{ width: 16, height: 16, tintColor: '#58595B' }} />
                                                <Text style={{ color: '#58595B', fontSize: 16, marginLeft: 6 }}>{item.name}</Text>
                                            </View>
                                        </View>
                                    </View>
                                </TouchableOpacity>
                            })
                        }
                    </View>
                </View>
            </Modal>
        </>
    );
}