
import * as React from 'react'
import { Text, View, ScrollView, TouchableOpacity, Image, ActivityIndicator } from 'react-native'
import { useTheme } from '@react-navigation/native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import images from '../../assets'
import axios from 'axios'
import { showMessage } from "react-native-flash-message"
import moment from 'moment'
import { Modal } from 'react-native-modals'

export default React.memo(function TicketInfo({ navigation, route }) {
    const { colors, fontFamily } = useTheme()
    const insets = useSafeAreaInsets()
    const [progress, setProgress] = React.useState(false)
    const [modal_cancel, setModalCancel] = React.useState(false)
    const [modal_payment, setModalPayment] = React.useState(false)
    const [data, setData] = React.useState()
    const reason = React.useRef(null)

    const { ticket_code, booking_code, trip_id } = route.params

    React.useEffect(() => {
        axios.get(`tickets/${ticket_code}`)
            .then(res => {
                console.log(res)
                res.data.data.trip.id = trip_id
                if (res.status === 200) setData({ ...res.data.data })
            })
            .catch(err => console.log(err.response))
    }, [])

    const cancelBooking = () => {
        setModalCancel(false)
        setProgress(true)
        axios.post('cancel-seat', {
            order_code: booking_code,
            seat_code: ticket_code,
        }).then(res => {
            console.log(res)
            navigation.navigate({
                name: 'Seats',
                params: { reload: moment().unix() },
                merge: true,
            })
            showMessage({
                message: "Thông báo",
                description: "Huỷ vé thành công!",
                type: "info",
            })
        }).catch(err => {
            console.log(err.response)
            if (err.response.status === 400)
                showMessage({
                    message: "Thông báo",
                    description: err.response.data.message,
                    type: "warning",
                })
        }).finally(() => setProgress(false))
    }

    const updatePayment = () => {
        setModalPayment(false)
        setProgress(true)
        axios.post('bookings/update-payment', {
            "order_code": booking_code,
            "ticket_code": ticket_code,
        }).then(res => {
            console.log(res)
            navigation.navigate({
                name: 'Seats',
                params: { reload: moment().unix() },
                merge: true,
            })
            showMessage({
                message: "Thông báo",
                description: "Cập nhật thanh toán thành công!",
                type: "info",
            })
        }).catch(err => {
            console.log(err.response)
        }).finally(() => setProgress(false))
    }

    return (
        <View style={{ flex: 1, position: 'relative', backgroundColor: 'white' }}>
            <View style={{ backgroundColor: colors.primary, paddingTop: insets.top ? insets.top : 0 }}>
                <View style={{
                    flexDirection: 'row', justifyContent: 'center', alignItems: 'center', height: 48,
                    position: 'relative',
                }}>
                    <Text style={{ fontFamily: 'Nunito_700Bold', fontSize: 18 }}>Thông tin vé</Text>
                    <View style={{ position: 'absolute', height: '100%', left: 0, top: 0 }}>
                        <TouchableOpacity onPress={() => {
                            if (navigation.canGoBack())
                                navigation.pop()
                            else window.history.back()
                        }}
                            style={{ height: '100%', justifyContent: 'center', alignItems: 'center', paddingLeft: 16 }}>
                            <Image source={images.icons.back} style={{ width: 16, height: 16 }} />
                        </TouchableOpacity>
                    </View>
                </View>
            </View>

            <ScrollView showsVerticalScrollIndicator={false}>
                {
                    data ? <View style={{ padding: 16 }}>
                        <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
                            <Text style={{ color: '#514F4F', flex: 4, fontFamily: 'Nunito_400Regular' }}>Hành khách</Text>
                            <Text style={{ fontFamily: fontFamily.semi_bold, flex: 5 }}>{data.customer?.name}</Text>
                        </View>
                        <View style={{ flexDirection: 'row', justifyContent: 'center', marginTop: 8 }}>
                            <Text style={{ color: '#514F4F', flex: 4, fontFamily: 'Nunito_400Regular' }}>Số điện thoại</Text>
                            <Text style={{ fontFamily: fontFamily.semi_bold, flex: 5 }}>{data.customer?.phone}</Text>
                        </View>
                        {
                            data.customer?.email ? <View style={{ flexDirection: 'row', justifyContent: 'center', marginTop: 8 }}>
                                <Text style={{ color: '#514F4F', flex: 4, fontFamily: 'Nunito_400Regular' }}>Email</Text>
                                <Text style={{ fontFamily: fontFamily.semi_bold, flex: 5 }}>{data.customer?.email}</Text>
                            </View> : null
                        }
                        {
                            data.note ? <View style={{ flexDirection: 'row', justifyContent: 'center', marginTop: 8 }}>
                                <Text style={{ color: '#514F4F', flex: 4, fontFamily: 'Nunito_400Regular' }}>Ghi chú</Text>
                                <Text style={{ fontFamily: fontFamily.semi_bold, flex: 5 }}>{data.note}</Text>
                            </View> : null
                        }
                        <View style={{ width: '100%', backgroundColor: '#E9E9E9', height: 1, marginVertical: 8 }} />
                        <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
                            <Text style={{ color: '#514F4F', flex: 4, fontFamily: 'Nunito_400Regular' }}>Mã vé</Text>
                            <Text style={{ color: '#1F76E5', fontFamily: fontFamily.bold, fontSize: 16, flex: 5 }}>{data.code}</Text>
                        </View>
                        <View style={{ flexDirection: 'row', justifyContent: 'center', marginTop: 8 }}>
                            <Text style={{ color: '#514F4F', flex: 4, fontFamily: 'Nunito_400Regular' }}>Ngày khởi hành</Text>
                            <Text style={{ fontFamily: fontFamily.semi_bold, flex: 5 }}>{moment(data.trip?.time, 'DD-MM-YYYY HH:mm').format('HH:mm DD/MM/YYYY')}</Text>
                        </View>
                        <View style={{ flexDirection: 'row', justifyContent: 'center', marginTop: 8 }}>
                            <Text style={{ color: '#514F4F', flex: 4, fontFamily: 'Nunito_400Regular' }}>Điểm lên xe</Text>
                            <Text style={{ fontFamily: fontFamily.semi_bold, flex: 5 }}>
                                {data.pick_up?.address}
                            </Text>
                        </View>
                        <View style={{ flexDirection: 'row', justifyContent: 'center', marginTop: 8 }}>
                            <Text style={{ color: '#514F4F', flex: 4, fontFamily: 'Nunito_400Regular' }}>Điểm xuống xe</Text>
                            <Text style={{ fontFamily: fontFamily.semi_bold, flex: 5 }}>
                                {data.drop_off?.address}
                            </Text>
                        </View>

                        <View style={{ flexDirection: 'row', justifyContent: 'center', marginTop: 8 }}>
                            <Text style={{ color: '#514F4F', flex: 4, fontFamily: 'Nunito_400Regular' }}>Tên tuyến</Text>
                            <Text style={{ fontFamily: fontFamily.semi_bold, flex: 5 }}>{data.trip?.node?.route?.name}</Text>
                        </View>
                        <View style={{ flexDirection: 'row', justifyContent: 'center', marginTop: 8 }}>
                            <Text style={{ color: '#514F4F', flex: 4, fontFamily: 'Nunito_400Regular' }}>Hạng xe</Text>
                            <Text style={{ fontFamily: fontFamily.semi_bold, flex: 5 }}>{data.trip?.product?.name}</Text>
                        </View>
                        <View style={{ flexDirection: 'row', justifyContent: 'center', marginTop: 8 }}>
                            <Text style={{ color: '#514F4F', flex: 4, fontFamily: 'Nunito_400Regular' }}>Số ghế/giường</Text>
                            <Text style={{ fontFamily: fontFamily.semi_bold, flex: 5 }}>{data?.name}</Text>
                        </View>
                        <View style={{ width: '100%', backgroundColor: '#E9E9E9', height: 1, marginVertical: 8 }} />
                        <View style={{ flexDirection: 'row' }}>
                            <Text style={{ fontSize: 16, fontFamily: fontFamily.bold, flex: 1 }}>Tổng tiền</Text>
                            <View style={{ flexDirection: 'column', alignItems: 'flex-end' }}>
                                <Text style={{ fontSize: 20, fontFamily: fontFamily.bold, color: data?.is_payment === 1 ? '#1F76E5' : colors.text }}>{
                                    (Intl.NumberFormat('vi-VN').format(data?.price))
                                }đ</Text>
                                <Text style={{ fontSize: 16, fontFamily: fontFamily.regular, color: data?.is_payment === 1 ? '#1F76E5' : colors.text }}>{data.payment?.type_name}</Text>
                            </View>
                        </View>
                    </View> : <View style={{ alignItems: 'center', justifyContent: 'center', marginTop: 16 }}>
                        <ActivityIndicator color={colors.primary} />
                    </View>
                }
            </ScrollView>

            {
                data ?
                    <View style={{
                        paddingHorizontal: 16, paddingBottom: 12 + insets.bottom
                    }}>
                        <View style={{ flexDirection: 'row', marginTop: 8 }}>
                            <TouchableOpacity style={{
                                height: 45, borderColor: '#3E77E9', borderWidth: 1,
                                justifyContent: 'center', alignItems: 'center', borderRadius: 6, flex: 1
                            }} onPress={() => navigation.navigate('EditInfo', { booking: data })}>
                                <Text style={{ fontSize: 16, fontFamily: fontFamily.semi_bold }}>Chỉnh sửa</Text>
                            </TouchableOpacity>
                            <View style={{ paddingHorizontal: 5 }} />
                            <TouchableOpacity style={{
                                height: 45, borderColor: '#430CFF', borderWidth: 1,
                                justifyContent: 'center', alignItems: 'center', borderRadius: 6, flex: 1
                            }} onPress={() => {
                                navigation.navigate({
                                    name: 'Seats',
                                    params: { action: 'move_ticket' },
                                    merge: true,
                                })
                            }}>
                                <Text style={{ fontSize: 16, fontFamily: fontFamily.semi_bold }}>Chuyển chỗ</Text>
                            </TouchableOpacity>
                        </View>
                        <View style={{ flexDirection: 'row', marginTop: 8 }}>
                            <TouchableOpacity style={{
                                height: 45, borderColor: '#208B05', borderWidth: 1,
                                justifyContent: 'center', alignItems: 'center', borderRadius: 6, flex: 1
                            }} onPress={() => {
                                navigation.navigate({
                                    name: 'Seats',
                                    params: { action: 'add_ticket' },
                                    merge: true,
                                })
                            }}>
                                <Text style={{ fontSize: 16, fontFamily: fontFamily.semi_bold }}>Thêm vé</Text>
                            </TouchableOpacity>
                            <View style={{ paddingHorizontal: 5 }} />
                            <TouchableOpacity style={{
                                height: 45, borderColor: '#CF1820', borderWidth: 1,
                                justifyContent: 'center', alignItems: 'center', borderRadius: 6, flex: 1
                            }} onPress={() => {
                                setProgress(true)
                                axios.get(`check-ticket-cancel-fine?order_code=${booking_code}&ticket_code=${ticket_code}`)
                                    .then(res => {
                                        console.log(res)
                                        if (res.status === 200) {
                                            reason.current = res.data?.data?.amount
                                            setProgress(false)
                                            setModalCancel(true)
                                        } else showMessage({
                                            message: "Thông báo",
                                            description: 'Bạn không thể huỷ vé này!',
                                            type: "warning",
                                        })
                                    })
                                    .catch(err => {
                                        console.log(err.response)
                                        if (err.response.status === 400)
                                            showMessage({
                                                message: "Thông báo",
                                                description: err.response.data.message,
                                                type: "warning",
                                            })
                                    })
                                    .finally(() => setProgress(false))
                            }}>
                                <Text style={{ fontSize: 16, fontFamily: fontFamily.semi_bold }}>Huỷ vé</Text>
                            </TouchableOpacity>
                        </View>
                        {
                            data?.is_payment === 2 ? <View style={{ flexDirection: 'row', marginTop: 8 }}>
                                <TouchableOpacity style={{
                                    height: 45, backgroundColor: colors.primary,
                                    justifyContent: 'center', alignItems: 'center', borderRadius: 6, flex: 1
                                }} onPress={() => setModalPayment(true)}>
                                    <Text style={{ fontSize: 16, fontFamily: fontFamily.semi_bold }}>Xác nhận thanh toán</Text>
                                </TouchableOpacity>
                            </View> : null
                        }
                    </View> : null
            }

            {
                progress ? <View style={{
                    top: 0, left: 0, width: '100%', height: '100%',
                    justifyContent: "center", alignItems: "center", position: 'absolute', backgroundColor: '#FFFFFF80'
                }}>
                    <ActivityIndicator color={colors.primary} />
                </View> : null
            }

            <Modal
                visible={modal_cancel}
                style={{ zIndex: 1000 }}
                onTouchOutside={() => setModalCancel(false)}
            >
                <View style={{ backgroundColor: 'white', borderRadius: 8 }}>
                    <View style={{ paddingHorizontal: 48, paddingVertical: 16 }}>
                        <Text style={{ fontSize: 16, fontFamily: fontFamily.bold, textAlign: 'center' }}>Xác nhận huỷ</Text>
                        <Text style={{ textAlign: 'center', marginTop: 8 }}>Bạn có muốn huỷ ghế này?</Text>
                        {
                            reason.current ? <Text style={{ textAlign: 'center', marginTop: 8 }}>Phí huỷ: <Text style={{ color: 'red', fontFamily: fontFamily.bold }}>
                                {Intl.NumberFormat('vi-VN').format(reason.current)}đ
                            </Text></Text> : null
                        }
                    </View>
                    <View style={{ flexDirection: 'row', borderTopColor: 'rgba(202, 203, 208, 0.5)', borderTopWidth: 1, height: 40 }}>
                        <TouchableOpacity style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}
                            onPress={cancelBooking}>
                            <Text style={{ fontSize: 16 }}>Đồng ý</Text>
                        </TouchableOpacity>
                        <View style={{ height: '100%', backgroundColor: 'rgba(202, 203, 208, 0.5)', width: 1 }} />
                        <TouchableOpacity style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}
                            onPress={() => setModalCancel(false)}>
                            <Text style={{ fontSize: 16, fontFamily: fontFamily.bold }}>Huỷ</Text>
                        </TouchableOpacity>
                    </View>
                </View>
            </Modal>

            <Modal
                visible={modal_payment}
                style={{ zIndex: 1000 }}
                onTouchOutside={() => setModalPayment(false)}
            >
                <View style={{ backgroundColor: 'white', borderRadius: 8 }}>
                    <View style={{ paddingHorizontal: 24, paddingVertical: 16 }}>
                        <Text style={{ fontSize: 16, fontFamily: fontFamily.bold, textAlign: 'center' }}>Xác nhận thanh toán</Text>
                        <Text style={{ textAlign: 'center', marginTop: 8 }}>Đại lý đã thu tiền cho ghế này?</Text>
                    </View>
                    <View style={{ flexDirection: 'row', borderTopColor: 'rgba(202, 203, 208, 0.5)', borderTopWidth: 1, height: 40 }}>
                        <TouchableOpacity style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}
                            onPress={updatePayment}>
                            <Text style={{ fontSize: 16, fontFamily: fontFamily.bold }}>Đã thu</Text>
                        </TouchableOpacity>
                        <View style={{ height: '100%', backgroundColor: 'rgba(202, 203, 208, 0.5)', width: 1 }} />
                        <TouchableOpacity style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}
                            onPress={() => setModalPayment(false)}>
                            <Text style={{ fontSize: 16 }}>Huỷ</Text>
                        </TouchableOpacity>
                    </View>
                </View>
            </Modal>
        </View>
    )
})
