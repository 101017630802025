
import * as React from 'react'
import { Image, View, Text, ScrollView, TextInput, TouchableOpacity, KeyboardAvoidingView, ActivityIndicator, Platform } from 'react-native'
import { useTheme } from '@react-navigation/native'
import images from '../../assets'
import { BackHandler } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import axios from 'axios'
import { showMessage } from 'react-native-flash-message'
import { useDispatch } from 'react-redux'
import { setUserLogin } from "../redux/ActionCreators"

export default React.memo(function Main({ navigation, route }) {
    const { colors, fontFamily } = useTheme()
    const insets = useSafeAreaInsets()
    const ref_name = React.useRef(null)
    const ref_pass = React.useRef(null)
    const [data, setData] = React.useState({})
    const [progress, setProgress] = React.useState(false)
    const dispatch = useDispatch()

    React.useEffect(() => {
        const backAction = () => {
            BackHandler.exitApp()
            return true;
        };
        const backHandler = BackHandler.addEventListener('hardwareBackPress', backAction)
        return () => backHandler.remove()
    }, [])

    const login = () => {
        console.log(data)
        if (data.name && data.pass) {
            setProgress(true)
            axios.post('login', {
                "email": data.name,
                "password": data.pass
            }).then(res => {
                console.log(res)
                axios.defaults.headers = {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': res.data.data.token_type + ' ' + res.data.data.access_token
                }
                dispatch(setUserLogin(res.data.data))
                navigation.reset({
                    index: 0,
                    routes: [{ name: 'Main' }],
                });
            }).catch(err => {
                console.log(err.response)
                showMessage({
                    message: "Thông báo",
                    description: "Sai tên đăng nhập hoặc mật khẩu!",
                    type: "warning",
                })
            }).finally(() => setProgress(false))
        }
    }

    return (
        <View style={{ flex: 1, backgroundColor: 'white' }}>
            <KeyboardAvoidingView style={{ flex: 1 }} behavior={Platform.OS === 'ios' ? "padding" : "height"} enabled>
                <ScrollView keyboardShouldPersistTaps='handled'>
                    <View style={{ flexDirection: 'row', marginTop: insets.top, position: 'relative' }}>
                        <View style={{ paddingHorizontal: 16, marginTop: 40, flex: 1 }}>
                            <Image source={images.logo} style={{ width: 100, height: 20, tintColor: colors.text }} />
                            <Text style={{ fontSize: 24, fontFamily: fontFamily.bold }}>Đại lý</Text>
                        </View>
                        <Image source={images.car_hv} style={{ width: 185, height: 210 }} />
                    </View>
                    <View style={{ paddingHorizontal: 16 }}>
                        <Text style={{ fontFamily: fontFamily.regular }}>Chào bạn!</Text>
                        <Text style={{ fontSize: 30, fontFamily: fontFamily.bold }}>Vui lòng đăng nhập</Text>

                        <Text style={{ fontFamily: fontFamily.semi_bold, fontSize: 16, marginTop: 12 }}>Tên đăng nhập</Text>
                        <TextInput
                            ref={ref_name} returnKeyType='next' autoCorrect={false} autoCapitalize='none'
                            onChangeText={name => setData({ ...data, name })}
                            style={{
                                height: 45, backgroundColor: '#F0F2F5', borderRadius: 4, marginTop: 8,
                                paddingHorizontal: 16, fontSize: 16, fontFamily: fontFamily.regular
                            }} onSubmitEditing={() => ref_pass.current.focus()} />

                        <Text style={{ fontFamily: fontFamily.semi_bold, fontSize: 16, marginTop: 12 }}>Mật khẩu</Text>
                        <TextInput
                            ref={ref_pass} returnKeyType='done' autoCorrect={false} autoCapitalize='none'
                            secureTextEntry={true}
                            onChangeText={pass => setData({ ...data, pass })}
                            style={{
                                height: 45, backgroundColor: '#F0F2F5', borderRadius: 4, marginTop: 8,
                                paddingHorizontal: 16, fontSize: 16, fontFamily: fontFamily.regular
                            }} onSubmitEditing={login} />

                        {
                            progress ? <View style={{ height: 50, marginTop: 24, marginBottom: 24, justifyContent: 'center', alignItems: 'center' }}>
                                <ActivityIndicator color={colors.primary} />
                            </View> : <TouchableOpacity style={{
                                height: 50, backgroundColor: colors.primary, marginTop: 24, marginBottom: 24,
                                justifyContent: 'center', alignItems: 'center', borderRadius: 6
                            }} onPress={login}>
                                <Text style={{ fontSize: 16, fontFamily: fontFamily.semi_bold }}>Đăng nhập</Text>
                            </TouchableOpacity>
                        }
                    </View>
                </ScrollView>
            </KeyboardAvoidingView>
        </View>
    )
})
