
import * as React from 'react'
import { Text, View, ScrollView, TouchableOpacity, Image, TextInput, FlatList, SectionList, Platform } from 'react-native'
import { CalendarList, Calendar } from 'react-native-calendars'
import moment from 'moment'
import { useTheme } from '@react-navigation/native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import images from '../../assets'
import 'moment-lunar'
import _ from 'lodash'

export default function ModalCalendar({ navigation, route }) {
    const { colors, fontFamily } = useTheme()
    const insets = useSafeAreaInsets()
    const [list_search, setListSearch] = React.useState(JSON.parse(JSON.stringify(list_all)))
    const [text, setText] = React.useState(route.params.search ? route.params.search : '')
    const flatListRef = React.useRef(null)
    const refInput = React.useRef(null)

    React.useEffect(() => {
        try {
            let search = removeVietnameseTones(route.params.search).toLowerCase()
            setListSearch(list_all.filter(item => item.search.toLocaleLowerCase().includes(search)))
        } catch (error) {
        }
        setTimeout(() => {
            refInput.current.focus()
        }, 100)
    }, [])

    const searchText = _.debounce((value) => {
        setText(value)
        let search = removeVietnameseTones(value).toLowerCase()
        setListSearch(list_all.filter(item => item.search.toLocaleLowerCase().includes(search)))
        setTimeout(() => {
            try {
                flatListRef.current.scrollToOffset({ animated: true, offset: 0 })
            } catch (error) {
            }
        }, 250)
    }, 250)

    const removeVietnameseTones = (str) => {
        str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a")
        str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e")
        str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i")
        str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o")
        str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u")
        str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y")
        str = str.replace(/đ/g, "d")
        str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A")
        str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E")
        str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I")
        str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O")
        str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U")
        str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y")
        str = str.replace(/Đ/g, "D")
        str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ""); // ̀ ́ ̃ ̉ ̣  huyền, sắc, ngã, hỏi, nặng
        str = str.replace(/\u02C6|\u0306|\u031B/g, ""); // ˆ ̆ ̛  Â, Ê, Ă, Ơ, Ư
        str = str.replace(/ + /g, " ")
        str = str.trim()
        str = str.replace(/!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g, " ")
        return str
    }

    const renderItem = React.useCallback(({ item }) => (
        <TouchableOpacity style={{
            height: 45, backgroundColor: 'white', flexDirection: 'row', alignItems: 'center', paddingHorizontal: 16,
            borderColor: 'rgba(202, 203, 208, 0.5)', borderBottomWidth: 1
        }} onPress={() => {
            let name = item.district ? item.district : item.name
            let params = {}
            if (route.params?.is_start) params.from = name
            else params.to = name

            if (navigation.canGoBack())
                navigation.navigate({
                    name: 'TabHome',
                    params: params,
                    merge: true,
                })
            else window.history.back()
        }}>
            <View style={{ width: 8, height: 8, borderRadius: 4, borderWidth: 1, borderColor: '#555555', backgroundColor: '#F8F8F8' }} />
            <Text style={{ marginLeft: 8, fontSize: 16, fontFamily: fontFamily.regular }}>{item.name}</Text>
        </TouchableOpacity>
    ))

    const keyExtractor = React.useCallback((item) => `${item.search}`)

    return (
        <View style={{ flex: 1, backgroundColor: '#E5E5E5' }}>
            <View style={{ backgroundColor: 'white' }}>
                <View style={{
                    flexDirection: 'row', justifyContent: 'center', alignItems: 'center', height: 48, paddingHorizontal: 8,
                    marginTop: Platform.OS === 'android' ? insets.top : 0
                }}>
                    <TouchableOpacity style={{ padding: 8 }} onPress={() => {
                        if (Platform.OS === 'web') window.history.back()
                        else navigation.pop()
                    }}>
                        <Image source={images.icons.close} style={{ width: 16, height: 16 }} />
                    </TouchableOpacity>
                    <Text style={{ flex: 1, textAlign: 'center', color: colors.text, fontFamily: 'Nunito_700Bold', fontSize: 18 }}>
                        {route.params?.is_start ? 'Điểm xuất phát' : 'Điểm đến'}
                    </Text>
                    <TouchableOpacity style={{ padding: 8, opacity: 0 }}>
                        <Image source={images.icons.close} style={{ width: 16, height: 16 }} />
                    </TouchableOpacity>
                </View>
            </View>

            <View style={{ marginTop: 6, backgroundColor: 'white', padding: 16, flexDirection: 'column' }}>
                <View style={{ position: 'relative' }}>
                    <TextInput ref={refInput}
                        defaultValue={route?.params?.search}
                        style={{ borderRadius: 4, backgroundColor: '#F0F2F5', height: 45, paddingHorizontal: 16, fontFamily: fontFamily.regular }}
                        placeholder='Chọn Tỉnh - Huyện'
                        onChangeText={searchText} autoFocus />
                    {
                        text ? <TouchableOpacity onPress={() => {
                            setText('')
                            setListSearch(list_all)
                            refInput.current.clear()
                        }} style={{ position: 'absolute', top: 0, right: 0, height: '100%', paddingHorizontal: 16, justifyContent: 'center', alignItems: 'center' }}>
                            <Image source={images.icons.clear} style={{ width: 16, height: 16 }} />
                        </TouchableOpacity> : null
                    }
                </View>
            </View>

            <FlatList
                ListFooterComponent={() => <View style={{ marginBottom: insets.bottom ? insets.bottom : 0 }} />}
                initialNumToRender={20}
                keyboardShouldPersistTaps={'handled'}
                ref={flatListRef}
                data={list_search}
                renderItem={renderItem}
                keyExtractor={keyExtractor} />
        </View>
    )
}

const list_all = [
    { name: `An Giang`, search: `An Giang` },
    { name: `Bà Rịa – Vũng Tàu`, search: `Ba Ria – Vung Tau` },
    { name: `Bạc Liêu`, search: `Bac Lieu` },
    { name: `Bắc Giang`, search: `Bac Giang` },
    { name: `Bắc Kạn`, search: `Bac Kan` },
    { name: `Bắc Ninh`, search: `Bac Ninh` },
    { name: `Bến Tre`, search: `Ben Tre` },
    { name: `Bình Dương`, search: `Binh Duong` },
    { name: `Bình Định`, search: `Binh Dinh` },
    { name: `Bình Phước`, search: `Binh Phuoc` },
    { name: `Bình Thuận`, search: `Binh Thuan` },
    { name: `Cà Mau`, search: `Ca Mau` },
    { name: `Cao Bằng`, search: `Cao Bang` },
    { name: `Cần Thơ`, search: `Can Tho` },
    { name: `Đà Nẵng`, search: `Da Nang` },
    { name: `Đắk Lắk`, search: `Dak Lak` },
    { name: `Đắk Nông`, search: `Dak Nong` },
    { name: `Điện Biên`, search: `Dien Bien` },
    { name: `Đồng Nai`, search: `Dong Nai` },
    { name: `Đồng Tháp`, search: `Dong Thap` },
    { name: `Gia Lai`, search: `Gia Lai` },
    { name: `Hà Giang`, search: `Ha Giang` },
    { name: `Hà Nam`, search: `Ha Nam` },
    { name: `Hà Nội`, search: `Ha Noi` },
    { name: `Hà Tĩnh`, search: `Ha Tinh` },
    { name: `Hải Dương`, search: `Hai Duong` },
    { name: `Hải Phòng`, search: `Hai Phong` },
    { name: `Hậu Giang`, search: `Hau Giang` },
    { name: `Hòa Bình`, search: `Hoa Binh` },
    { name: `Thành phố Hồ Chí Minh`, search: `Thanh pho Ho Chi Minh` },
    { name: `Hưng Yên`, search: `Hung Yen` },
    { name: `Khánh Hòa`, search: `Khanh Hoa` },
    { name: `Kiên Giang`, search: `Kien Giang` },
    { name: `Kon Tum`, search: `Kon Tum` },
    { name: `Lai Châu`, search: `Lai Chau` },
    { name: `Lạng Sơn`, search: `Lang Son` },
    { name: `Lào Cai`, search: `Lao Cai` },
    { name: `Lâm Đồng`, search: `Lam Dong` },
    { name: `Long An`, search: `Long An` },
    { name: `Nam Định`, search: `Nam Dinh` },
    { name: `Nghệ An`, search: `Nghe An` },
    { name: `Ninh Bình`, search: `Ninh Binh` },
    { name: `Ninh Thuận`, search: `Ninh Thuan` },
    { name: `Phú Thọ`, search: `Phu Tho` },
    { name: `Phú Yên`, search: `Phu Yen` },
    { name: `Quảng Bình`, search: `Quang Binh` },
    { name: `Quảng Nam`, search: `Quang Nam` },
    { name: `Quảng Ngãi`, search: `Quang Ngai` },
    { name: `Quảng Ninh`, search: `Quang Ninh` },
    { name: `Quảng Trị`, search: `Quang Tri` },
    { name: `Sóc Trăng`, search: `Soc Trang` },
    { name: `Sơn La`, search: `Son La` },
    { name: `Tây Ninh`, search: `Tay Ninh` },
    { name: `Thái Bình`, search: `Thai Binh` },
    { name: `Thái Nguyên`, search: `Thai Nguyen` },
    { name: `Thanh Hóa`, search: `Thanh Hoa` },
    { name: `Thừa Thiên Huế`, search: `Thua Thien Hue` },
    { name: `Tiền Giang`, search: `Tien Giang` },
    { name: `Trà Vinh`, search: `Tra Vinh` },
    { name: `Tuyên Quang`, search: `Tuyen Quang` },
    { name: `Vĩnh Long`, search: `Vinh Long` },
    { name: `Vĩnh Phúc`, search: `Vinh Phuc` },
    { name: `Yên Bái`, search: `Yen Bai` },
    { district: `A Lưới`, name: `A Lưới - Thừa Thiên Huế`, search: `A Luoi - Thua Thien Hue` },
    { district: `An Biên`, name: `An Biên - Kiên Giang`, search: `An Bien - Kien Giang` },
    { district: `An Dương`, name: `An Dương - Hải Phòng`, search: `An Duong - Hai Phong` },
    { district: `An Khê`, name: `An Khê - Gia Lai`, search: `An Khe - Gia Lai` },
    { district: `An Lão`, name: `An Lão - Hải Phòng`, search: `An Lao - Hai Phong` },
    { district: `An Lão`, name: `An Lão - Bình Định`, search: `An Lao - Binh Dinh` },
    { district: `An Minh`, name: `An Minh - Kiên Giang`, search: `An Minh - Kien Giang` },
    { district: `An Nhơn`, name: `An Nhơn - Bình Định`, search: `An Nhon - Binh Dinh` },
    { district: `An Phú`, name: `An Phú - An Giang`, search: `An Phu - An Giang` },
    { district: `Ân Thi`, name: `Ân Thi - Hưng Yên`, search: `An Thi - Hung Yen` },
    { district: `Anh Sơn`, name: `Anh Sơn - Nghệ An`, search: `Anh Son - Nghe An` },
    { district: `Ayun Pa`, name: `Ayun Pa - Gia Lai`, search: `Ayun Pa - Gia Lai` },
    { district: `Ba Bể`, name: `Ba Bể - Bắc Kạn`, search: `Ba Be - Bac Kan` },
    { district: `Ba Chẽ`, name: `Ba Chẽ - Quảng Ninh`, search: `Ba Che - Quang Ninh` },
    { district: `Ba Đình`, name: `Ba Đình - Hà Nội`, search: `Ba Dinh - Ha Noi` },
    { district: `Ba Đồn`, name: `Ba Đồn - Quảng Bình`, search: `Ba Don - Quang Binh` },
    { district: `Bà Rịa`, name: `Bà Rịa - Bà Rịa – Vũng Tàu`, search: `Ba Ria - Ba Ria – Vung Tau` },
    { district: `Bá Thước`, name: `Bá Thước - Thanh Hóa`, search: `Ba Thuoc - Thanh Hoa` },
    { district: `Ba Tơ`, name: `Ba Tơ - Quảng Ngãi`, search: `Ba To - Quang Ngai` },
    { district: `Ba Tri`, name: `Ba Tri - Bến Tre`, search: `Ba Tri - Ben Tre` },
    { district: `Ba Vì`, name: `Ba Vì - Hà Nội`, search: `Ba Vi - Ha Noi` },
    { district: `Bác Ái`, name: `Bác Ái - Ninh Thuận`, search: `Bac Ai - Ninh Thuan` },
    { district: `Bắc Bình`, name: `Bắc Bình - Bình Thuận`, search: `Bac Binh - Binh Thuan` },
    { district: `Bắc Giang`, name: `Bắc Giang - Bắc Giang`, search: `Bac Giang - Bac Giang` },
    { district: `Bắc Hà`, name: `Bắc Hà - Lào Cai`, search: `Bac Ha - Lao Cai` },
    { district: `Bắc Kạn`, name: `Bắc Kạn - Bắc Kạn`, search: `Bac Kan - Bac Kan` },
    { district: `Bạc Liêu`, name: `Bạc Liêu - Bạc Liêu`, search: `Bac Lieu - Bac Lieu` },
    { district: `Bắc Mê`, name: `Bắc Mê - Hà Giang`, search: `Bac Me - Ha Giang` },
    { district: `Bắc Ninh`, name: `Bắc Ninh - Bắc Ninh`, search: `Bac Ninh - Bac Ninh` },
    { district: `Bắc Quang`, name: `Bắc Quang - Hà Giang`, search: `Bac Quang - Ha Giang` },
    { district: `Bắc Sơn`, name: `Bắc Sơn - Lạng Sơn`, search: `Bac Son - Lang Son` },
    { district: `Bắc Tân Uyên`, name: `Bắc Tân Uyên - Bình Dương`, search: `Bac Tan Uyen - Binh Duong` },
    { district: `Bắc Trà My`, name: `Bắc Trà My - Quảng Nam`, search: `Bac Tra My - Quang Nam` },
    { district: `Bắc Từ Liêm`, name: `Bắc Từ Liêm - Hà Nội`, search: `Bac Tu Liem - Ha Noi` },
    { district: `Bắc Yên`, name: `Bắc Yên - Sơn La`, search: `Bac Yen - Son La` },
    { district: `Bạch Long Vĩ`, name: `Bạch Long Vĩ - Hải Phòng`, search: `Bach Long Vi - Hai Phong` },
    { district: `Bạch Thông`, name: `Bạch Thông - Bắc Kạn`, search: `Bach Thong - Bac Kan` },
    { district: `Bảo Lạc`, name: `Bảo Lạc - Cao Bằng`, search: `Bao Lac - Cao Bang` },
    { district: `Bảo Lâm`, name: `Bảo Lâm - Cao Bằng`, search: `Bao Lam - Cao Bang` },
    { district: `Bảo Lâm`, name: `Bảo Lâm - Lâm Đồng`, search: `Bao Lam - Lam Dong` },
    { district: `Bảo Lộc`, name: `Bảo Lộc - Lâm Đồng`, search: `Bao Loc - Lam Dong` },
    { district: `Bảo Thắng`, name: `Bảo Thắng - Lào Cai`, search: `Bao Thang - Lao Cai` },
    { district: `Bảo Yên`, name: `Bảo Yên - Lào Cai`, search: `Bao Yen - Lao Cai` },
    { district: `Bát Xát`, name: `Bát Xát - Lào Cai`, search: `Bat Xat - Lao Cai` },
    { district: `Bàu Bàng`, name: `Bàu Bàng - Bình Dương`, search: `Bau Bang - Binh Duong` },
    { district: `Bến Cát`, name: `Bến Cát - Bình Dương`, search: `Ben Cat - Binh Duong` },
    { district: `Bến Cầu`, name: `Bến Cầu - Tây Ninh`, search: `Ben Cau - Tay Ninh` },
    { district: `Bến Lức`, name: `Bến Lức - Long An`, search: `Ben Luc - Long An` },
    { district: `Bến Tre`, name: `Bến Tre - Bến Tre`, search: `Ben Tre - Ben Tre` },
    { district: `Biên Hòa`, name: `Biên Hòa - Đồng Nai`, search: `Bien Hoa - Dong Nai` },
    { district: `Bỉm Sơn`, name: `Bỉm Sơn - Thanh Hóa`, search: `Bim Son - Thanh Hoa` },
    { district: `Bình Chánh`, name: `Bình Chánh - Thành phố Hồ Chí Minh`, search: `Binh Chanh - Thanh pho Ho Chi Minh` },
    { district: `Bình Đại`, name: `Bình Đại - Bến Tre`, search: `Binh Dai - Ben Tre` },
    { district: `Bình Gia`, name: `Bình Gia - Lạng Sơn`, search: `Binh Gia - Lang Son` },
    { district: `Bình Giang`, name: `Bình Giang - Hải Dương`, search: `Binh Giang - Hai Duong` },
    { district: `Bình Liêu`, name: `Bình Liêu - Quảng Ninh`, search: `Binh Lieu - Quang Ninh` },
    { district: `Bình Long`, name: `Bình Long - Bình Phước`, search: `Binh Long - Binh Phuoc` },
    { district: `Bình Lục`, name: `Bình Lục - Hà Nam`, search: `Binh Luc - Ha Nam` },
    { district: `Bình Minh`, name: `Bình Minh - Vĩnh Long`, search: `Binh Minh - Vinh Long` },
    { district: `Bình Sơn`, name: `Bình Sơn - Quảng Ngãi`, search: `Binh Son - Quang Ngai` },
    { district: `Bình Tân`, name: `Bình Tân - Thành phố Hồ Chí Minh`, search: `Binh Tan - Thanh pho Ho Chi Minh` },
    { district: `Bình Tân`, name: `Bình Tân - Vĩnh Long`, search: `Binh Tan - Vinh Long` },
    { district: `Bình Thạnh`, name: `Bình Thạnh - Thành phố Hồ Chí Minh`, search: `Binh Thanh - Thanh pho Ho Chi Minh` },
    { district: `Bình Thủy`, name: `Bình Thủy - Cần Thơ`, search: `Binh Thuy - Can Tho` },
    { district: `Bình Xuyên`, name: `Bình Xuyên - Vĩnh Phúc`, search: `Binh Xuyen - Vinh Phuc` },
    { district: `Bố Trạch`, name: `Bố Trạch - Quảng Bình`, search: `Bo Trach - Quang Binh` },
    { district: `Bù Đăng`, name: `Bù Đăng - Bình Phước`, search: `Bu Dang - Binh Phuoc` },
    { district: `Bù Đốp`, name: `Bù Đốp - Bình Phước`, search: `Bu Dop - Binh Phuoc` },
    { district: `Bù Gia Mập`, name: `Bù Gia Mập - Bình Phước`, search: `Bu Gia Map - Binh Phuoc` },
    { district: `Buôn Đôn`, name: `Buôn Đôn - Đắk Lắk`, search: `Buon Don - Dak Lak` },
    { district: `Buôn Hồ`, name: `Buôn Hồ - Đắk Lắk`, search: `Buon Ho - Dak Lak` },
    { district: `Buôn Ma Thuột`, name: `Buôn Ma Thuột - Đắk Lắk`, search: `Buon Ma Thuot - Dak Lak` },
    { district: `Cà Mau`, name: `Cà Mau - Cà Mau`, search: `Ca Mau - Ca Mau` },
    { district: `Cái Bè`, name: `Cái Bè - Tiền Giang`, search: `Cai Be - Tien Giang` },
    { district: `Cai Lậy`, name: `Cai Lậy - Tiền Giang`, search: `Cai Lay - Tien Giang` },
    { district: `Cái Nước`, name: `Cái Nước - Cà Mau`, search: `Cai Nuoc - Ca Mau` },
    { district: `Cái Răng`, name: `Cái Răng - Cần Thơ`, search: `Cai Rang - Can Tho` },
    { district: `Cẩm Giàng`, name: `Cẩm Giàng - Hải Dương`, search: `Cam Giang - Hai Duong` },
    { district: `Cẩm Khê`, name: `Cẩm Khê - Phú Thọ`, search: `Cam Khe - Phu Tho` },
    { district: `Cam Lâm`, name: `Cam Lâm - Khánh Hòa`, search: `Cam Lam - Khanh Hoa` },
    { district: `Cẩm Lệ`, name: `Cẩm Lệ - Đà Nẵng`, search: `Cam Le - Da Nang` },
    { district: `Cam Lộ`, name: `Cam Lộ - Quảng Trị`, search: `Cam Lo - Quang Tri` },
    { district: `Cẩm Mỹ`, name: `Cẩm Mỹ - Đồng Nai`, search: `Cam My - Dong Nai` },
    { district: `Cẩm Phả`, name: `Cẩm Phả - Quảng Ninh`, search: `Cam Pha - Quang Ninh` },
    { district: `Cam Ranh`, name: `Cam Ranh - Khánh Hòa`, search: `Cam Ranh - Khanh Hoa` },
    { district: `Cẩm Thủy`, name: `Cẩm Thủy - Thanh Hóa`, search: `Cam Thuy - Thanh Hoa` },
    { district: `Cẩm Xuyên`, name: `Cẩm Xuyên - Hà Tĩnh`, search: `Cam Xuyen - Ha Tinh` },
    { district: `Cần Đước`, name: `Cần Đước - Long An`, search: `Can Duoc - Long An` },
    { district: `Cần Giờ`, name: `Cần Giờ - Thành phố Hồ Chí Minh`, search: `Can Gio - Thanh pho Ho Chi Minh` },
    { district: `Cần Giuộc`, name: `Cần Giuộc - Long An`, search: `Can Giuoc - Long An` },
    { district: `Can Lộc`, name: `Can Lộc - Hà Tĩnh`, search: `Can Loc - Ha Tinh` },
    { district: `Càng Long`, name: `Càng Long - Trà Vinh`, search: `Cang Long - Tra Vinh` },
    { district: `Cao Bằng`, name: `Cao Bằng - Cao Bằng`, search: `Cao Bang - Cao Bang` },
    { district: `Cao Lãnh`, name: `Cao Lãnh - Đồng Tháp`, search: `Cao Lanh - Dong Thap` },
    { district: `Cao Lộc`, name: `Cao Lộc - Lạng Sơn`, search: `Cao Loc - Lang Son` },
    { district: `Cao Phong`, name: `Cao Phong - Hòa Bình`, search: `Cao Phong - Hoa Binh` },
    { district: `Cát Hải`, name: `Cát Hải - Hải Phòng`, search: `Cat Hai - Hai Phong` },
    { district: `Cát Tiên`, name: `Cát Tiên - Lâm Đồng`, search: `Cat Tien - Lam Dong` },
    { district: `Cầu Giấy`, name: `Cầu Giấy - Hà Nội`, search: `Cau Giay - Ha Noi` },
    { district: `Cầu Kè`, name: `Cầu Kè - Trà Vinh`, search: `Cau Ke - Tra Vinh` },
    { district: `Cầu Ngang`, name: `Cầu Ngang - Trà Vinh`, search: `Cau Ngang - Tra Vinh` },
    { district: `Châu Đốc`, name: `Châu Đốc - An Giang`, search: `Chau Doc - An Giang` },
    { district: `Châu Đức`, name: `Châu Đức - Bà Rịa – Vũng Tàu`, search: `Chau Duc - Ba Ria – Vung Tau` },
    { district: `Châu Phú`, name: `Châu Phú - An Giang`, search: `Chau Phu - An Giang` },
    { district: `Châu Thành`, name: `Châu Thành - An Giang`, search: `Chau Thanh - An Giang` },
    { district: `Châu Thành`, name: `Châu Thành - Bến Tre`, search: `Chau Thanh - Ben Tre` },
    { district: `Châu Thành`, name: `Châu Thành - Đồng Tháp`, search: `Chau Thanh - Dong Thap` },
    { district: `Châu Thành`, name: `Châu Thành - Hậu Giang`, search: `Chau Thanh - Hau Giang` },
    { district: `Châu Thành`, name: `Châu Thành - Kiên Giang`, search: `Chau Thanh - Kien Giang` },
    { district: `Châu Thành`, name: `Châu Thành - Long An`, search: `Chau Thanh - Long An` },
    { district: `Châu Thành`, name: `Châu Thành - Sóc Trăng`, search: `Chau Thanh - Soc Trang` },
    { district: `Châu Thành`, name: `Châu Thành - Tây Ninh`, search: `Chau Thanh - Tay Ninh` },
    { district: `Châu Thành`, name: `Châu Thành - Tiền Giang`, search: `Chau Thanh - Tien Giang` },
    { district: `Châu Thành`, name: `Châu Thành - Trà Vinh`, search: `Chau Thanh - Tra Vinh` },
    { district: `Châu Thành A`, name: `Châu Thành A - Hậu Giang`, search: `Chau Thanh A - Hau Giang` },
    { district: `Chi Lăng`, name: `Chi Lăng - Lạng Sơn`, search: `Chi Lang - Lang Son` },
    { district: `Chí Linh`, name: `Chí Linh - Hải Dương`, search: `Chi Linh - Hai Duong` },
    { district: `Chiêm Hóa`, name: `Chiêm Hóa - Tuyên Quang`, search: `Chiem Hoa - Tuyen Quang` },
    { district: `Chợ Đồn`, name: `Chợ Đồn - Bắc Kạn`, search: `Cho Don - Bac Kan` },
    { district: `Chợ Gạo`, name: `Chợ Gạo - Tiền Giang`, search: `Cho Gao - Tien Giang` },
    { district: `Chợ Lách`, name: `Chợ Lách - Bến Tre`, search: `Cho Lach - Ben Tre` },
    { district: `Chợ Mới`, name: `Chợ Mới - An Giang`, search: `Cho Moi - An Giang` },
    { district: `Chợ Mới`, name: `Chợ Mới - Bắc Kạn`, search: `Cho Moi - Bac Kan` },
    { district: `Chơn Thành`, name: `Chơn Thành - Bình Phước`, search: `Chon Thanh - Binh Phuoc` },
    { district: `Chư Păh`, name: `Chư Păh - Gia Lai`, search: `Chu Pah - Gia Lai` },
    { district: `Chư Prông`, name: `Chư Prông - Gia Lai`, search: `Chu Prong - Gia Lai` },
    { district: `Chư Pưh`, name: `Chư Pưh - Gia Lai`, search: `Chu Puh - Gia Lai` },
    { district: `Chư Sê`, name: `Chư Sê - Gia Lai`, search: `Chu Se - Gia Lai` },
    { district: `Chương Mỹ`, name: `Chương Mỹ - Hà Nội`, search: `Chuong My - Ha Noi` },
    { district: `Cờ Đỏ`, name: `Cờ Đỏ - Cần Thơ`, search: `Co Do - Can Tho` },
    { district: `Cô Tô`, name: `Cô Tô - Quảng Ninh`, search: `Co To - Quang Ninh` },
    { district: `Cồn Cỏ`, name: `Cồn Cỏ - Quảng Trị`, search: `Con Co - Quang Tri` },
    { district: `Con Cuông`, name: `Con Cuông - Nghệ An`, search: `Con Cuong - Nghe An` },
    { district: `Côn Đảo`, name: `Côn Đảo - Bà Rịa – Vũng Tàu`, search: `Con Dao - Ba Ria – Vung Tau` },
    { district: `Củ Chi`, name: `Củ Chi - Thành phố Hồ Chí Minh`, search: `Cu Chi - Thanh pho Ho Chi Minh` },
    { district: `Cư Jút`, name: `Cư Jút - Đắk Nông`, search: `Cu Jut - Dak Nong` },
    { district: `Cư Kuin`, name: `Cư Kuin - Đắk Lắk`, search: `Cu Kuin - Dak Lak` },
    { district: `Cù Lao Dung`, name: `Cù Lao Dung - Sóc Trăng`, search: `Cu Lao Dung - Soc Trang` },
    { district: `Cư M'gar`, name: `Cư M'gar - Đắk Lắk`, search: `Cu M'gar - Dak Lak` },
    { district: `Cửa Lò`, name: `Cửa Lò - Nghệ An`, search: `Cua Lo - Nghe An` },
    { district: `Đà Bắc`, name: `Đà Bắc - Hòa Bình`, search: `Da Bac - Hoa Binh` },
    { district: `Đạ Huoai`, name: `Đạ Huoai - Lâm Đồng`, search: `Da Huoai - Lam Dong` },
    { district: `Đakrông`, name: `Đakrông - Quảng Trị`, search: `Dakrong - Quang Tri` },
    { district: `Đà Lạt`, name: `Đà Lạt - Lâm Đồng`, search: `Da Lat - Lam Dong` },
    { district: `Đạ Tẻh`, name: `Đạ Tẻh - Lâm Đồng`, search: `Da Teh - Lam Dong` },
    { district: `Đại Lộc`, name: `Đại Lộc - Quảng Nam`, search: `Dai Loc - Quang Nam` },
    { district: `Đại Từ`, name: `Đại Từ - Thái Nguyên`, search: `Dai Tu - Thai Nguyen` },
    { district: `Đak Đoa`, name: `Đak Đoa - Gia Lai`, search: `Dak Doa - Gia Lai` },
    { district: `Đăk Glei`, name: `Đăk Glei - Kon Tum`, search: `Dak Glei - Kon Tum` },
    { district: `Đắk Glong`, name: `Đắk Glong - Đắk Nông`, search: `Dak Glong - Dak Nong` },
    { district: `Đăk Hà`, name: `Đăk Hà - Kon Tum`, search: `Dak Ha - Kon Tum` },
    { district: `Đắk Mil`, name: `Đắk Mil - Đắk Nông`, search: `Dak Mil - Dak Nong` },
    { district: `Đak Pơ`, name: `Đak Pơ - Gia Lai`, search: `Dak Po - Gia Lai` },
    { district: `Đắk R'lấp`, name: `Đắk R'lấp - Đắk Nông`, search: `Dak R'lap - Dak Nong` },
    { district: `Đắk Song`, name: `Đắk Song - Đắk Nông`, search: `Dak Song - Dak Nong` },
    { district: `Đăk Tô`, name: `Đăk Tô - Kon Tum`, search: `Dak To - Kon Tum` },
    { district: `Đầm Dơi`, name: `Đầm Dơi - Cà Mau`, search: `Dam Doi - Ca Mau` },
    { district: `Đầm Hà`, name: `Đầm Hà - Quảng Ninh`, search: `Dam Ha - Quang Ninh` },
    { district: `Đam Rông`, name: `Đam Rông - Lâm Đồng`, search: `Dam Rong - Lam Dong` },
    { district: `Đan Phượng`, name: `Đan Phượng - Hà Nội`, search: `Dan Phuong - Ha Noi` },
    { district: `Đất Đỏ`, name: `Đất Đỏ - Bà Rịa – Vũng Tàu`, search: `Dat Do - Ba Ria – Vung Tau` },
    { district: `Dầu Tiếng`, name: `Dầu Tiếng - Bình Dương`, search: `Dau Tieng - Binh Duong` },
    { district: `Dĩ An`, name: `Dĩ An - Bình Dương`, search: `Di An - Binh Duong` },
    { district: `Di Linh`, name: `Di Linh - Lâm Đồng`, search: `Di Linh - Lam Dong` },
    { district: `Điện Bàn`, name: `Điện Bàn - Quảng Nam`, search: `Dien Ban - Quang Nam` },
    { district: `Điện Biên`, name: `Điện Biên - Điện Biên`, search: `Dien Bien - Dien Bien` },
    { district: `Điện Biên Đông`, name: `Điện Biên Đông - Điện Biên`, search: `Dien Bien Dong - Dien Bien` },
    { district: `Điện Biên Phủ`, name: `Điện Biên Phủ - Điện Biên`, search: `Dien Bien Phu - Dien Bien` },
    { district: `Diễn Châu`, name: `Diễn Châu - Nghệ An`, search: `Dien Chau - Nghe An` },
    { district: `Diên Khánh`, name: `Diên Khánh - Khánh Hòa`, search: `Dien Khanh - Khanh Hoa` },
    { district: `Định Hóa`, name: `Định Hóa - Thái Nguyên`, search: `Dinh Hoa - Thai Nguyen` },
    { district: `Đình Lập`, name: `Đình Lập - Lạng Sơn`, search: `Dinh Lap - Lang Son` },
    { district: `Định Quán`, name: `Định Quán - Đồng Nai`, search: `Dinh Quan - Dong Nai` },
    { district: `Đô Lương`, name: `Đô Lương - Nghệ An`, search: `Do Luong - Nghe An` },
    { district: `Đồ Sơn`, name: `Đồ Sơn - Hải Phòng`, search: `Do Son - Hai Phong` },
    { district: `Đoan Hùng`, name: `Đoan Hùng - Phú Thọ`, search: `Doan Hung - Phu Tho` },
    { district: `Đơn Dương`, name: `Đơn Dương - Lâm Đồng`, search: `Don Duong - Lam Dong` },
    { district: `Đông Anh`, name: `Đông Anh - Hà Nội`, search: `Dong Anh - Ha Noi` },
    { district: `Đống Đa`, name: `Đống Đa - Hà Nội`, search: `Dong Da - Ha Noi` },
    { district: `Đông Giang`, name: `Đông Giang - Quảng Nam`, search: `Dong Giang - Quang Nam` },
    { district: `Đông Hà`, name: `Đông Hà - Quảng Trị`, search: `Dong Ha - Quang Tri` },
    { district: `Đông Hải`, name: `Đông Hải - Bạc Liêu`, search: `Dong Hai - Bac Lieu` },
    { district: `Đông Hòa`, name: `Đông Hòa - Phú Yên`, search: `Dong Hoa - Phu Yen` },
    { district: `Đồng Hới`, name: `Đồng Hới - Quảng Bình`, search: `Dong Hoi - Quang Binh` },
    { district: `Đông Hưng`, name: `Đông Hưng - Thái Bình`, search: `Dong Hung - Thai Binh` },
    { district: `Đồng Hỷ`, name: `Đồng Hỷ - Thái Nguyên`, search: `Dong Hy - Thai Nguyen` },
    { district: `Đồng Phú`, name: `Đồng Phú - Bình Phước`, search: `Dong Phu - Binh Phuoc` },
    { district: `Đông Sơn`, name: `Đông Sơn - Thanh Hóa`, search: `Dong Son - Thanh Hoa` },
    { district: `Đông Triều`, name: `Đông Triều - Quảng Ninh`, search: `Dong Trieu - Quang Ninh` },
    { district: `Đồng Văn`, name: `Đồng Văn - Hà Giang`, search: `Dong Van - Ha Giang` },
    { district: `Đồng Xoài`, name: `Đồng Xoài - Bình Phước`, search: `Dong Xoai - Binh Phuoc` },
    { district: `Đồng Xuân`, name: `Đồng Xuân - Phú Yên`, search: `Dong Xuan - Phu Yen` },
    { district: `Đức Cơ`, name: `Đức Cơ - Gia Lai`, search: `Duc Co - Gia Lai` },
    { district: `Đức Hòa`, name: `Đức Hòa - Long An`, search: `Duc Hoa - Long An` },
    { district: `Đức Huệ`, name: `Đức Huệ - Long An`, search: `Duc Hue - Long An` },
    { district: `Đức Linh`, name: `Đức Linh - Bình Thuận`, search: `Duc Linh - Binh Thuan` },
    { district: `Đức Phổ`, name: `Đức Phổ - Quảng Ngãi`, search: `Duc Pho - Quang Ngai` },
    { district: `Đức Thọ`, name: `Đức Thọ - Hà Tĩnh`, search: `Duc Tho - Ha Tinh` },
    { district: `Đức Trọng`, name: `Đức Trọng - Lâm Đồng`, search: `Duc Trong - Lam Dong` },
    { district: `Dương Kinh`, name: `Dương Kinh - Hải Phòng`, search: `Duong Kinh - Hai Phong` },
    { district: `Dương Minh Châu`, name: `Dương Minh Châu - Tây Ninh`, search: `Duong Minh Chau - Tay Ninh` },
    { district: `Duy Tiên`, name: `Duy Tiên - Hà Nam`, search: `Duy Tien - Ha Nam` },
    { district: `Duy Xuyên`, name: `Duy Xuyên - Quảng Nam`, search: `Duy Xuyen - Quang Nam` },
    { district: `Duyên Hải`, name: `Duyên Hải - Trà Vinh`, search: `Duyen Hai - Tra Vinh` },
    { district: `Ea H'leo`, name: `Ea H'leo - Đắk Lắk`, search: `Ea H'leo - Dak Lak` },
    { district: `Ea Kar`, name: `Ea Kar - Đắk Lắk`, search: `Ea Kar - Dak Lak` },
    { district: `Ea Súp`, name: `Ea Súp - Đắk Lắk`, search: `Ea Sup - Dak Lak` },
    { district: `Gia Bình`, name: `Gia Bình - Bắc Ninh`, search: `Gia Binh - Bac Ninh` },
    { district: `Gia Lâm`, name: `Gia Lâm - Hà Nội`, search: `Gia Lam - Ha Noi` },
    { district: `Gia Lộc`, name: `Gia Lộc - Hải Dương`, search: `Gia Loc - Hai Duong` },
    { district: `Gia Nghĩa`, name: `Gia Nghĩa - Đắk Nông`, search: `Gia Nghia - Dak Nong` },
    { district: `Giá Rai`, name: `Giá Rai - Bạc Liêu`, search: `Gia Rai - Bac Lieu` },
    { district: `Gia Viễn`, name: `Gia Viễn - Ninh Bình`, search: `Gia Vien - Ninh Binh` },
    { district: `Giang Thành`, name: `Giang Thành - Kiên Giang`, search: `Giang Thanh - Kien Giang` },
    { district: `Giao Thủy`, name: `Giao Thủy - Nam Định`, search: `Giao Thuy - Nam Dinh` },
    { district: `Gio Linh`, name: `Gio Linh - Quảng Trị`, search: `Gio Linh - Quang Tri` },
    { district: `Giồng Riềng`, name: `Giồng Riềng - Kiên Giang`, search: `Giong Rieng - Kien Giang` },
    { district: `Giồng Trôm`, name: `Giồng Trôm - Bến Tre`, search: `Giong Trom - Ben Tre` },
    { district: `Gò Công`, name: `Gò Công - Tiền Giang`, search: `Go Cong - Tien Giang` },
    { district: `Gò Công Đông`, name: `Gò Công Đông - Tiền Giang`, search: `Go Cong Dong - Tien Giang` },
    { district: `Gò Công Tây`, name: `Gò Công Tây - Tiền Giang`, search: `Go Cong Tay - Tien Giang` },
    { district: `Gò Dầu`, name: `Gò Dầu - Tây Ninh`, search: `Go Dau - Tay Ninh` },
    { district: `Gò Quao`, name: `Gò Quao - Kiên Giang`, search: `Go Quao - Kien Giang` },
    { district: `Gò Vấp`, name: `Gò Vấp - Thành phố Hồ Chí Minh`, search: `Go Vap - Thanh pho Ho Chi Minh` },
    { district: `Hà Đông`, name: `Hà Đông - Hà Nội`, search: `Ha Dong - Ha Noi` },
    { district: `Hà Giang`, name: `Hà Giang - Hà Giang`, search: `Ha Giang - Ha Giang` },
    { district: `Hạ Hòa`, name: `Hạ Hòa - Phú Thọ`, search: `Ha Hoa - Phu Tho` },
    { district: `Hạ Lang`, name: `Hạ Lang - Cao Bằng`, search: `Ha Lang - Cao Bang` },
    { district: `Hạ Long`, name: `Hạ Long - Quảng Ninh`, search: `Ha Long - Quang Ninh` },
    { district: `Hà Quảng`, name: `Hà Quảng - Cao Bằng`, search: `Ha Quang - Cao Bang` },
    { district: `Hà Tiên`, name: `Hà Tiên - Kiên Giang`, search: `Ha Tien - Kien Giang` },
    { district: `Hà Tĩnh`, name: `Hà Tĩnh - Hà Tĩnh`, search: `Ha Tinh - Ha Tinh` },
    { district: `Hà Trung`, name: `Hà Trung - Thanh Hóa`, search: `Ha Trung - Thanh Hoa` },
    { district: `Hải An`, name: `Hải An - Hải Phòng`, search: `Hai An - Hai Phong` },
    { district: `Hai Bà Trưng`, name: `Hai Bà Trưng - Hà Nội`, search: `Hai Ba Trung - Ha Noi` },
    { district: `Hải Châu`, name: `Hải Châu - Đà Nẵng`, search: `Hai Chau - Da Nang` },
    { district: `Hải Dương`, name: `Hải Dương - Hải Dương`, search: `Hai Duong - Hai Duong` },
    { district: `Hải Hà`, name: `Hải Hà - Quảng Ninh`, search: `Hai Ha - Quang Ninh` },
    { district: `Hải Hậu`, name: `Hải Hậu - Nam Định`, search: `Hai Hau - Nam Dinh` },
    { district: `Hải Lăng`, name: `Hải Lăng - Quảng Trị`, search: `Hai Lang - Quang Tri` },
    { district: `Hàm Tân`, name: `Hàm Tân - Bình Thuận`, search: `Ham Tan - Binh Thuan` },
    { district: `Hàm Thuận Bắc`, name: `Hàm Thuận Bắc - Bình Thuận`, search: `Ham Thuan Bac - Binh Thuan` },
    { district: `Hàm Thuận Nam`, name: `Hàm Thuận Nam - Bình Thuận`, search: `Ham Thuan Nam - Binh Thuan` },
    { district: `Hàm Yên`, name: `Hàm Yên - Tuyên Quang`, search: `Ham Yen - Tuyen Quang` },
    { district: `Hậu Lộc`, name: `Hậu Lộc - Thanh Hóa`, search: `Hau Loc - Thanh Hoa` },
    { district: `Hiệp Đức`, name: `Hiệp Đức - Quảng Nam`, search: `Hiep Duc - Quang Nam` },
    { district: `Hiệp Hòa`, name: `Hiệp Hòa - Bắc Giang`, search: `Hiep Hoa - Bac Giang` },
    { district: `Hòa An`, name: `Hòa An - Cao Bằng`, search: `Hoa An - Cao Bang` },
    { district: `Hòa Bình`, name: `Hòa Bình - Hòa Bình`, search: `Hoa Binh - Hoa Binh` },
    { district: `Hòa Bình`, name: `Hòa Bình - Bạc Liêu`, search: `Hoa Binh - Bac Lieu` },
    { district: `Hoa Lư`, name: `Hoa Lư - Ninh Bình`, search: `Hoa Lu - Ninh Binh` },
    { district: `Hòa Thành`, name: `Hòa Thành - Tây Ninh`, search: `Hoa Thanh - Tay Ninh` },
    { district: `Hòa Vang`, name: `Hòa Vang - Đà Nẵng`, search: `Hoa Vang - Da Nang` },
    { district: `Hoài Ân`, name: `Hoài Ân - Bình Định`, search: `Hoai An - Binh Dinh` },
    { district: `Hoài Đức`, name: `Hoài Đức - Hà Nội`, search: `Hoai Duc - Ha Noi` },
    { district: `Hoài Nhơn`, name: `Hoài Nhơn - Bình Định`, search: `Hoai Nhon - Binh Dinh` },
    { district: `Hoàn Kiếm`, name: `Hoàn Kiếm - Hà Nội`, search: `Hoan Kiem - Ha Noi` },
    { district: `Hoằng Hóa`, name: `Hoằng Hóa - Thanh Hóa`, search: `Hoang Hoa - Thanh Hoa` },
    { district: `Hoàng Mai`, name: `Hoàng Mai - Hà Nội`, search: `Hoang Mai - Ha Noi` },
    { district: `Hoàng Mai`, name: `Hoàng Mai - Nghệ An`, search: `Hoang Mai - Nghe An` },
    { district: `Hoàng Sa`, name: `Hoàng Sa - Đà Nẵng`, search: `Hoang Sa - Da Nang` },
    { district: `Hoàng Su Phì`, name: `Hoàng Su Phì - Hà Giang`, search: `Hoang Su Phi - Ha Giang` },
    { district: `Hóc Môn`, name: `Hóc Môn - Thành phố Hồ Chí Minh`, search: `Hoc Mon - Thanh pho Ho Chi Minh` },
    { district: `Hội An`, name: `Hội An - Quảng Nam`, search: `Hoi An - Quang Nam` },
    { district: `Hòn Đất`, name: `Hòn Đất - Kiên Giang`, search: `Hon Dat - Kien Giang` },
    { district: `Hớn Quản`, name: `Hớn Quản - Bình Phước`, search: `Hon Quan - Binh Phuoc` },
    { district: `Hồng Bàng`, name: `Hồng Bàng - Hải Phòng`, search: `Hong Bang - Hai Phong` },
    { district: `Hồng Dân`, name: `Hồng Dân - Bạc Liêu`, search: `Hong Dan - Bac Lieu` },
    { district: `Hồng Lĩnh`, name: `Hồng Lĩnh - Hà Tĩnh`, search: `Hong Linh - Ha Tinh` },
    { district: `Hồng Ngự`, name: `Hồng Ngự - Đồng Tháp`, search: `Hong Ngu - Dong Thap` },
    { district: `Huế`, name: `Huế - Thừa Thiên Huế`, search: `Hue - Thua Thien Hue` },
    { district: `Hưng Hà`, name: `Hưng Hà - Thái Bình`, search: `Hung Ha - Thai Binh` },
    { district: `Hưng Nguyên`, name: `Hưng Nguyên - Nghệ An`, search: `Hung Nguyen - Nghe An` },
    { district: `Hưng Yên`, name: `Hưng Yên - Hưng Yên`, search: `Hung Yen - Hung Yen` },
    { district: `Hướng Hóa`, name: `Hướng Hóa - Quảng Trị`, search: `Huong Hoa - Quang Tri` },
    { district: `Hương Khê`, name: `Hương Khê - Hà Tĩnh`, search: `Huong Khe - Ha Tinh` },
    { district: `Hương Sơn`, name: `Hương Sơn - Hà Tĩnh`, search: `Huong Son - Ha Tinh` },
    { district: `Hương Thủy`, name: `Hương Thủy - Thừa Thiên Huế`, search: `Huong Thuy - Thua Thien Hue` },
    { district: `Hương Trà`, name: `Hương Trà - Thừa Thiên Huế`, search: `Huong Tra - Thua Thien Hue` },
    { district: `Hữu Lũng`, name: `Hữu Lũng - Lạng Sơn`, search: `Huu Lung - Lang Son` },
    { district: `Ia Grai`, name: `Ia Grai - Gia Lai`, search: `Ia Grai - Gia Lai` },
    { district: `Ia H'Drai`, name: `Ia H'Drai - Kon Tum`, search: `Ia H'Drai - Kon Tum` },
    { district: `Ia Pa`, name: `Ia Pa - Gia Lai`, search: `Ia Pa - Gia Lai` },
    { district: `Kbang`, name: `Kbang - Gia Lai`, search: `Kbang - Gia Lai` },
    { district: `Kế Sách`, name: `Kế Sách - Sóc Trăng`, search: `Ke Sach - Soc Trang` },
    { district: `Khánh Sơn`, name: `Khánh Sơn - Khánh Hòa`, search: `Khanh Son - Khanh Hoa` },
    { district: `Khánh Vĩnh`, name: `Khánh Vĩnh - Khánh Hòa`, search: `Khanh Vinh - Khanh Hoa` },
    { district: `Khoái Châu`, name: `Khoái Châu - Hưng Yên`, search: `Khoai Chau - Hung Yen` },
    { district: `Kiến An`, name: `Kiến An - Hải Phòng`, search: `Kien An - Hai Phong` },
    { district: `Kiên Hải`, name: `Kiên Hải - Kiên Giang`, search: `Kien Hai - Kien Giang` },
    { district: `Kiên Lương`, name: `Kiên Lương - Kiên Giang`, search: `Kien Luong - Kien Giang` },
    { district: `Kiến Thụy`, name: `Kiến Thụy - Hải Phòng`, search: `Kien Thuy - Hai Phong` },
    { district: `Kiến Tường`, name: `Kiến Tường - Long An`, search: `Kien Tuong - Long An` },
    { district: `Kiến Xương`, name: `Kiến Xương - Thái Bình`, search: `Kien Xuong - Thai Binh` },
    { district: `Kim Bảng`, name: `Kim Bảng - Hà Nam`, search: `Kim Bang - Ha Nam` },
    { district: `Kim Bôi`, name: `Kim Bôi - Hòa Bình`, search: `Kim Boi - Hoa Binh` },
    { district: `Kim Động`, name: `Kim Động - Hưng Yên`, search: `Kim Dong - Hung Yen` },
    { district: `Kim Sơn`, name: `Kim Sơn - Ninh Bình`, search: `Kim Son - Ninh Binh` },
    { district: `Kim Thành`, name: `Kim Thành - Hải Dương`, search: `Kim Thanh - Hai Duong` },
    { district: `Kinh Môn`, name: `Kinh Môn - Hải Dương`, search: `Kinh Mon - Hai Duong` },
    { district: `Kon Plông`, name: `Kon Plông - Kon Tum`, search: `Kon Plong - Kon Tum` },
    { district: `Kon Rẫy`, name: `Kon Rẫy - Kon Tum`, search: `Kon Ray - Kon Tum` },
    { district: `Kon Tum`, name: `Kon Tum - Kon Tum`, search: `Kon Tum - Kon Tum` },
    { district: `Kông Chro`, name: `Kông Chro - Gia Lai`, search: `Kong Chro - Gia Lai` },
    { district: `Krông Ana`, name: `Krông Ana - Đắk Lắk`, search: `Krong Ana - Dak Lak` },
    { district: `Krông Bông`, name: `Krông Bông - Đắk Lắk`, search: `Krong Bong - Dak Lak` },
    { district: `Krông Búk`, name: `Krông Búk - Đắk Lắk`, search: `Krong Buk - Dak Lak` },
    { district: `Krông Năng`, name: `Krông Năng - Đắk Lắk`, search: `Krong Nang - Dak Lak` },
    { district: `Krông Nô`, name: `Krông Nô - Đắk Nông`, search: `Krong No - Dak Nong` },
    { district: `Krông Pa`, name: `Krông Pa - Gia Lai`, search: `Krong Pa - Gia Lai` },
    { district: `Krông Pắc`, name: `Krông Pắc - Đắk Lắk`, search: `Krong Pac - Dak Lak` },
    { district: `Kỳ Anh`, name: `Kỳ Anh - Hà Tĩnh`, search: `Ky Anh - Ha Tinh` },
    { district: `Kỳ Sơn`, name: `Kỳ Sơn - Nghệ An`, search: `Ky Son - Nghe An` },
    { district: `La Gi`, name: `La Gi - Bình Thuận`, search: `La Gi - Binh Thuan` },
    { district: `Lạc Dương`, name: `Lạc Dương - Lâm Đồng`, search: `Lac Duong - Lam Dong` },
    { district: `Lạc Sơn`, name: `Lạc Sơn - Hòa Bình`, search: `Lac Son - Hoa Binh` },
    { district: `Lạc Thủy`, name: `Lạc Thủy - Hòa Bình`, search: `Lac Thuy - Hoa Binh` },
    { district: `Lai Châu`, name: `Lai Châu - Lai Châu`, search: `Lai Chau - Lai Chau` },
    { district: `Lai Vung`, name: `Lai Vung - Đồng Tháp`, search: `Lai Vung - Dong Thap` },
    { district: `Lắk`, name: `Lắk - Đắk Lắk`, search: `Lak - Dak Lak` },
    { district: `Lâm Bình`, name: `Lâm Bình - Tuyên Quang`, search: `Lam Binh - Tuyen Quang` },
    { district: `Lâm Hà`, name: `Lâm Hà - Lâm Đồng`, search: `Lam Ha - Lam Dong` },
    { district: `Lâm Thao`, name: `Lâm Thao - Phú Thọ`, search: `Lam Thao - Phu Tho` },
    { district: `Lang Chánh`, name: `Lang Chánh - Thanh Hóa`, search: `Lang Chanh - Thanh Hoa` },
    { district: `Lạng Giang`, name: `Lạng Giang - Bắc Giang`, search: `Lang Giang - Bac Giang` },
    { district: `Lạng Sơn`, name: `Lạng Sơn - Lạng Sơn`, search: `Lang Son - Lang Son` },
    { district: `Lào Cai`, name: `Lào Cai - Lào Cai`, search: `Lao Cai - Lao Cai` },
    { district: `Lập Thạch`, name: `Lập Thạch - Vĩnh Phúc`, search: `Lap Thach - Vinh Phuc` },
    { district: `Lấp Vò`, name: `Lấp Vò - Đồng Tháp`, search: `Lap Vo - Dong Thap` },
    { district: `Lê Chân`, name: `Lê Chân - Hải Phòng`, search: `Le Chan - Hai Phong` },
    { district: `Lệ Thủy`, name: `Lệ Thủy - Quảng Bình`, search: `Le Thuy - Quang Binh` },
    { district: `Liên Chiểu`, name: `Liên Chiểu - Đà Nẵng`, search: `Lien Chieu - Da Nang` },
    { district: `Lộc Bình`, name: `Lộc Bình - Lạng Sơn`, search: `Loc Binh - Lang Son` },
    { district: `Lộc Hà`, name: `Lộc Hà - Hà Tĩnh`, search: `Loc Ha - Ha Tinh` },
    { district: `Lộc Ninh`, name: `Lộc Ninh - Bình Phước`, search: `Loc Ninh - Binh Phuoc` },
    { district: `Long Biên`, name: `Long Biên - Hà Nội`, search: `Long Bien - Ha Noi` },
    { district: `Long Điền`, name: `Long Điền - Bà Rịa – Vũng Tàu`, search: `Long Dien - Ba Ria – Vung Tau` },
    { district: `Long Hồ`, name: `Long Hồ - Vĩnh Long`, search: `Long Ho - Vinh Long` },
    { district: `Long Khánh`, name: `Long Khánh - Đồng Nai`, search: `Long Khanh - Dong Nai` },
    { district: `Long Mỹ`, name: `Long Mỹ - Hậu Giang`, search: `Long My - Hau Giang` },
    { district: `Long Phú`, name: `Long Phú - Sóc Trăng`, search: `Long Phu - Soc Trang` },
    { district: `Long Thành`, name: `Long Thành - Đồng Nai`, search: `Long Thanh - Dong Nai` },
    { district: `Long Xuyên`, name: `Long Xuyên - An Giang`, search: `Long Xuyen - An Giang` },
    { district: `Lục Nam`, name: `Lục Nam - Bắc Giang`, search: `Luc Nam - Bac Giang` },
    { district: `Lục Ngạn`, name: `Lục Ngạn - Bắc Giang`, search: `Luc Ngan - Bac Giang` },
    { district: `Lục Yên`, name: `Lục Yên - Yên Bái`, search: `Luc Yen - Yen Bai` },
    { district: `Lương Sơn`, name: `Lương Sơn - Hòa Bình`, search: `Luong Son - Hoa Binh` },
    { district: `Lương Tài`, name: `Lương Tài - Bắc Ninh`, search: `Luong Tai - Bac Ninh` },
    { district: `Lý Nhân`, name: `Lý Nhân - Hà Nam`, search: `Ly Nhan - Ha Nam` },
    { district: `Lý Sơn`, name: `Lý Sơn - Quảng Ngãi`, search: `Ly Son - Quang Ngai` },
    { district: `M'Drắk`, name: `M'Drắk - Đắk Lắk`, search: `M'Drak - Dak Lak` },
    { district: `Mai Châu`, name: `Mai Châu - Hòa Bình`, search: `Mai Chau - Hoa Binh` },
    { district: `Mai Sơn`, name: `Mai Sơn - Sơn La`, search: `Mai Son - Son La` },
    { district: `Mang Thít`, name: `Mang Thít - Vĩnh Long`, search: `Mang Thit - Vinh Long` },
    { district: `Mang Yang`, name: `Mang Yang - Gia Lai`, search: `Mang Yang - Gia Lai` },
    { district: `Mê Linh`, name: `Mê Linh - Hà Nội`, search: `Me Linh - Ha Noi` },
    { district: `Mèo Vạc`, name: `Mèo Vạc - Hà Giang`, search: `Meo Vac - Ha Giang` },
    { district: `Minh Hóa`, name: `Minh Hóa - Quảng Bình`, search: `Minh Hoa - Quang Binh` },
    { district: `Minh Long`, name: `Minh Long - Quảng Ngãi`, search: `Minh Long - Quang Ngai` },
    { district: `Mỏ Cày Bắc`, name: `Mỏ Cày Bắc - Bến Tre`, search: `Mo Cay Bac - Ben Tre` },
    { district: `Mỏ Cày Nam`, name: `Mỏ Cày Nam - Bến Tre`, search: `Mo Cay Nam - Ben Tre` },
    { district: `Mộ Đức`, name: `Mộ Đức - Quảng Ngãi`, search: `Mo Duc - Quang Ngai` },
    { district: `Mộc Châu`, name: `Mộc Châu - Sơn La`, search: `Moc Chau - Son La` },
    { district: `Mộc Hóa`, name: `Mộc Hóa - Long An`, search: `Moc Hoa - Long An` },
    { district: `Móng Cái`, name: `Móng Cái - Quảng Ninh`, search: `Mong Cai - Quang Ninh` },
    { district: `Mù Cang Chải`, name: `Mù Cang Chải - Yên Bái`, search: `Mu Cang Chai - Yen Bai` },
    { district: `Mường Ảng`, name: `Mường Ảng - Điện Biên`, search: `Muong Ang - Dien Bien` },
    { district: `Mường Chà`, name: `Mường Chà - Điện Biên`, search: `Muong Cha - Dien Bien` },
    { district: `Mường Khương`, name: `Mường Khương - Lào Cai`, search: `Muong Khuong - Lao Cai` },
    { district: `Mường La`, name: `Mường La - Sơn La`, search: `Muong La - Son La` },
    { district: `Mường Lát`, name: `Mường Lát - Thanh Hóa`, search: `Muong Lat - Thanh Hoa` },
    { district: `Mường Lay`, name: `Mường Lay - Điện Biên`, search: `Muong Lay - Dien Bien` },
    { district: `Mường Nhé`, name: `Mường Nhé - Điện Biên`, search: `Muong Nhe - Dien Bien` },
    { district: `Mường Tè`, name: `Mường Tè - Lai Châu`, search: `Muong Te - Lai Chau` },
    { district: `Mỹ Đức`, name: `Mỹ Đức - Hà Nội`, search: `My Duc - Ha Noi` },
    { district: `Mỹ Hào`, name: `Mỹ Hào - Hưng Yên`, search: `My Hao - Hung Yen` },
    { district: `Mỹ Lộc`, name: `Mỹ Lộc - Nam Định`, search: `My Loc - Nam Dinh` },
    { district: `Mỹ Tho`, name: `Mỹ Tho - Tiền Giang`, search: `My Tho - Tien Giang` },
    { district: `Mỹ Tú`, name: `Mỹ Tú - Sóc Trăng`, search: `My Tu - Soc Trang` },
    { district: `Mỹ Xuyên`, name: `Mỹ Xuyên - Sóc Trăng`, search: `My Xuyen - Soc Trang` },
    { district: `Na Hang`, name: `Na Hang - Tuyên Quang`, search: `Na Hang - Tuyen Quang` },
    { district: `Na Rì`, name: `Na Rì - Bắc Kạn`, search: `Na Ri - Bac Kan` },
    { district: `Năm Căn`, name: `Năm Căn - Cà Mau`, search: `Nam Can - Ca Mau` },
    { district: `Nam Đàn`, name: `Nam Đàn - Nghệ An`, search: `Nam Dan - Nghe An` },
    { district: `Nam Định`, name: `Nam Định - Nam Định`, search: `Nam Dinh - Nam Dinh` },
    { district: `Nam Đông`, name: `Nam Đông - Thừa Thiên Huế`, search: `Nam Dong - Thua Thien Hue` },
    { district: `Nam Giang`, name: `Nam Giang - Quảng Nam`, search: `Nam Giang - Quang Nam` },
    { district: `Nậm Nhùn`, name: `Nậm Nhùn - Lai Châu`, search: `Nam Nhun - Lai Chau` },
    { district: `Nậm Pồ`, name: `Nậm Pồ - Điện Biên`, search: `Nam Po - Dien Bien` },
    { district: `Nam Sách`, name: `Nam Sách - Hải Dương`, search: `Nam Sach - Hai Duong` },
    { district: `Nam Trà My`, name: `Nam Trà My - Quảng Nam`, search: `Nam Tra My - Quang Nam` },
    { district: `Nam Trực`, name: `Nam Trực - Nam Định`, search: `Nam Truc - Nam Dinh` },
    { district: `Nam Từ Liêm`, name: `Nam Từ Liêm - Hà Nội`, search: `Nam Tu Liem - Ha Noi` },
    { district: `Ngã Bảy`, name: `Ngã Bảy - Hậu Giang`, search: `Nga Bay - Hau Giang` },
    { district: `Ngã Năm`, name: `Ngã Năm - Sóc Trăng`, search: `Nga Nam - Soc Trang` },
    { district: `Nga Sơn`, name: `Nga Sơn - Thanh Hóa`, search: `Nga Son - Thanh Hoa` },
    { district: `Ngân Sơn`, name: `Ngân Sơn - Bắc Kạn`, search: `Ngan Son - Bac Kan` },
    { district: `Nghi Lộc`, name: `Nghi Lộc - Nghệ An`, search: `Nghi Loc - Nghe An` },
    { district: `Nghi Sơn`, name: `Nghi Sơn - Thanh Hóa`, search: `Nghi Son - Thanh Hoa` },
    { district: `Nghi Xuân`, name: `Nghi Xuân - Hà Tĩnh`, search: `Nghi Xuan - Ha Tinh` },
    { district: `Nghĩa Đàn`, name: `Nghĩa Đàn - Nghệ An`, search: `Nghia Dan - Nghe An` },
    { district: `Nghĩa Hành`, name: `Nghĩa Hành - Quảng Ngãi`, search: `Nghia Hanh - Quang Ngai` },
    { district: `Nghĩa Hưng`, name: `Nghĩa Hưng - Nam Định`, search: `Nghia Hung - Nam Dinh` },
    { district: `Nghĩa Lộ`, name: `Nghĩa Lộ - Yên Bái`, search: `Nghia Lo - Yen Bai` },
    { district: `Ngô Quyền`, name: `Ngô Quyền - Hải Phòng`, search: `Ngo Quyen - Hai Phong` },
    { district: `Ngọc Hiển`, name: `Ngọc Hiển - Cà Mau`, search: `Ngoc Hien - Ca Mau` },
    { district: `Ngọc Hồi`, name: `Ngọc Hồi - Kon Tum`, search: `Ngoc Hoi - Kon Tum` },
    { district: `Ngọc Lặc`, name: `Ngọc Lặc - Thanh Hóa`, search: `Ngoc Lac - Thanh Hoa` },
    { district: `Ngũ Hành Sơn`, name: `Ngũ Hành Sơn - Đà Nẵng`, search: `Ngu Hanh Son - Da Nang` },
    { district: `Nguyên Bình`, name: `Nguyên Bình - Cao Bằng`, search: `Nguyen Binh - Cao Bang` },
    { district: `Nhà Bè`, name: `Nhà Bè - Thành phố Hồ Chí Minh`, search: `Nha Be - Thanh pho Ho Chi Minh` },
    { district: `Nha Trang`, name: `Nha Trang - Khánh Hòa`, search: `Nha Trang - Khanh Hoa` },
    { district: `Nho Quan`, name: `Nho Quan - Ninh Bình`, search: `Nho Quan - Ninh Binh` },
    { district: `Nhơn Trạch`, name: `Nhơn Trạch - Đồng Nai`, search: `Nhon Trach - Dong Nai` },
    { district: `Như Thanh`, name: `Như Thanh - Thanh Hóa`, search: `Nhu Thanh - Thanh Hoa` },
    { district: `Như Xuân`, name: `Như Xuân - Thanh Hóa`, search: `Nhu Xuan - Thanh Hoa` },
    { district: `Ninh Bình`, name: `Ninh Bình - Ninh Bình`, search: `Ninh Binh - Ninh Binh` },
    { district: `Ninh Giang`, name: `Ninh Giang - Hải Dương`, search: `Ninh Giang - Hai Duong` },
    { district: `Ninh Hải`, name: `Ninh Hải - Ninh Thuận`, search: `Ninh Hai - Ninh Thuan` },
    { district: `Ninh Hòa`, name: `Ninh Hòa - Khánh Hòa`, search: `Ninh Hoa - Khanh Hoa` },
    { district: `Ninh Kiều`, name: `Ninh Kiều - Cần Thơ`, search: `Ninh Kieu - Can Tho` },
    { district: `Ninh Phước`, name: `Ninh Phước - Ninh Thuận`, search: `Ninh Phuoc - Ninh Thuan` },
    { district: `Ninh Sơn`, name: `Ninh Sơn - Ninh Thuận`, search: `Ninh Son - Ninh Thuan` },
    { district: `Nông Cống`, name: `Nông Cống - Thanh Hóa`, search: `Nong Cong - Thanh Hoa` },
    { district: `Nông Sơn`, name: `Nông Sơn - Quảng Nam`, search: `Nong Son - Quang Nam` },
    { district: `Núi Thành`, name: `Núi Thành - Quảng Nam`, search: `Nui Thanh - Quang Nam` },
    { district: `Ô Môn`, name: `Ô Môn - Cần Thơ`, search: `O Mon - Can Tho` },
    { district: `Pác Nặm`, name: `Pác Nặm - Bắc Kạn`, search: `Pac Nam - Bac Kan` },
    { district: `Phan Rang – Tháp Chàm`, name: `Phan Rang – Tháp Chàm - Ninh Thuận`, search: `Phan Rang – Thap Cham - Ninh Thuan` },
    { district: `Phan Thiết`, name: `Phan Thiết - Bình Thuận`, search: `Phan Thiet - Binh Thuan` },
    { district: `Phổ Yên`, name: `Phổ Yên - Thái Nguyên`, search: `Pho Yen - Thai Nguyen` },
    { district: `Phong Điền`, name: `Phong Điền - Cần Thơ`, search: `Phong Dien - Can Tho` },
    { district: `Phong Điền`, name: `Phong Điền - Thừa Thiên Huế`, search: `Phong Dien - Thua Thien Hue` },
    { district: `Phong Thổ`, name: `Phong Thổ - Lai Châu`, search: `Phong Tho - Lai Chau` },
    { district: `Phú Bình`, name: `Phú Bình - Thái Nguyên`, search: `Phu Binh - Thai Nguyen` },
    { district: `Phù Cát`, name: `Phù Cát - Bình Định`, search: `Phu Cat - Binh Dinh` },
    { district: `Phù Cừ`, name: `Phù Cừ - Hưng Yên`, search: `Phu Cu - Hung Yen` },
    { district: `Phú Giáo`, name: `Phú Giáo - Bình Dương`, search: `Phu Giao - Binh Duong` },
    { district: `Phú Hòa`, name: `Phú Hòa - Phú Yên`, search: `Phu Hoa - Phu Yen` },
    { district: `Phú Lộc`, name: `Phú Lộc - Thừa Thiên Huế`, search: `Phu Loc - Thua Thien Hue` },
    { district: `Phú Lương`, name: `Phú Lương - Thái Nguyên`, search: `Phu Luong - Thai Nguyen` },
    { district: `Phủ Lý`, name: `Phủ Lý - Hà Nam`, search: `Phu Ly - Ha Nam` },
    { district: `Phú Mỹ`, name: `Phú Mỹ - Bà Rịa – Vũng Tàu`, search: `Phu My - Ba Ria – Vung Tau` },
    { district: `Phù Mỹ`, name: `Phù Mỹ - Bình Định`, search: `Phu My - Binh Dinh` },
    { district: `Phú Nhuận`, name: `Phú Nhuận - Thành phố Hồ Chí Minh`, search: `Phu Nhuan - Thanh pho Ho Chi Minh` },
    { district: `Phú Ninh`, name: `Phú Ninh - Quảng Nam`, search: `Phu Ninh - Quang Nam` },
    { district: `Phù Ninh`, name: `Phù Ninh - Phú Thọ`, search: `Phu Ninh - Phu Tho` },
    { district: `Phú Quốc`, name: `Phú Quốc - Kiên Giang`, search: `Phu Quoc - Kien Giang` },
    { district: `Phú Quý`, name: `Phú Quý - Bình Thuận`, search: `Phu Quy - Binh Thuan` },
    { district: `Phú Riềng`, name: `Phú Riềng - Bình Phước`, search: `Phu Rieng - Binh Phuoc` },
    { district: `Phú Tân`, name: `Phú Tân - An Giang`, search: `Phu Tan - An Giang` },
    { district: `Phú Tân`, name: `Phú Tân - Cà Mau`, search: `Phu Tan - Ca Mau` },
    { district: `Phú Thiện`, name: `Phú Thiện - Gia Lai`, search: `Phu Thien - Gia Lai` },
    { district: `Phú Thọ`, name: `Phú Thọ - Phú Thọ`, search: `Phu Tho - Phu Tho` },
    { district: `Phú Vang`, name: `Phú Vang - Thừa Thiên Huế`, search: `Phu Vang - Thua Thien Hue` },
    { district: `Phú Xuyên`, name: `Phú Xuyên - Hà Nội`, search: `Phu Xuyen - Ha Noi` },
    { district: `Phù Yên`, name: `Phù Yên - Sơn La`, search: `Phu Yen - Son La` },
    { district: `Phúc Thọ`, name: `Phúc Thọ - Hà Nội`, search: `Phuc Tho - Ha Noi` },
    { district: `Phúc Yên`, name: `Phúc Yên - Vĩnh Phúc`, search: `Phuc Yen - Vinh Phuc` },
    { district: `Phụng Hiệp`, name: `Phụng Hiệp - Hậu Giang`, search: `Phung Hiep - Hau Giang` },
    { district: `Phước Long`, name: `Phước Long - Bình Phước`, search: `Phuoc Long - Binh Phuoc` },
    { district: `Phước Long`, name: `Phước Long - Bạc Liêu`, search: `Phuoc Long - Bac Lieu` },
    { district: `Phước Sơn`, name: `Phước Sơn - Quảng Nam`, search: `Phuoc Son - Quang Nam` },
    { district: `Pleiku`, name: `Pleiku - Gia Lai`, search: `Pleiku - Gia Lai` },
    { district: `Quận 1`, name: `Quận 1 - Thành phố Hồ Chí Minh`, search: `Quan 1 - Thanh pho Ho Chi Minh` },
    { district: `Quận 3`, name: `Quận 3 - Thành phố Hồ Chí Minh`, search: `Quan 3 - Thanh pho Ho Chi Minh` },
    { district: `Quận 4`, name: `Quận 4 - Thành phố Hồ Chí Minh`, search: `Quan 4 - Thanh pho Ho Chi Minh` },
    { district: `Quận 5`, name: `Quận 5 - Thành phố Hồ Chí Minh`, search: `Quan 5 - Thanh pho Ho Chi Minh` },
    { district: `Quận 6`, name: `Quận 6 - Thành phố Hồ Chí Minh`, search: `Quan 6 - Thanh pho Ho Chi Minh` },
    { district: `Quận 7`, name: `Quận 7 - Thành phố Hồ Chí Minh`, search: `Quan 7 - Thanh pho Ho Chi Minh` },
    { district: `Quận 8`, name: `Quận 8 - Thành phố Hồ Chí Minh`, search: `Quan 8 - Thanh pho Ho Chi Minh` },
    { district: `Quận 10`, name: `Quận 10 - Thành phố Hồ Chí Minh`, search: `Quan 10 - Thanh pho Ho Chi Minh` },
    { district: `Quận 11`, name: `Quận 11 - Thành phố Hồ Chí Minh`, search: `Quan 11 - Thanh pho Ho Chi Minh` },
    { district: `Quận 12`, name: `Quận 12 - Thành phố Hồ Chí Minh`, search: `Quan 12 - Thanh pho Ho Chi Minh` },
    { district: `Quản Bạ`, name: `Quản Bạ - Hà Giang`, search: `Quan Ba - Ha Giang` },
    { district: `Quan Hóa`, name: `Quan Hóa - Thanh Hóa`, search: `Quan Hoa - Thanh Hoa` },
    { district: `Quan Sơn`, name: `Quan Sơn - Thanh Hóa`, search: `Quan Son - Thanh Hoa` },
    { district: `Quang Bình`, name: `Quang Bình - Hà Giang`, search: `Quang Binh - Ha Giang` },
    { district: `Quảng Điền`, name: `Quảng Điền - Thừa Thiên Huế`, search: `Quang Dien - Thua Thien Hue` },
    { district: `Quảng Hòa`, name: `Quảng Hòa - Cao Bằng`, search: `Quang Hoa - Cao Bang` },
    { district: `Quảng Ngãi`, name: `Quảng Ngãi - Quảng Ngãi`, search: `Quang Ngai - Quang Ngai` },
    { district: `Quảng Ninh`, name: `Quảng Ninh - Quảng Bình`, search: `Quang Ninh - Quang Binh` },
    { district: `Quảng Trạch`, name: `Quảng Trạch - Quảng Bình`, search: `Quang Trach - Quang Binh` },
    { district: `Quảng Trị`, name: `Quảng Trị - Quảng Trị`, search: `Quang Tri - Quang Tri` },
    { district: `Quảng Xương`, name: `Quảng Xương - Thanh Hóa`, search: `Quang Xuong - Thanh Hoa` },
    { district: `Quảng Yên`, name: `Quảng Yên - Quảng Ninh`, search: `Quang Yen - Quang Ninh` },
    { district: `Quế Phong`, name: `Quế Phong - Nghệ An`, search: `Que Phong - Nghe An` },
    { district: `Quế Sơn`, name: `Quế Sơn - Quảng Nam`, search: `Que Son - Quang Nam` },
    { district: `Quế Võ`, name: `Quế Võ - Bắc Ninh`, search: `Que Vo - Bac Ninh` },
    { district: `Quốc Oai`, name: `Quốc Oai - Hà Nội`, search: `Quoc Oai - Ha Noi` },
    { district: `Quỳ Châu`, name: `Quỳ Châu - Nghệ An`, search: `Quy Chau - Nghe An` },
    { district: `Quỳ Hợp`, name: `Quỳ Hợp - Nghệ An`, search: `Quy Hop - Nghe An` },
    { district: `Quy Nhơn`, name: `Quy Nhơn - Bình Định`, search: `Quy Nhon - Binh Dinh` },
    { district: `Quỳnh Lưu`, name: `Quỳnh Lưu - Nghệ An`, search: `Quynh Luu - Nghe An` },
    { district: `Quỳnh Nhai`, name: `Quỳnh Nhai - Sơn La`, search: `Quynh Nhai - Son La` },
    { district: `Quỳnh Phụ`, name: `Quỳnh Phụ - Thái Bình`, search: `Quynh Phu - Thai Binh` },
    { district: `Rạch Giá`, name: `Rạch Giá - Kiên Giang`, search: `Rach Gia - Kien Giang` },
    { district: `Sa Đéc`, name: `Sa Đéc - Đồng Tháp`, search: `Sa Dec - Dong Thap` },
    { district: `Sa Pa`, name: `Sa Pa - Lào Cai`, search: `Sa Pa - Lao Cai` },
    { district: `Sa Thầy`, name: `Sa Thầy - Kon Tum`, search: `Sa Thay - Kon Tum` },
    { district: `Sầm Sơn`, name: `Sầm Sơn - Thanh Hóa`, search: `Sam Son - Thanh Hoa` },
    { district: `Si Ma Cai`, name: `Si Ma Cai - Lào Cai`, search: `Si Ma Cai - Lao Cai` },
    { district: `Sìn Hồ`, name: `Sìn Hồ - Lai Châu`, search: `Sin Ho - Lai Chau` },
    { district: `Sóc Sơn`, name: `Sóc Sơn - Hà Nội`, search: `Soc Son - Ha Noi` },
    { district: `Sóc Trăng`, name: `Sóc Trăng - Sóc Trăng`, search: `Soc Trang - Soc Trang` },
    { district: `Sơn Động`, name: `Sơn Động - Bắc Giang`, search: `Son Dong - Bac Giang` },
    { district: `Sơn Dương`, name: `Sơn Dương - Tuyên Quang`, search: `Son Duong - Tuyen Quang` },
    { district: `Sơn Hà`, name: `Sơn Hà - Quảng Ngãi`, search: `Son Ha - Quang Ngai` },
    { district: `Sơn Hòa`, name: `Sơn Hòa - Phú Yên`, search: `Son Hoa - Phu Yen` },
    { district: `Sơn La`, name: `Sơn La - Sơn La`, search: `Son La - Son La` },
    { district: `Sơn Tây`, name: `Sơn Tây - Hà Nội`, search: `Son Tay - Ha Noi` },
    { district: `Sơn Tây`, name: `Sơn Tây - Quảng Ngãi`, search: `Son Tay - Quang Ngai` },
    { district: `Sơn Tịnh`, name: `Sơn Tịnh - Quảng Ngãi`, search: `Son Tinh - Quang Ngai` },
    { district: `Sơn Trà`, name: `Sơn Trà - Đà Nẵng`, search: `Son Tra - Da Nang` },
    { district: `Sông Cầu`, name: `Sông Cầu - Phú Yên`, search: `Song Cau - Phu Yen` },
    { district: `Sông Công`, name: `Sông Công - Thái Nguyên`, search: `Song Cong - Thai Nguyen` },
    { district: `Sông Hinh`, name: `Sông Hinh - Phú Yên`, search: `Song Hinh - Phu Yen` },
    { district: `Sông Lô`, name: `Sông Lô - Vĩnh Phúc`, search: `Song Lo - Vinh Phuc` },
    { district: `Sông Mã`, name: `Sông Mã - Sơn La`, search: `Song Ma - Son La` },
    { district: `Sốp Cộp`, name: `Sốp Cộp - Sơn La`, search: `Sop Cop - Son La` },
    { district: `Tam Bình`, name: `Tam Bình - Vĩnh Long`, search: `Tam Binh - Vinh Long` },
    { district: `Tam Đảo`, name: `Tam Đảo - Vĩnh Phúc`, search: `Tam Dao - Vinh Phuc` },
    { district: `Tam Điệp`, name: `Tam Điệp - Ninh Bình`, search: `Tam Diep - Ninh Binh` },
    { district: `Tam Dương`, name: `Tam Dương - Vĩnh Phúc`, search: `Tam Duong - Vinh Phuc` },
    { district: `Tam Đường`, name: `Tam Đường - Lai Châu`, search: `Tam Duong - Lai Chau` },
    { district: `Tam Kỳ`, name: `Tam Kỳ - Quảng Nam`, search: `Tam Ky - Quang Nam` },
    { district: `Tam Nông`, name: `Tam Nông - Đồng Tháp`, search: `Tam Nong - Dong Thap` },
    { district: `Tam Nông`, name: `Tam Nông - Phú Thọ`, search: `Tam Nong - Phu Tho` },
    { district: `Tân An`, name: `Tân An - Long An`, search: `Tan An - Long An` },
    { district: `Tân Biên`, name: `Tân Biên - Tây Ninh`, search: `Tan Bien - Tay Ninh` },
    { district: `Tân Bình`, name: `Tân Bình - Thành phố Hồ Chí Minh`, search: `Tan Binh - Thanh pho Ho Chi Minh` },
    { district: `Tân Châu`, name: `Tân Châu - An Giang`, search: `Tan Chau - An Giang` },
    { district: `Tân Châu`, name: `Tân Châu - Tây Ninh`, search: `Tan Chau - Tay Ninh` },
    { district: `Tân Hiệp`, name: `Tân Hiệp - Kiên Giang`, search: `Tan Hiep - Kien Giang` },
    { district: `Tân Hồng`, name: `Tân Hồng - Đồng Tháp`, search: `Tan Hong - Dong Thap` },
    { district: `Tân Hưng`, name: `Tân Hưng - Long An`, search: `Tan Hung - Long An` },
    { district: `Tân Kỳ`, name: `Tân Kỳ - Nghệ An`, search: `Tan Ky - Nghe An` },
    { district: `Tân Lạc`, name: `Tân Lạc - Hòa Bình`, search: `Tan Lac - Hoa Binh` },
    { district: `Tân Phú`, name: `Tân Phú - Thành phố Hồ Chí Minh`, search: `Tan Phu - Thanh pho Ho Chi Minh` },
    { district: `Tân Phú`, name: `Tân Phú - Đồng Nai`, search: `Tan Phu - Dong Nai` },
    { district: `Tân Phú Đông`, name: `Tân Phú Đông - Tiền Giang`, search: `Tan Phu Dong - Tien Giang` },
    { district: `Tân Phước`, name: `Tân Phước - Tiền Giang`, search: `Tan Phuoc - Tien Giang` },
    { district: `Tân Sơn`, name: `Tân Sơn - Phú Thọ`, search: `Tan Son - Phu Tho` },
    { district: `Tân Thạnh`, name: `Tân Thạnh - Long An`, search: `Tan Thanh - Long An` },
    { district: `Tân Trụ`, name: `Tân Trụ - Long An`, search: `Tan Tru - Long An` },
    { district: `Tân Uyên`, name: `Tân Uyên - Bình Dương`, search: `Tan Uyen - Binh Duong` },
    { district: `Tân Uyên`, name: `Tân Uyên - Lai Châu`, search: `Tan Uyen - Lai Chau` },
    { district: `Tân Yên`, name: `Tân Yên - Bắc Giang`, search: `Tan Yen - Bac Giang` },
    { district: `Tánh Linh`, name: `Tánh Linh - Bình Thuận`, search: `Tanh Linh - Binh Thuan` },
    { district: `Tây Giang`, name: `Tây Giang - Quảng Nam`, search: `Tay Giang - Quang Nam` },
    { district: `Tây Hồ`, name: `Tây Hồ - Hà Nội`, search: `Tay Ho - Ha Noi` },
    { district: `Tây Hòa`, name: `Tây Hòa - Phú Yên`, search: `Tay Hoa - Phu Yen` },
    { district: `Tây Ninh`, name: `Tây Ninh - Tây Ninh`, search: `Tay Ninh - Tay Ninh` },
    { district: `Tây Sơn`, name: `Tây Sơn - Bình Định`, search: `Tay Son - Binh Dinh` },
    { district: `Thạch An`, name: `Thạch An - Cao Bằng`, search: `Thach An - Cao Bang` },
    { district: `Thạch Hà`, name: `Thạch Hà - Hà Tĩnh`, search: `Thach Ha - Ha Tinh` },
    { district: `Thạch Thành`, name: `Thạch Thành - Thanh Hóa`, search: `Thach Thanh - Thanh Hoa` },
    { district: `Thạch Thất`, name: `Thạch Thất - Hà Nội`, search: `Thach That - Ha Noi` },
    { district: `Thái Bình`, name: `Thái Bình - Thái Bình`, search: `Thai Binh - Thai Binh` },
    { district: `Thái Hòa`, name: `Thái Hòa - Nghệ An`, search: `Thai Hoa - Nghe An` },
    { district: `Thái Nguyên`, name: `Thái Nguyên - Thái Nguyên`, search: `Thai Nguyen - Thai Nguyen` },
    { district: `Thái Thụy`, name: `Thái Thụy - Thái Bình`, search: `Thai Thuy - Thai Binh` },
    { district: `Than Uyên`, name: `Than Uyên - Lai Châu`, search: `Than Uyen - Lai Chau` },
    { district: `Thăng Bình`, name: `Thăng Bình - Quảng Nam`, search: `Thang Binh - Quang Nam` },
    { district: `Thanh Ba`, name: `Thanh Ba - Phú Thọ`, search: `Thanh Ba - Phu Tho` },
    { district: `Thanh Bình`, name: `Thanh Bình - Đồng Tháp`, search: `Thanh Binh - Dong Thap` },
    { district: `Thanh Chương`, name: `Thanh Chương - Nghệ An`, search: `Thanh Chuong - Nghe An` },
    { district: `Thanh Hà`, name: `Thanh Hà - Hải Dương`, search: `Thanh Ha - Hai Duong` },
    { district: `Thanh Hóa`, name: `Thanh Hóa - Thanh Hóa`, search: `Thanh Hoa - Thanh Hoa` },
    { district: `Thạnh Hóa`, name: `Thạnh Hóa - Long An`, search: `Thanh Hoa - Long An` },
    { district: `Thanh Khê`, name: `Thanh Khê - Đà Nẵng`, search: `Thanh Khe - Da Nang` },
    { district: `Thanh Liêm`, name: `Thanh Liêm - Hà Nam`, search: `Thanh Liem - Ha Nam` },
    { district: `Thanh Miện`, name: `Thanh Miện - Hải Dương`, search: `Thanh Mien - Hai Duong` },
    { district: `Thanh Oai`, name: `Thanh Oai - Hà Nội`, search: `Thanh Oai - Ha Noi` },
    { district: `Thạnh Phú`, name: `Thạnh Phú - Bến Tre`, search: `Thanh Phu - Ben Tre` },
    { district: `Thanh Sơn`, name: `Thanh Sơn - Phú Thọ`, search: `Thanh Son - Phu Tho` },
    { district: `Thanh Thủy`, name: `Thanh Thủy - Phú Thọ`, search: `Thanh Thuy - Phu Tho` },
    { district: `Thanh Trì`, name: `Thanh Trì - Hà Nội`, search: `Thanh Tri - Ha Noi` },
    { district: `Thạnh Trị`, name: `Thạnh Trị - Sóc Trăng`, search: `Thanh Tri - Soc Trang` },
    { district: `Thanh Xuân`, name: `Thanh Xuân - Hà Nội`, search: `Thanh Xuan - Ha Noi` },
    { district: `Tháp Mười`, name: `Tháp Mười - Đồng Tháp`, search: `Thap Muoi - Dong Thap` },
    { district: `Thiệu Hóa`, name: `Thiệu Hóa - Thanh Hóa`, search: `Thieu Hoa - Thanh Hoa` },
    { district: `Thọ Xuân`, name: `Thọ Xuân - Thanh Hóa`, search: `Tho Xuan - Thanh Hoa` },
    { district: `Thoại Sơn`, name: `Thoại Sơn - An Giang`, search: `Thoai Son - An Giang` },
    { district: `Thới Bình`, name: `Thới Bình - Cà Mau`, search: `Thoi Binh - Ca Mau` },
    { district: `Thới Lai`, name: `Thới Lai - Cần Thơ`, search: `Thoi Lai - Can Tho` },
    { district: `Thống Nhất`, name: `Thống Nhất - Đồng Nai`, search: `Thong Nhat - Dong Nai` },
    { district: `Thốt Nốt`, name: `Thốt Nốt - Cần Thơ`, search: `Thot Not - Can Tho` },
    { district: `Thủ Dầu Một`, name: `Thủ Dầu Một - Bình Dương`, search: `Thu Dau Mot - Binh Duong` },
    { district: `Thủ Đức`, name: `Thủ Đức - Thành phố Hồ Chí Minh`, search: `Thu Duc - Thanh pho Ho Chi Minh` },
    { district: `Thủ Thừa`, name: `Thủ Thừa - Long An`, search: `Thu Thua - Long An` },
    { district: `Thuận An`, name: `Thuận An - Bình Dương`, search: `Thuan An - Binh Duong` },
    { district: `Thuận Bắc`, name: `Thuận Bắc - Ninh Thuận`, search: `Thuan Bac - Ninh Thuan` },
    { district: `Thuận Châu`, name: `Thuận Châu - Sơn La`, search: `Thuan Chau - Son La` },
    { district: `Thuận Nam`, name: `Thuận Nam - Ninh Thuận`, search: `Thuan Nam - Ninh Thuan` },
    { district: `Thuận Thành`, name: `Thuận Thành - Bắc Ninh`, search: `Thuan Thanh - Bac Ninh` },
    { district: `Thường Tín`, name: `Thường Tín - Hà Nội`, search: `Thuong Tin - Ha Noi` },
    { district: `Thường Xuân`, name: `Thường Xuân - Thanh Hóa`, search: `Thuong Xuan - Thanh Hoa` },
    { district: `Thủy Nguyên`, name: `Thủy Nguyên - Hải Phòng`, search: `Thuy Nguyen - Hai Phong` },
    { district: `Tiên Du`, name: `Tiên Du - Bắc Ninh`, search: `Tien Du - Bac Ninh` },
    { district: `Tiền Hải`, name: `Tiền Hải - Thái Bình`, search: `Tien Hai - Thai Binh` },
    { district: `Tiên Lãng`, name: `Tiên Lãng - Hải Phòng`, search: `Tien Lang - Hai Phong` },
    { district: `Tiên Lữ`, name: `Tiên Lữ - Hưng Yên`, search: `Tien Lu - Hung Yen` },
    { district: `Tiên Phước`, name: `Tiên Phước - Quảng Nam`, search: `Tien Phuoc - Quang Nam` },
    { district: `Tiên Yên`, name: `Tiên Yên - Quảng Ninh`, search: `Tien Yen - Quang Ninh` },
    { district: `Tiểu Cần`, name: `Tiểu Cần - Trà Vinh`, search: `Tieu Can - Tra Vinh` },
    { district: `Tịnh Biên`, name: `Tịnh Biên - An Giang`, search: `Tinh Bien - An Giang` },
    { district: `Trà Bồng`, name: `Trà Bồng - Quảng Ngãi`, search: `Tra Bong - Quang Ngai` },
    { district: `Trà Cú`, name: `Trà Cú - Trà Vinh`, search: `Tra Cu - Tra Vinh` },
    { district: `Trà Ôn`, name: `Trà Ôn - Vĩnh Long`, search: `Tra On - Vinh Long` },
    { district: `Trà Vinh`, name: `Trà Vinh - Trà Vinh`, search: `Tra Vinh - Tra Vinh` },
    { district: `Trạm Tấu`, name: `Trạm Tấu - Yên Bái`, search: `Tram Tau - Yen Bai` },
    { district: `Trần Đề`, name: `Trần Đề - Sóc Trăng`, search: `Tran De - Soc Trang` },
    { district: `Trần Văn Thời`, name: `Trần Văn Thời - Cà Mau`, search: `Tran Van Thoi - Ca Mau` },
    { district: `Trấn Yên`, name: `Trấn Yên - Yên Bái`, search: `Tran Yen - Yen Bai` },
    { district: `Trảng Bàng`, name: `Trảng Bàng - Tây Ninh`, search: `Trang Bang - Tay Ninh` },
    { district: `Trảng Bom`, name: `Trảng Bom - Đồng Nai`, search: `Trang Bom - Dong Nai` },
    { district: `Tràng Định`, name: `Tràng Định - Lạng Sơn`, search: `Trang Dinh - Lang Son` },
    { district: `Tri Tôn`, name: `Tri Tôn - An Giang`, search: `Tri Ton - An Giang` },
    { district: `Triệu Phong`, name: `Triệu Phong - Quảng Trị`, search: `Trieu Phong - Quang Tri` },
    { district: `Triệu Sơn`, name: `Triệu Sơn - Thanh Hóa`, search: `Trieu Son - Thanh Hoa` },
    { district: `Trực Ninh`, name: `Trực Ninh - Nam Định`, search: `Truc Ninh - Nam Dinh` },
    { district: `Trùng Khánh`, name: `Trùng Khánh - Cao Bằng`, search: `Trung Khanh - Cao Bang` },
    { district: `Trường Sa`, name: `Trường Sa - Khánh Hòa`, search: `Truong Sa - Khanh Hoa` },
    { district: `Tứ Kỳ`, name: `Tứ Kỳ - Hải Dương`, search: `Tu Ky - Hai Duong` },
    { district: `Tu Mơ Rông`, name: `Tu Mơ Rông - Kon Tum`, search: `Tu Mo Rong - Kon Tum` },
    { district: `Tư Nghĩa`, name: `Tư Nghĩa - Quảng Ngãi`, search: `Tu Nghia - Quang Ngai` },
    { district: `Từ Sơn`, name: `Từ Sơn - Bắc Ninh`, search: `Tu Son - Bac Ninh` },
    { district: `Tủa Chùa`, name: `Tủa Chùa - Điện Biên`, search: `Tua Chua - Dien Bien` },
    { district: `Tuần Giáo`, name: `Tuần Giáo - Điện Biên`, search: `Tuan Giao - Dien Bien` },
    { district: `Tương Dương`, name: `Tương Dương - Nghệ An`, search: `Tuong Duong - Nghe An` },
    { district: `Tuy An`, name: `Tuy An - Phú Yên`, search: `Tuy An - Phu Yen` },
    { district: `Tuy Đức`, name: `Tuy Đức - Đắk Nông`, search: `Tuy Duc - Dak Nong` },
    { district: `Tuy Hòa`, name: `Tuy Hòa - Phú Yên`, search: `Tuy Hoa - Phu Yen` },
    { district: `Tuy Phong`, name: `Tuy Phong - Bình Thuận`, search: `Tuy Phong - Binh Thuan` },
    { district: `Tuy Phước`, name: `Tuy Phước - Bình Định`, search: `Tuy Phuoc - Binh Dinh` },
    { district: `Tuyên Hóa`, name: `Tuyên Hóa - Quảng Bình`, search: `Tuyen Hoa - Quang Binh` },
    { district: `Tuyên Quang`, name: `Tuyên Quang - Tuyên Quang`, search: `Tuyen Quang - Tuyen Quang` },
    { district: `U Minh Thượng`, name: `U Minh Thượng - Kiên Giang`, search: `U Minh Thuong - Kien Giang` },
    { district: `U Minh`, name: `U Minh - Cà Mau`, search: `U Minh - Ca Mau` },
    { district: `Ứng Hòa`, name: `Ứng Hòa - Hà Nội`, search: `Ung Hoa - Ha Noi` },
    { district: `Uông Bí`, name: `Uông Bí - Quảng Ninh`, search: `Uong Bi - Quang Ninh` },
    { district: `Văn Bàn`, name: `Văn Bàn - Lào Cai`, search: `Van Ban - Lao Cai` },
    { district: `Vân Canh`, name: `Vân Canh - Bình Định`, search: `Van Canh - Binh Dinh` },
    { district: `Văn Chấn`, name: `Văn Chấn - Yên Bái`, search: `Van Chan - Yen Bai` },
    { district: `Vân Đồn`, name: `Vân Đồn - Quảng Ninh`, search: `Van Don - Quang Ninh` },
    { district: `Văn Giang`, name: `Văn Giang - Hưng Yên`, search: `Van Giang - Hung Yen` },
    { district: `Vân Hồ`, name: `Vân Hồ - Sơn La`, search: `Van Ho - Son La` },
    { district: `Văn Lâm`, name: `Văn Lâm - Hưng Yên`, search: `Van Lam - Hung Yen` },
    { district: `Văn Lãng`, name: `Văn Lãng - Lạng Sơn`, search: `Van Lang - Lang Son` },
    { district: `Vạn Ninh`, name: `Vạn Ninh - Khánh Hòa`, search: `Van Ninh - Khanh Hoa` },
    { district: `Văn Quan`, name: `Văn Quan - Lạng Sơn`, search: `Van Quan - Lang Son` },
    { district: `Văn Yên`, name: `Văn Yên - Yên Bái`, search: `Van Yen - Yen Bai` },
    { district: `Vị Thanh`, name: `Vị Thanh - Hậu Giang`, search: `Vi Thanh - Hau Giang` },
    { district: `Vị Thủy`, name: `Vị Thủy - Hậu Giang`, search: `Vi Thuy - Hau Giang` },
    { district: `Vị Xuyên`, name: `Vị Xuyên - Hà Giang`, search: `Vi Xuyen - Ha Giang` },
    { district: `Việt Trì`, name: `Việt Trì - Phú Thọ`, search: `Viet Tri - Phu Tho` },
    { district: `Việt Yên`, name: `Việt Yên - Bắc Giang`, search: `Viet Yen - Bac Giang` },
    { district: `Vĩnh Bảo`, name: `Vĩnh Bảo - Hải Phòng`, search: `Vinh Bao - Hai Phong` },
    { district: `Vĩnh Châu`, name: `Vĩnh Châu - Sóc Trăng`, search: `Vinh Chau - Soc Trang` },
    { district: `Vĩnh Cửu`, name: `Vĩnh Cửu - Đồng Nai`, search: `Vinh Cuu - Dong Nai` },
    { district: `Vĩnh Hưng`, name: `Vĩnh Hưng - Long An`, search: `Vinh Hung - Long An` },
    { district: `Vĩnh Linh`, name: `Vĩnh Linh - Quảng Trị`, search: `Vinh Linh - Quang Tri` },
    { district: `Vĩnh Lộc`, name: `Vĩnh Lộc - Thanh Hóa`, search: `Vinh Loc - Thanh Hoa` },
    { district: `Vĩnh Lợi`, name: `Vĩnh Lợi - Bạc Liêu`, search: `Vinh Loi - Bac Lieu` },
    { district: `Vĩnh Long`, name: `Vĩnh Long - Vĩnh Long`, search: `Vinh Long - Vinh Long` },
    { district: `Vĩnh Thạnh`, name: `Vĩnh Thạnh - Cần Thơ`, search: `Vinh Thanh - Can Tho` },
    { district: `Vĩnh Thạnh`, name: `Vĩnh Thạnh - Bình Định`, search: `Vinh Thanh - Binh Dinh` },
    { district: `Vĩnh Thuận`, name: `Vĩnh Thuận - Kiên Giang`, search: `Vinh Thuan - Kien Giang` },
    { district: `Vĩnh Tường`, name: `Vĩnh Tường - Vĩnh Phúc`, search: `Vinh Tuong - Vinh Phuc` },
    { district: `Vĩnh Yên`, name: `Vĩnh Yên - Vĩnh Phúc`, search: `Vinh Yen - Vinh Phuc` },
    { district: `Vinh`, name: `Vinh - Nghệ An`, search: `Vinh - Nghe An` },
    { district: `Võ Nhai`, name: `Võ Nhai - Thái Nguyên`, search: `Vo Nhai - Thai Nguyen` },
    { district: `Vụ Bản`, name: `Vụ Bản - Nam Định`, search: `Vu Ban - Nam Dinh` },
    { district: `Vũ Quang`, name: `Vũ Quang - Hà Tĩnh`, search: `Vu Quang - Ha Tinh` },
    { district: `Vũ Thư`, name: `Vũ Thư - Thái Bình`, search: `Vu Thu - Thai Binh` },
    { district: `Vũng Liêm`, name: `Vũng Liêm - Vĩnh Long`, search: `Vung Liem - Vinh Long` },
    { district: `Vũng Tàu`, name: `Vũng Tàu - Bà Rịa – Vũng Tàu`, search: `Vung Tau - Ba Ria – Vung Tau` },
    { district: `Xín Mần`, name: `Xín Mần - Hà Giang`, search: `Xin Man - Ha Giang` },
    { district: `Xuân Lộc`, name: `Xuân Lộc - Đồng Nai`, search: `Xuan Loc - Dong Nai` },
    { district: `Xuân Trường`, name: `Xuân Trường - Nam Định`, search: `Xuan Truong - Nam Dinh` },
    { district: `Xuyên Mộc`, name: `Xuyên Mộc - Bà Rịa – Vũng Tàu`, search: `Xuyen Moc - Ba Ria – Vung Tau` },
    { district: `Ý Yên`, name: `Ý Yên - Nam Định`, search: `Y Yen - Nam Dinh` },
    { district: `Yên Bái`, name: `Yên Bái - Yên Bái`, search: `Yen Bai - Yen Bai` },
    { district: `Yên Bình`, name: `Yên Bình - Yên Bái`, search: `Yen Binh - Yen Bai` },
    { district: `Yên Châu`, name: `Yên Châu - Sơn La`, search: `Yen Chau - Son La` },
    { district: `Yên Định`, name: `Yên Định - Thanh Hóa`, search: `Yen Dinh - Thanh Hoa` },
    { district: `Yên Dũng`, name: `Yên Dũng - Bắc Giang`, search: `Yen Dung - Bac Giang` },
    { district: `Yên Khánh`, name: `Yên Khánh - Ninh Bình`, search: `Yen Khanh - Ninh Binh` },
    { district: `Yên Lạc`, name: `Yên Lạc - Vĩnh Phúc`, search: `Yen Lac - Vinh Phuc` },
    { district: `Yên Lập`, name: `Yên Lập - Phú Thọ`, search: `Yen Lap - Phu Tho` },
    { district: `Yên Minh`, name: `Yên Minh - Hà Giang`, search: `Yen Minh - Ha Giang` },
    { district: `Yên Mô`, name: `Yên Mô - Ninh Bình`, search: `Yen Mo - Ninh Binh` },
    { district: `Yên Mỹ`, name: `Yên Mỹ - Hưng Yên`, search: `Yen My - Hung Yen` },
    { district: `Yên Phong`, name: `Yên Phong - Bắc Ninh`, search: `Yen Phong - Bac Ninh` },
    { district: `Yên Sơn`, name: `Yên Sơn - Tuyên Quang`, search: `Yen Son - Tuyen Quang` },
    { district: `Yên Thành`, name: `Yên Thành - Nghệ An`, search: `Yen Thanh - Nghe An` },
    { district: `Yên Thế`, name: `Yên Thế - Bắc Giang`, search: `Yen The - Bac Giang` },
    { district: `Yên Thủy`, name: `Yên Thủy - Hòa Bình`, search: `Yen Thuy - Hoa Binh` },
]