
import * as React from 'react'
import { Text, View, BackHandler, ScrollView, TouchableOpacity, Image, useWindowDimensions } from 'react-native'
import { useTheme } from '@react-navigation/native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { Step } from '../components'
import images from '../../assets'
import axios from 'axios'
import moment from 'moment'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import { setListSearch } from '../redux/ActionCreators'

export default React.memo(function TabHome({ navigation, route }) {
    const { colors, fontFamily } = useTheme()
    const insets = useSafeAreaInsets()
    const [routes, setRoutes] = React.useState([])
    const date = moment(useSelector(state => state.date, shallowEqual))
    const list_search = useSelector(state => state.list_search, shallowEqual)
    const dispatch = useDispatch()
    const { width } = useWindowDimensions()

    const { from, to } = route.params ? route.params : {}

    React.useEffect(() => {
        // axios.get(`https://new.cms.mybus.vn/api/homewayroads`).then(res => {
        axios.get(`https://cms.haivan.com/api/homewayroads`).then(res => {
            console.log(res)
            setRoutes(res.data)
        })
        const backAction = () => {
            BackHandler.exitApp()
            return true;
        };
        const backHandler = BackHandler.addEventListener('hardwareBackPress', backAction)
        return () => backHandler.remove()
    }, [])

    const addListSearch = (item) => {
        console.log(list_search)
        try {
            const found = list_search?.find(element => element[0] === item[0] && element[1] === item[1])
            if (found) return
            let tmp = [...list_search]
            if (tmp.length > 5) {
                tmp.shift()
            }
            dispatch(setListSearch([...tmp, item]))
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <View style={{ flex: 1 }}>
            <View style={{ backgroundColor: colors.primary, paddingTop: insets.top ? insets.top : 0 }}>
                <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', height: 48, position: 'relative' }}>
                    <Text style={{ fontFamily: fontFamily.bold, fontSize: 18 }}>Đặt vé xe</Text>
                </View>
            </View>
            <View>
                <Step step={0} />
            </View>
            <ScrollView showsVerticalScrollIndicator={false}>
                <View style={{ backgroundColor: 'white', padding: 16 }}>
                    <Text style={{ fontFamily: fontFamily.bold }}>Điểm xuất phát</Text>
                    <TouchableOpacity style={{
                        backgroundColor: '#F0F2F5', borderRadius: 4, height: 45, marginTop: 8, justifyContent: 'center',
                        paddingHorizontal: 16, position: 'relative'
                    }} onPress={() => navigation.navigate('ModalPlace', { is_start: true, search: from })}>
                        <Text style={{ color: '#050505', opacity: from ? 1 : 0.5, fontFamily: 'Nunito_400Regular' }}>{from ? from : 'Nhập điểm xuất phát'}</Text>
                        {
                            from && to ? <TouchableOpacity style={{
                                position: 'absolute', right: 0, top: 0, height: '100%',
                                justifyContent: 'center', alignItems: 'center', paddingHorizontal: 12
                            }} onPress={() => {
                                navigation.navigate({
                                    name: 'TabHome',
                                    params: { from: to, to: from },
                                    merge: true,
                                })
                            }}>
                                <Image source={images.icons.exchange} style={{ height: 24, width: 24 }} />
                            </TouchableOpacity> : null
                        }
                    </TouchableOpacity>

                    <Text style={{ fontFamily: fontFamily.bold, marginTop: 14 }}>Điểm đến</Text>
                    <TouchableOpacity style={{
                        backgroundColor: '#F0F2F5', borderRadius: 4, height: 45, marginTop: 8, justifyContent: 'center', paddingHorizontal: 16,
                    }} onPress={() => navigation.navigate('ModalPlace', { is_start: false, search: to })}>
                        <Text style={{ color: '#050505', opacity: to ? 1 : 0.5, fontFamily: 'Nunito_400Regular' }}>{to ? to : 'Nhập điểm đến'}</Text>
                    </TouchableOpacity>

                    <Text style={{ fontFamily: fontFamily.bold, marginTop: 14 }}>Ngày đi</Text>
                    <TouchableOpacity style={{
                        backgroundColor: '#F0F2F5', borderRadius: 4, height: 45, marginTop: 8,
                        alignItems: 'center', paddingHorizontal: 16, flexDirection: 'row'
                    }} onPress={() => navigation.navigate('ModalCalendar', { from_route: 'TabHome' })}>
                        <Text style={{ color: '#050505', flex: 1, fontFamily: 'Nunito_400Regular' }}>
                            {moment().format('DD-MM-YYYY') === date.format('DD-MM-YYYY') ? 'Hôm nay' : date.format('DD-MM-YYYY')}
                        </Text>
                        <Image source={images.icons.calendar} style={{ width: 16, height: 16 }} />
                    </TouchableOpacity>

                    <TouchableOpacity onPress={() => {
                        addListSearch([from, to])
                        navigation.navigate('Trips', { from: from, to: to, date: date.format('YYYY-MM-DD') })
                    }} disabled={!(from && to && date)}>
                        <View style={{
                            height: 45, backgroundColor: colors.primary, marginTop: 16,
                            justifyContent: 'center', alignItems: 'center', borderRadius: 6,
                            opacity: from && to && date ? 1 : 0.5
                        }}>
                            <Text style={{
                                fontSize: 16, fontFamily: fontFamily.semi_bold,
                            }}>Tìm chuyến</Text>
                        </View>
                    </TouchableOpacity>
                </View>

                {
                    list_search?.length > 0 ? <View style={{ paddingTop: 16 }}>
                        <View style={{ flexDirection: 'row', alignItems: 'center', paddingHorizontal: 16 }}>
                            <Image source={images.icons.route} style={{ width: 16, height: 16 }} />
                            <Text style={{ fontSize: 16, marginLeft: 12, fontFamily: fontFamily.bold }}>Tìm kiếm gần đây</Text>
                        </View>
                        <View>
                            <ScrollView showsHorizontalScrollIndicator={false} horizontal>
                                <View style={{ paddingHorizontal: 12, flexDirection: 'row', paddingVertical: 8 }}>
                                    {
                                        list_search.map(item => {
                                            return <TouchableOpacity key={item} style={{
                                                backgroundColor: 'white', borderRadius: 4, paddingHorizontal: 16,
                                                paddingVertical: 12, flexDirection: 'row', alignItems: 'center', marginHorizontal: 4
                                            }} onPress={() => navigation.navigate('Trips', { from: item[0], to: item[1], date: moment().format('YYYY-MM-DD') })}>
                                                <Text style={{ fontFamily: fontFamily.semi_bold, marginRight: 12 }}>{item[0]}</Text>
                                                <View style={{ width: 4, height: 4, borderRadius: 2, backgroundColor: colors.text }} />
                                                <View style={{ width: 4, height: 2, marginHorizontal: 1, backgroundColor: colors.text }} />
                                                <View style={{ width: 4, height: 4, backgroundColor: colors.text }} />
                                                <Text style={{ fontFamily: fontFamily.semi_bold, marginLeft: 12 }}>{item[1]}</Text>
                                            </TouchableOpacity>
                                        })
                                    }
                                </View>
                            </ScrollView>
                        </View>
                    </View> : null
                }

                <View>
                    <View style={{ flexDirection: 'row', alignItems: 'center', paddingHorizontal: 16, marginTop: 8 }}>
                        <Image source={images.icons.map} style={{ width: 16, height: 16 }} />
                        <Text style={{ fontSize: 16, marginLeft: 12, fontFamily: fontFamily.bold }}>Danh sách tuyến</Text>
                    </View>
                    <View style={{ paddingVertical: 8, paddingHorizontal: 8, flexDirection: width > 1024 ? 'row' : 'column', flexWrap: 'wrap' }}>
                        {
                            routes.map((item, idx) => {
                                if ((item.merchant === '02' || item.merchant === '01') && item.ta_search) {
                                    let place = item.ta_search?.split('/')
                                    let names = item.name?.split('-')
                                    return <View style={{ width: width > 1024 ? '50%' : '100%' }} key={idx} >
                                        <TouchableOpacity style={{
                                            backgroundColor: 'white', borderRadius: 4, paddingHorizontal: 16, paddingVertical: 12,
                                            flexDirection: 'row', alignItems: 'center', marginVertical: 4, marginHorizontal: 8
                                        }} onPress={() => {
                                            addListSearch(names)
                                            navigation.navigate('Trips', { from: place[0], to: place[1], date: moment().format('YYYY-MM-DD') })
                                        }}>
                                            <View style={{ flexDirection: 'row', alignItems: 'center', flex: 1 }}>
                                                <Text style={{ fontFamily: fontFamily.semi_bold, marginRight: 12 }} numberOfLines={1}>{names[0]?.trim()}</Text>
                                                <View style={{ width: 4, height: 4, borderRadius: 2, backgroundColor: colors.text }} />
                                                <View style={{ width: 4, height: 2, marginHorizontal: 1, backgroundColor: colors.text }} />
                                                <View style={{ width: 4, height: 4, backgroundColor: colors.text }} />
                                                <Text style={{ fontFamily: fontFamily.semi_bold, marginLeft: 12, flex: 1 }} numberOfLines={1}>{names[1]?.trim()}</Text>
                                            </View>
                                            <View style={{ flexDirection: 'row', alignItems: 'center', marginLeft: 8 }}>
                                                <Image source={images.icons.tab2} style={{ width: 24, height: 24, tintColor: colors.text }} />
                                                <Text style={{ fontFamily: fontFamily.semi_bold, marginLeft: 12 }}>{item.price_avg}</Text>
                                            </View>
                                        </TouchableOpacity>
                                    </View>
                                }
                            })
                        }
                    </View>
                </View>

            </ScrollView>
        </View>
    )
})
