
const images = {
    trips_empty: require('./images/trips_empty.png'),
    contact: require('./images/contact.png'),
    logo: require('./images/logo.png'),
    car_hv: require('./images/car_hv.png'),
    icons: {
        tab1: require('./icons/tab1.png'),
        tab1_: require('./icons/tab1_.png'),
        tab2: require('./icons/tab2.png'),
        tab2_: require('./icons/tab2_.png'),
        tab3: require('./icons/tab3.png'),
        tab3_: require('./icons/tab3_.png'),
        tab4: require('./icons/tab4.png'),
        tab4_: require('./icons/tab4_.png'),
        back: require('./icons/back.png'),
        calendar: require('./icons/calendar.png'),
        checkbox_off: require('./icons/checkbox_off.png'),
        checkbox_on: require('./icons/checkbox_on.png'),
        driver: require('./icons/driver.png'),
        error: require('./icons/error.png'),
        exchange: require('./icons/exchange.png'),
        filter: require('./icons/filter.png'),
        map: require('./icons/map.png'),
        pin: require('./icons/pin.png'),
        radio_off: require('./icons/radio_off.png'),
        radio_on: require('./icons/radio_on.png'),
        sort: require('./icons/sort.png'),
        success: require('./icons/success.png'),
        clock: require('./icons/clock.png'),
        close: require('./icons/close.png'),
        clear: require('./icons/clear.png'),
        wheel: require('./icons/wheel.png'),
        seat_default: require('./icons/seat_default.png'),
        seat_active: require('./icons/seat_active.png'),
        seat_disable: require('./icons/seat_disable.png'),
        route: require('./icons/route.png'),

        add_seat: require('./icons/add_seat.png'),
        bus: require('./icons/bus.png'),
        cell_phone: require('./icons/cell_phone.png'),
        change_seat: require('./icons/change_seat.png'),
        edit_seat: require('./icons/edit_seat.png'),
        edit_seat: require('./icons/edit_seat.png'),
        journey: require('./icons/journey.png'),
        lock: require('./icons/lock.png'),
        paid: require('./icons/paid.png'),
        remove_seat: require('./icons/remove_seat.png'),
        seat_lock: require('./icons/seat_lock.png'),

        account1: require('./icons/account1.png'),
        account2: require('./icons/account2.png'),
        account3: require('./icons/account3.png'),
        account4: require('./icons/account4.png'),
        account5: require('./icons/account5.png'),
        account6: require('./icons/account6.png'),
        search: require('./icons/search.png'),

        histoty_nghiem_thu: require('./icons/histoty_nghiem_thu.png'),
        history_chuyen_doi: require('./icons/history_chuyen_doi.png'),
        history_dat: require('./icons/history_dat.png'),
        history_giam: require('./icons/history_giam.png'),
        history_hoa_hong: require('./icons/history_hoa_hong.png'),
        history_huy: require('./icons/history_huy.png'),
        history_phat: require('./icons/history_phat.png'),
        history_tang: require('./icons/history_tang.png'),
        history_thu: require('./icons/history_thu.png'),

        contact_phone: require('./icons/contact_phone.png'),
        contact_mail: require('./icons/contact_mail.png'),
        contact_web: require('./icons/contact_web.png'),

        user_card: require('./icons/user_card.png'),
        reload: require('./icons/reload.png'),
        countdown: require('./icons/countdown.png'),
        funnel: require('./icons/funnel.png'),
        setting: require('./icons/setting.png'),
    },
};

export default images
