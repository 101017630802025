
import * as React from 'react'
import { Text, View, ScrollView, TouchableOpacity, Image, ActivityIndicator, useWindowDimensions, Platform } from 'react-native'
import { useTheme } from '@react-navigation/native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { ModalJourney, ModalTripInfo, Step } from '../components'
import images from '../../assets'
import axios from 'axios'
import { showMessage } from "react-native-flash-message"
import moment from 'moment'
import Collapsible from 'react-native-collapsible'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { setModalJourney, setModalTripInfo } from '../redux/ActionCreators'
import CountDown from '../libs/countdown'

export default React.memo(function Seats({ navigation, route }) {
    const { colors, fontFamily } = useTheme()
    const insets = useSafeAreaInsets()
    const [data, setData] = React.useState()
    const [progress, setProgress] = React.useState(false)
    const [seat_select, setSeatSelect] = React.useState()
    const [progress_all, setProgressAll] = React.useState(false)
    const positionRef = React.useRef({ x: 0, y: 0 })
    const scrollRef = React.useRef()
    const [collapse_trip, setCollapseTrip] = React.useState(false)
    const dispatch = useDispatch()
    const trip = useSelector(state => state.trip, shallowEqual)
    const action = route.params?.action
    const { width } = useWindowDimensions()

    React.useEffect(() => {
        if (route.params?.reload)
            getTripDetail()
    }, [route.params?.reload])

    React.useEffect(() => {
        getTripDetail()
    }, [trip])

    const getTripDetail = () => {
        setProgress(true)
        const trip_id = trip.id, departure_id = trip.from?.place_id, destination_id = trip.to?.place_id
        if (trip_id && departure_id && destination_id)
            axios.get(`trips/${trip_id}?departure_id=${departure_id}&destination_id=${destination_id}`)
                .then(res => {
                    console.log(res)
                    if (res.status === 200 && res.data.data)
                        setData(res.data.data)
                }).catch(err => console.log(err.response))
                .finally(() => {
                    setProgress(false)
                })
    }

    const keepSeat = (seat) => {
        setProgressAll(true)
        if (action && seat_select) {
            if (action === 'add_ticket') {
                axios.post(`add-ticket?id_from=${seat_select.id}&id_to=${seat.id}&trip_id=${trip.id}`)
                    .then(res => {
                        console.log(res)
                        if (res.status === 200) showMessage({
                            message: "Thông báo",
                            description: "Thêm vé thành công!",
                            type: "info",
                        })
                        navigation.navigate({
                            name: 'Seats',
                            params: { reload: moment().unix(), action: '' },
                            merge: true,
                        })
                    }).catch(err => {
                        console.log(err.response)
                        if (err.response.status === 400)
                            showMessage({
                                message: "Thông báo",
                                description: err.response.data.message,
                                type: "warning",
                            })
                    }).finally(() => setProgressAll(false))
            } else if (action === 'move_ticket') {
                axios.post(`move-ticket?id_from=${seat_select.id}&id_to=${seat.id}&trip_id=${trip.id}`)
                    .then(res => {
                        console.log(res)
                        if (res.status === 200) showMessage({
                            message: "Thông báo",
                            description: "Chuyển chỗ thành công!",
                            type: "info",
                        })
                        navigation.navigate({
                            name: 'Seats',
                            params: { reload: moment().unix(), action: '' },
                            merge: true,
                        })
                    }).catch(err => {
                        console.log(err.response)
                        if (err.response.status === 400)
                            showMessage({
                                message: "Thông báo",
                                description: err.response.data.message,
                                type: "warning",
                            })
                    }).finally(() => setProgressAll(false))
            }
        } else {
            setSeatSelect(seat)
            axios.post('hold-bookings', {
                trip_id: trip.id,
                phone: "0988733166",
                seats: [seat],
            }).then(res => {
                console.log(res)
                if (res.status === 200 && res.data.order_code) {
                    navigation.navigate('Booking', { trip: trip, booking: res.data, seats: [seat] })
                } else {
                    showMessage({
                        message: "Thông báo",
                        description: res.data?.message ? res.data?.message : "Có lỗi xảy ra trong quá trình đặt vé!",
                        type: "warning",
                    })
                }
            }).catch(err => {
                showMessage({
                    message: "Thông báo",
                    description: err.response?.data?.message ? err.response?.data?.message : "Có lỗi xảy ra trong quá trình đặt vé!",
                    type: "warning",
                })
                console.log(err.response)
            }).finally(() =>
                setProgressAll(false)
            )
        }
    }

    const getInfoTicket = (seat) => {
        setSeatSelect(seat)
        if (moment().unix() - moment(seat.expire_time, 'DD-MM-YYYY HH:mm:ss').unix() > 0 || seat.ticket_status > 1)
            navigation.navigate('TicketInfo', { ticket_code: seat.ticket_code, booking_code: seat.order?.code, trip_id: trip.id })
        else {
            setProgressAll(true)
            axios.get(`tickets/${seat.ticket_code}`)
                .then(res => {
                    console.log(res)
                    navigation.navigate('Booking', { trip: trip, booking: { order_code: seat.order?.code, expire_time: seat.expire_time, code: res.data?.data?.code }, seats: [seat] })
                })
                .catch(err => console.log(err.response))
                .finally(() => setProgressAll(false))
        }
    }

    const handleScroll = (event) => {
        const x = event.nativeEvent.contentOffset.x
        const y = event.nativeEvent.contentOffset.y
        positionRef.current = { x, y }
    }

    const cancelBooking = (seat) => {
        setProgressAll(true)
        axios.post('cancel-seat', {
            order_code: seat.order?.code,
            seat_code: seat.ticket_code,
        }).then(res => {
            console.log(res)
            seat.is_edit = 0
            seat.is_empty = true
            setData([...data])
            showMessage({
                message: "Thông báo",
                description: "Huỷ vé thành công!",
                type: "info",
            })
        }).catch(err => {
            console.log(err.response)
        }).finally(() => setProgressAll(false))
    }

    return (
        <View style={{ flex: 1, position: 'relative' }}>
            <View style={{ backgroundColor: colors.primary, paddingTop: insets.top ? insets.top : 0 }}>
                <View style={{
                    flexDirection: 'row', justifyContent: 'center', alignItems: 'center', height: 48,
                    position: 'relative',
                }}>
                    <Text style={{ fontFamily: 'Nunito_700Bold', fontSize: 18 }}>Chọn ghế</Text>
                    <View style={{ position: 'absolute', height: '100%', left: 0, top: 0 }}>
                        <TouchableOpacity onPress={() => {
                            if (Platform.OS === 'web') window.history.back()
                            else navigation.pop()
                        }}
                            style={{ height: '100%', justifyContent: 'center', alignItems: 'center', paddingLeft: 16 }}>
                            <Image source={images.icons.back} style={{ width: 16, height: 16 }} />
                        </TouchableOpacity>
                    </View>
                    <View style={{ position: 'absolute', height: '100%', right: 0, top: 0 }}>
                        <TouchableOpacity onPress={getTripDetail}
                            style={{ height: '100%', justifyContent: 'center', alignItems: 'center', paddingRight: 16 }}>
                            <Image source={images.icons.reload} style={{ width: 16, height: 16 }} />
                        </TouchableOpacity>
                    </View>
                </View>
            </View>
            <View>
                <Step step={2} />
            </View>

            <ScrollView showsVerticalScrollIndicator={false}
                onScroll={(event) => handleScroll(event)}
                scrollEventThrottle={16}
                ref={scrollRef}>
                <View style={{ backgroundColor: 'white' }}>
                    <TouchableOpacity style={{
                        flexDirection: 'row', paddingHorizontal: 16, paddingTop: 8, paddingBottom: 6,
                        justifyContent: 'center', alignItems: 'center'
                    }} onPress={() => setCollapseTrip(!collapse_trip)}>
                        <Image source={images.icons.back} style={{ width: 16, height: 16, marginRight: 8, transform: [{ rotate: collapse_trip ? '90deg' : '-90deg' }] }} />
                        <Text style={{ fontFamily: fontFamily.bold, flex: 1 }} numberOfLines={1}>{trip.route?.name}</Text>
                        <Text style={{ fontFamily: fontFamily.bold, marginLeft: 8 }}>{moment(trip.date).format('DD/MM/YYYY')}</Text>
                    </TouchableOpacity>
                    {
                        <Collapsible collapsed={!collapse_trip}>
                            <View style={{ width: '100%', height: 1, backgroundColor: 'rgba(202, 203, 208, 0.5)' }} />
                            <View style={{ flexDirection: 'column', paddingHorizontal: 16, paddingTop: 6, paddingBottom: 8 }}>
                                <View style={{ flexDirection: 'row' }}>
                                    <View style={{ flexDirection: 'column', alignItems: 'center', paddingTop: 6, paddingBottom: 4 }}>
                                        <View style={{ width: 6, height: 6, backgroundColor: '#050505', borderRadius: 3 }} />
                                        <View style={{ backgroundColor: '#050505', width: 2, height: 20, marginVertical: 4 }} />
                                        <View style={{ width: 6, height: 6, backgroundColor: '#050505' }} />
                                    </View>
                                    <View style={{ flexDirection: 'column', justifyContent: 'space-between', marginLeft: 8, marginRight: 16 }}>
                                        <Text style={{ fontSize: 16, fontFamily: fontFamily.bold, marginBottom: 8 }}>{moment(trip.from?.reached_at).format('HH:mm')}</Text>
                                        <Text style={{ fontSize: 16, fontFamily: fontFamily.bold }}>{moment(trip.to?.reached_at).format('HH:mm')}</Text>
                                    </View>
                                    <View style={{ flexDirection: 'column', justifyContent: 'space-between', flex: 1 }}>
                                        <Text style={{ fontSize: 16, fontFamily: fontFamily.bold }} numberOfLines={1}>{trip.from?.place_name}</Text>
                                        <Text style={{ fontSize: 16, fontFamily: fontFamily.bold }} numberOfLines={1}>{trip.to?.place_name}</Text>
                                    </View>
                                </View>
                            </View>
                            {
                                trip.node_time !== trip.from?.reached_at ? <>
                                    <View style={{ width: '100%', height: 1, backgroundColor: 'rgba(202, 203, 208, 0.5)' }} />
                                    <View style={{ flexDirection: 'row', paddingHorizontal: 16, paddingVertical: 6 }}>
                                        <Text style={{ color: '#CF1820', fontFamily: fontFamily.bold }}>*Khởi hành lúc {moment(trip.node_time).format('HH:mm - DD/MM')}</Text>
                                    </View>
                                </> : null
                            }
                        </Collapsible>
                    }
                    <View style={{ width: '100%', height: 1, backgroundColor: 'rgba(202, 203, 208, 0.5)' }} />
                    <View style={{ flexDirection: 'row', height: 36 }}>
                        <TouchableOpacity style={{ flex: 1, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}
                            onPress={() =>
                                dispatch(setModalJourney(true))
                            }>
                            <Image source={images.icons.journey} style={{ width: 20, height: 20 }} resizeMode='contain'/>
                            <Text style={{ marginLeft: 12 }}>Lộ trình</Text>
                        </TouchableOpacity>
                        <View style={{ width: 1, height: '100%', backgroundColor: 'rgba(202, 203, 208, 0.5)' }} />
                        <TouchableOpacity style={{ flex: 1, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}
                            onPress={() =>
                                dispatch(setModalTripInfo(true))
                            }>
                            <Image source={images.icons.bus} style={{ width: 20, height: 20 }} />
                            <Text style={{ marginLeft: 12 }}>Thông tin xe</Text>
                        </TouchableOpacity>
                    </View>
                </View>

                {/* <View style={{
                    marginTop: 6, height: 34,
                    justifyContent: 'center', alignItems: 'center', flexDirection: 'row'
                }}>
                    <Image source={images.icons.seat_default} style={{ height: 24, width: 24 }} />
                    <Text style={{
                        fontSize: 12, marginLeft: 4, marginRight: 12, fontFamily: fontFamily.regular
                    }}>Chỗ trống</Text>
                    <Image source={images.icons.seat_active} style={{ height: 24, width: 24 }} />
                    <Text style={{
                        fontSize: 12, marginLeft: 4, marginRight: 12, fontFamily: fontFamily.regular
                    }}>Đang đặt</Text>
                    <Image source={images.icons.seat_lock} style={{ height: 24, width: 24 }} />
                    <Text style={{
                        fontSize: 12, marginLeft: 4, fontFamily: fontFamily.regular
                    }}>Đã đặt</Text>
                </View> */}

                {
                    progress ? <View style={{ justifyContent: 'center', alignItems: 'center', marginTop: 16 }}>
                        <ActivityIndicator color={colors.primary} />
                    </View> : <View style={{ marginVertical: 2, flex: 1, paddingBottom: insets.bottom + 12, flexDirection: width > 1024 ? 'row' : 'column' }}>
                        {
                            data?.map((floor, f_idx) => {
                                if (f_idx > 1) return null
                                return <View key={f_idx} style={{ flex: 1 }}>
                                    {
                                        floor?.length > 0 ? <View style={{ flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center', marginTop: 16, marginBottom: 12 }}>
                                            {
                                                f_idx === 0 ? <Image source={images.icons.wheel} style={{ width: 24, height: 24 }} /> : null
                                            }
                                            <Text style={{
                                                fontSize: 16, fontFamily: fontFamily.bold
                                            }}>Tầng {f_idx + 1}</Text>
                                            {
                                                f_idx === 0 ? <Image source={images.icons.wheel} style={{ width: 24, height: 24, opacity: 0 }} /> : null
                                            }
                                        </View> : null
                                    }
                                    {
                                        floor.map((row, r_idx) => {
                                            return <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: 4, paddingHorizontal: 4 }} key={r_idx}>
                                                {
                                                    row.map((col, c_idx) => {
                                                        if (col) {
                                                            if (col.is_empty) return <TouchableOpacity key={c_idx} style={{
                                                                height: 144, flex: 1, marginHorizontal: 2, flexDirection: 'column'
                                                            }} onPress={() => keepSeat(col)}>
                                                                <View key={c_idx} style={{
                                                                    borderColor: colors.primary, borderTopLeftRadius: 6, borderTopRightRadius: 6, borderWidth: 1,
                                                                    flex: 1, borderBottomWidth: 0, backgroundColor: 'white', position: 'relative'
                                                                }}>
                                                                    <View style={{
                                                                        flex: 1, justifyContent: 'center', alignItems: 'center', backgroundColor: 'white',
                                                                        borderTopLeftRadius: 6, borderTopRightRadius: 6
                                                                    }}>
                                                                        <Image source={images.icons.add_seat} style={{ width: 24, height: 24 }} />
                                                                        <Text style={{ fontFamily: fontFamily.semi_bold, fontSize: 18, marginTop: 12 }}>{col.price / 1000}K</Text>
                                                                    </View>
                                                                    <Text style={{
                                                                        fontSize: 12, fontFamily: fontFamily.bold, position: 'absolute',
                                                                        top: 4, right: 6
                                                                    }}>{col.name}</Text>
                                                                </View>
                                                                <View style={{
                                                                    borderColor: colors.primary, backgroundColor: colors.primary,
                                                                    borderWidth: 1, height: 9, width: '100%',
                                                                    borderBottomLeftRadius: 6, borderBottomRightRadius: 6
                                                                }} />
                                                            </TouchableOpacity>
                                                            else {
                                                                if (col.is_edit) {
                                                                    if (moment().unix() - moment(col.expire_time, 'DD-MM-YYYY HH:mm:ss').unix() > 0 || col.ticket_status > 1)
                                                                        return <TouchableOpacity key={c_idx} style={{
                                                                            height: 144, flex: 1, marginHorizontal: 2, flexDirection: 'column'
                                                                        }} onPress={() => getInfoTicket(col)}>
                                                                            <View key={c_idx} style={{
                                                                                borderColor: col.id === seat_select?.id && action ? '#A5BDFF' : colors.primary, borderTopLeftRadius: 6, borderTopRightRadius: 6, borderWidth: 1,
                                                                                flex: 1, borderBottomWidth: 0, backgroundColor: 'white', position: 'relative'
                                                                            }}>
                                                                                <View style={{
                                                                                    flex: 1, justifyContent: 'center', alignItems: 'center', backgroundColor: 'white',
                                                                                    borderTopLeftRadius: 6, borderTopRightRadius: 6
                                                                                }}>
                                                                                    <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                                                                        <Image source={images.icons.user_card} style={{ width: 12, height: 12 }} />
                                                                                        <Text style={{ fontFamily: fontFamily.semi_bold, fontSize: 12, marginLeft: 6 }}>{col.customer?.name}</Text>
                                                                                    </View>
                                                                                    <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                                                                        <Image source={images.icons.cell_phone} style={{ width: 12, height: 12 }} />
                                                                                        <Text style={{ fontFamily: fontFamily.semi_bold, fontSize: 12, marginLeft: 6 }}>{col.customer?.phone}</Text>
                                                                                    </View>
                                                                                </View>
                                                                                <View style={{ position: 'absolute', top: 0, left: 0, width: '100%', flexDirection: 'row', paddingHorizontal: 6, marginTop: 4 }}>
                                                                                    <Text style={{ fontFamily: fontFamily.bold, fontSize: 12, flex: 1 }}>{col.price / 1000}K</Text>
                                                                                    <Text style={{
                                                                                        fontSize: 12, fontFamily: fontFamily.bold,
                                                                                    }}>{col.name}</Text>
                                                                                </View>
                                                                                {
                                                                                    col.is_payment === 1 ? <Image source={images.icons.paid} style={{ width: 30, height: 30, position: 'absolute', bottom: 8, right: 8 }} /> : null
                                                                                }
                                                                            </View>
                                                                            <View style={{
                                                                                borderColor: col.id === seat_select?.id && action ? '#A5BDFF' : colors.primary, backgroundColor: col.id === seat_select?.id && action ? '#A5BDFF' : colors.primary,
                                                                                borderWidth: 1, height: 9, width: '100%',
                                                                                borderBottomLeftRadius: 6, borderBottomRightRadius: 6
                                                                            }} />
                                                                        </TouchableOpacity>
                                                                    else return <View key={c_idx} style={{
                                                                        height: 144, flex: 1, marginHorizontal: 2, flexDirection: 'column'
                                                                    }}>
                                                                        <View key={c_idx} style={{
                                                                            borderColor: colors.primary, borderTopLeftRadius: 6, borderTopRightRadius: 6, borderWidth: 1,
                                                                            flex: 1, borderBottomWidth: 0, backgroundColor: 'white', position: 'relative'
                                                                        }}>
                                                                            <View style={{
                                                                                flex: 1, justifyContent: 'center', alignItems: 'center', backgroundColor: 'white',
                                                                                borderTopLeftRadius: 6, borderTopRightRadius: 6, flexDirection: 'column'
                                                                            }}>
                                                                                <TouchableOpacity style={{ flex: 1, justifyContent: 'center', width: '100%', alignItems: 'center' }} onPress={() => cancelBooking(col)}>
                                                                                    <Image source={images.icons.history_giam} style={{ width: 28, height: 28 }} />
                                                                                </TouchableOpacity>
                                                                                <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                                                                    <Image source={images.icons.countdown} style={{ width: 12, height: 12, marginRight: 6 }} />
                                                                                    <CountDown
                                                                                        until={moment(col.expire_time, 'DD-MM-YYYY HH:mm:ss').unix() - moment().unix()}
                                                                                        size={12}
                                                                                        onFinish={() => {
                                                                                            col.is_edit = 0
                                                                                            col.is_empty = true
                                                                                            setData([...data])
                                                                                        }}
                                                                                        digitStyle={{ backgroundColor: 'transparent', borderWidth: 0, marginHorizontal: 0 }}
                                                                                        digitTxtStyle={{ color: 'black', fontFamily: fontFamily.semi_bold }}
                                                                                        timeToShow={['M', 'S']}
                                                                                        timeLabels={{ m: null, s: null }}
                                                                                        separatorStyle={{ marginBottom: 2, fontFamily: fontFamily.semi_bold }}
                                                                                        showSeparator
                                                                                    />
                                                                                </View>
                                                                                <TouchableOpacity style={{ flex: 1, justifyContent: 'center', width: '100%', alignItems: 'center' }} onPress={() => getInfoTicket(col)}>
                                                                                    <Image source={images.icons.edit_seat} style={{ width: 24, height: 24 }} />
                                                                                </TouchableOpacity>
                                                                            </View>
                                                                            <View style={{ position: 'absolute', top: 0, left: 0, width: '100%', flexDirection: 'row', paddingHorizontal: 6, marginTop: 4 }}>
                                                                                <Text style={{ fontFamily: fontFamily.bold, fontSize: 12, flex: 1 }}>{col.price / 1000}K</Text>
                                                                                <Text style={{
                                                                                    fontSize: 12, fontFamily: fontFamily.bold,
                                                                                }}>{col.name}</Text>
                                                                            </View>
                                                                        </View>
                                                                        <View style={{
                                                                            borderColor: colors.primary, backgroundColor: colors.primary,
                                                                            borderWidth: 1, height: 9, width: '100%',
                                                                            borderBottomLeftRadius: 6, borderBottomRightRadius: 6
                                                                        }} />
                                                                    </View>
                                                                }

                                                                else return <View key={c_idx} style={{ height: 144, flex: 1, marginHorizontal: 2, flexDirection: 'column' }}>
                                                                    <View key={c_idx} style={{
                                                                        borderColor: 'gray', flex: 1, borderTopLeftRadius: 6, borderTopRightRadius: 6,
                                                                        borderWidth: 1, backgroundColor: 'rgba(202, 203, 208, 0.5)', borderBottomWidth: 0
                                                                    }}>
                                                                        <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                                                                            <Image source={images.icons.lock} style={{ width: 16, height: 16 }} />
                                                                        </View>
                                                                    </View>
                                                                    <View style={{
                                                                        borderColor: 'gray', backgroundColor: 'rgba(202, 203, 208, 0.5)',
                                                                        borderWidth: 1, height: 9, width: '100%',
                                                                        borderBottomLeftRadius: 6, borderBottomRightRadius: 6
                                                                    }} />
                                                                </View>
                                                            }
                                                        } else return <View key={c_idx} style={{ flex: 1 }} />
                                                    })
                                                }
                                            </View>
                                        })
                                    }
                                </View>
                            })
                        }
                    </View>
                }
            </ScrollView>

            {
                progress_all ? <View style={{
                    top: 0, left: 0, width: '100%', height: '100%',
                    justifyContent: "center", alignItems: "center", position: 'absolute', backgroundColor: '#FFFFFF80'
                }}>
                    <ActivityIndicator color={colors.primary} />
                </View> : null
            }

            {
                action && action === 'add_ticket' ? <View style={{ justifyContent: 'center', alignItems: 'center', position: 'absolute', bottom: insets.bottom + 16, width: '100%', left: 0 }}>
                    <TouchableOpacity style={{
                        backgroundColor: '#A5BDFF', height: 44, borderRadius: 22, flexDirection: 'row', paddingHorizontal: 16,
                        justifyContent: 'center', alignItems: 'center',
                    }} onPress={() => {
                        navigation.navigate({
                            name: 'Seats',
                            params: { action: '' },
                            merge: true,
                        })
                    }}>
                        <Image source={images.icons.close} style={{ width: 12, height: 12 }} />
                        <Text style={{ fontSize: 16, fontFamily: fontFamily.semi_bold, marginLeft: 8 }}>Bỏ thêm vé</Text>
                    </TouchableOpacity>
                </View> : null
            }

            {
                action && action === 'move_ticket' ? <View style={{ justifyContent: 'center', alignItems: 'center', position: 'absolute', bottom: insets.bottom + 16, width: '100%', left: 0 }}>
                    <TouchableOpacity style={{
                        backgroundColor: 'white', height: 44, borderRadius: 22, flexDirection: 'row', paddingHorizontal: 16,
                        justifyContent: 'center', alignItems: 'center', shadowColor: "#000", elevation: 3,
                        shadowOffset: {
                            width: 0,
                            height: 1,
                        },
                        shadowOpacity: 0.22,
                        shadowRadius: 2.22,
                    }} onPress={() => {
                        navigation.navigate({
                            name: 'Seats',
                            params: { action: '' },
                            merge: true,
                        })
                    }}>
                        <Image source={images.icons.close} style={{ width: 12, height: 12 }} />
                        <Text style={{ fontSize: 16, fontFamily: fontFamily.semi_bold, marginLeft: 8 }}>Bỏ chuyển chỗ</Text>
                    </TouchableOpacity>
                </View> : null
            }

            <ModalJourney trip={trip} />
            <ModalTripInfo trip={trip} />
        </View>
    )
})
