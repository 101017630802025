
import { ActionTypes } from './ActionTypes'

export const setUserLogin = (data) => {
    return { type: ActionTypes.SET_USER_LOGIN, payload: data }
}
export const setTrip = (data) => {
    return { type: ActionTypes.SET_TRIP, payload: data }
}
export const setDate = (data) => {
    return { type: ActionTypes.SET_DATE, payload: data }
}
export const setModalJourney = (data) => {
    return { type: ActionTypes.SET_MODAL_JOURNEY, payload: data }
}
export const setModalTripInfo = (data) => {
    return { type: ActionTypes.SET_MODAL_TRIP_INFO, payload: data }
}
export const setListSearch = (data) => {
    return { type: ActionTypes.SET_LIST_SEARCH, payload: data }
}