
import * as React from 'react'
import { Text, View, ScrollView, TouchableOpacity } from 'react-native'
import { useTheme, useNavigation } from '@react-navigation/native'
import images from '../../assets'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import axios from 'axios'

export default React.memo(function BookingList({ }) {
    const { colors, fontFamily } = useTheme()
    const insets = useSafeAreaInsets()
    const navigation = useNavigation()

    // React.useEffect(() => {
    //     axios.get(`bookings?phone=0988733166`).then(res => {
    //         console.log(res)
    //     })
    // }, [])

    return <ScrollView showsVerticalScrollIndicator={false}>
        <View style={{ paddingVertical: 8 }}>
            <TouchableOpacity style={{
                marginHorizontal: 16, backgroundColor: 'white', borderRadius: 8
            }} onPress={() => navigation.navigate('BookingDetail')}>
                <View style={{ flexDirection: 'column', paddingHorizontal: 16, paddingVertical: 8 }}>
                    <View style={{ flexDirection: 'row', marginBottom: 8 }}>
                        <Text style={{ fontFamily: 'Nunito_700Bold', flex: 1 }} numberOfLines={1}>Hà Nội - Điện Biên</Text>
                        <Text style={{ fontFamily: 'Nunito_700Bold', marginLeft: 8 }} numberOfLines={1}>Hải Vân</Text>
                    </View>
                    <View style={{ flexDirection: 'row' }}>
                        <View style={{ flexDirection: 'row', alignItems: 'center', flex: 1 }}>
                            <Text style={{ fontFamily: 'Nunito_700Bold' }} numberOfLines={1}>Economy</Text>
                            <View style={{ width: 4, height: 4, borderRadius: 2, marginHorizontal: 4, backgroundColor: '#050505' }} />
                            <Text style={{ fontFamily: 'Nunito_400Regular' }} numberOfLines={1}>3 vé</Text>
                        </View>
                        <Text style={{ fontFamily: 'Nunito_700Bold', marginLeft: 8 }} numberOfLines={1}>22/07/2021</Text>
                    </View>
                </View>
                <View style={{ width: '100%', height: 1, backgroundColor: 'rgba(202, 203, 208, 0.5)' }} />
                <View style={{ flexDirection: 'column', paddingHorizontal: 16, paddingVertical: 8 }}>
                    <View style={{ flexDirection: 'row' }}>
                        <View style={{ flexDirection: 'column', alignItems: 'center', paddingTop: 6, paddingBottom: 4 }}>
                            <View style={{ width: 6, height: 6, backgroundColor: '#050505', borderRadius: 3 }} />
                            <View style={{ backgroundColor: '#050505', width: 2, height: 34, marginVertical: 4 }} />
                            <View style={{ width: 6, height: 6, backgroundColor: '#050505' }} />
                        </View>
                        <View style={{ flexDirection: 'column', justifyContent: 'space-between', marginLeft: 8, marginRight: 16 }}>
                            <Text style={{ fontSize: 16, fontFamily: 'Nunito_700Bold' }}>06:15</Text>
                            <Text style={{ fontSize: 12, fontFamily: 'Nunito_400Regular' }}>02h00p</Text>
                            <Text style={{ fontSize: 16, fontFamily: 'Nunito_700Bold' }}>10:15</Text>
                        </View>
                        <View style={{ flexDirection: 'column', justifyContent: 'space-between' }}>
                            <Text style={{ fontSize: 16, fontFamily: 'Nunito_700Bold' }}>Văn phòng Mỹ Đình</Text>
                            <Text style={{ fontSize: 16, fontFamily: 'Nunito_700Bold' }}>Bến xe Điện Biên</Text>
                        </View>
                    </View>
                </View>
                <View style={{ width: '100%', height: 1, backgroundColor: 'rgba(202, 203, 208, 0.5)' }} />
                <View style={{ flexDirection: 'row', paddingHorizontal: 16, paddingVertical: 8 }}>
                    <Text style={{ flex: 1, fontSize: 16, fontFamily: 'Nunito_400Regular' }}>Ghế 1,5</Text>
                    <Text style={{ color: '#E7AC00', fontSize: 16, fontFamily: 'Nunito_700Bold' }}>460.000đ</Text>
                </View>
            </TouchableOpacity>
        </View>
    </ScrollView>
})
