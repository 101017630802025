
import * as React from 'react'
import { Text, View, ScrollView, TouchableOpacity, Image } from 'react-native'
import { useTheme } from '@react-navigation/native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import images from '../../assets'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { setUserLogin } from '../redux/ActionCreators'
import UserAvatar from 'react-native-user-avatar'

export default React.memo(function TabAcount({ navigation, route }) {
    const { colors, fontFamily } = useTheme()
    const insets = useSafeAreaInsets()
    const dispatch = useDispatch()
    const user_login = useSelector(state => state.user_login, shallowEqual)

    return (
        <View style={{ flex: 1 }}>
            <View style={{ backgroundColor: colors.primary, paddingTop: insets.top ? insets.top : 0 }}>
                <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', height: 48, position: 'relative' }}>
                    <Text style={{ fontFamily: fontFamily.bold, fontSize: 18 }}>Thông tin đại lý</Text>
                </View>
            </View>
            <ScrollView showsVerticalScrollIndicator={false}>
                <View style={{ flexDirection: 'row', paddingHorizontal: 16, paddingVertical: 12, justifyContent: 'center', alignItems: 'center' }}>
                    <UserAvatar size={60} name={user_login?.user?.name} bgColor="#000" />
                    <View style={{ flex: 1, marginLeft: 12 }}>
                        <Text style={{ fontFamily: fontFamily.bold, fontSize: 16 }} numberOfLines={1}>{user_login?.agency?.name}</Text>
                        <Text style={{ fontFamily: fontFamily.semi_bold, marginTop: 2 }} numberOfLines={1}>{user_login?.user?.name}</Text>
                    </View>
                </View>
                <View style={{ backgroundColor: 'rgba(202, 203, 208, 0.5)', height: 1 }} />

                {
                    user_login?.is_admin ? <>
                        <Text style={{ fontFamily: fontFamily.semi_bold, fontSize: 16, paddingHorizontal: 16, paddingTop: 12, paddingBottom: 4 }}>Danh sách tài khoản</Text>
                        <View style={{ backgroundColor: 'rgba(202, 203, 208, 0.5)', height: 1 }} />
                        <TouchableOpacity style={{
                            height: 48, flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
                            backgroundColor: 'white', paddingHorizontal: 16
                        }} onPress={() => navigation.navigate('AccountManage')}>
                            <Image source={images.icons.account1} style={{ width: 16, height: 16 }} />
                            <Text style={{ flex: 1, paddingHorizontal: 12 }}>Xem danh sách tài khoản</Text>
                            <Image source={images.icons.back} style={{ width: 12, height: 12, transform: [{ rotate: '180deg' }] }} />
                        </TouchableOpacity>
                        <View style={{ backgroundColor: 'rgba(202, 203, 208, 0.5)', height: 1 }} />
                    </> : null
                }

                {/* <Text style={{ fontFamily: fontFamily.semi_bold, fontSize: 16, paddingHorizontal: 16, paddingTop: 12, paddingBottom: 4 }}>Thông tin</Text>
                <View style={{ backgroundColor: 'rgba(202, 203, 208, 0.5)', height: 1 }} />
                <TouchableOpacity style={{
                    height: 48, flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
                    backgroundColor: 'white', paddingHorizontal: 16
                }}>
                    <Image source={images.icons.account2} style={{ width: 16, height: 16 }} />
                    <Text style={{ flex: 1, paddingHorizontal: 12 }}>Chính sách chung</Text>
                    <Image source={images.icons.back} style={{ width: 12, height: 12, transform: [{ rotate: '180deg' }] }} />
                </TouchableOpacity>
                <View style={{ backgroundColor: 'rgba(202, 203, 208, 0.5)', height: 1 }} />

                <TouchableOpacity style={{
                    height: 48, flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
                    backgroundColor: 'white', paddingHorizontal: 16
                }}>
                    <Image source={images.icons.account3} style={{ width: 16, height: 16 }} />
                    <Text style={{ flex: 1, paddingHorizontal: 12 }}>Chính sách hoàn huỷ</Text>
                    <Image source={images.icons.back} style={{ width: 12, height: 12, transform: [{ rotate: '180deg' }] }} />
                </TouchableOpacity>
                <View style={{ backgroundColor: 'rgba(202, 203, 208, 0.5)', height: 1 }} />

                <TouchableOpacity style={{
                    height: 48, flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
                    backgroundColor: 'white', paddingHorizontal: 16
                }}>
                    <Image source={images.icons.account4} style={{ width: 16, height: 16 }} />
                    <Text style={{ flex: 1, paddingHorizontal: 12 }}>Chính sách hoa hồng</Text>
                    <Image source={images.icons.back} style={{ width: 12, height: 12, transform: [{ rotate: '180deg' }] }} />
                </TouchableOpacity>
                <View style={{ backgroundColor: 'rgba(202, 203, 208, 0.5)', height: 1 }} /> */}

                <Text style={{ fontFamily: fontFamily.semi_bold, fontSize: 16, paddingHorizontal: 16, paddingTop: 12, paddingBottom: 4 }}>Hỗ trợ</Text>
                <View style={{ backgroundColor: 'rgba(202, 203, 208, 0.5)', height: 1 }} />
                <TouchableOpacity style={{
                    height: 48, flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
                    backgroundColor: 'white', paddingHorizontal: 16
                }} onPress={() => navigation.navigate('Contact')}>
                    <Image source={images.icons.account5} style={{ width: 16, height: 16 }} />
                    <Text style={{ flex: 1, paddingHorizontal: 12 }}>Thông tin liên hệ</Text>
                    <Image source={images.icons.back} style={{ width: 12, height: 12, transform: [{ rotate: '180deg' }] }} />
                </TouchableOpacity>
                <View style={{ backgroundColor: 'rgba(202, 203, 208, 0.5)', height: 1 }} />


                <TouchableOpacity style={{
                    height: 48, flexDirection: 'row', justifyContent: 'center', alignItems: 'center', paddingHorizontal: 16
                }} onPress={() => {
                    dispatch(setUserLogin(null))
                    navigation.replace('Login')
                }}>
                    <Image source={images.icons.account6} style={{ width: 16, height: 16 }} />
                    <Text style={{ flex: 1, paddingHorizontal: 12 }}>Đăng xuất</Text>
                </TouchableOpacity>
            </ScrollView>
        </View>
    )
})
