
import * as React from 'react'
import { Text, View, TouchableOpacity, Image, FlatList, ActivityIndicator, useWindowDimensions } from 'react-native'
import { useTheme } from '@react-navigation/native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import images from '../../assets'
import axios from 'axios'
import moment from 'moment'
import qs from 'qs'
import { RefreshControl } from 'react-native-web-refresh-control'
import _ from 'lodash'
import { ModalFilterChange } from '../components'

const start_week = moment().startOf('week').format('YYYY-MM-DD')
const start_month = moment().startOf('month').format('YYYY-MM-DD')
const start_year = moment().startOf('year').format('YYYY-MM-DD')

export default React.memo(function TabChange() {
    const { colors, fontFamily } = useTheme()
    const insets = useSafeAreaInsets()
    const [data, setData] = React.useState({})
    const [info, setInfo] = React.useState({})
    const [modal_filter, setModalFilter] = React.useState(false)
    const { width } = useWindowDimensions()
    const [filter, setFilter] = React.useState({
        tab_index: 0,
        start_date: start_week,
        end_date: moment().format('YYYY-MM-DD'),
        types: []
    })
    const [refreshing, setRefreshing] = React.useState(true);
    const onRefresh = React.useCallback(() => {
        fetchInfoAgency()
        fetchTransaction()
    }, [])

    React.useEffect(() => {
        console.log(filter.types)
        fetchInfoAgency()
        fetchTransaction()
    }, [filter.start_date, filter.end_date, filter.types])

    const fetchInfoAgency = () => {
        setRefreshing(true)
        axios.get(`agencies`)
            .then(res => {
                console.log(res)
                setInfo(res.data.data)
            })
            .catch(err => console.log(err.response))
            .finally(() =>
                setRefreshing(false)
            )
    }

    const fetchTransaction = () => {
        setRefreshing(true)
        axios.get(`agency-transactions?${qs.stringify({
            'date_from': filter.start_date,
            'date_to': filter.end_date,
            'types': filter.types
        })}`)
            .then(res => {
                console.log(res)
                setData(res.data)
            })
            .catch(err => console.log(err.response))
            .finally(() =>
                setRefreshing(false)
            )
    }

    const fetchMoreTransaction = _.debounce(() => {
        let page = data.current_page
        try {
            if (data.current_page === data.last_page) return
            else page++
        } catch (error) {
        }
        axios.get(`agency-transactions?${qs.stringify({
            'date_from': filter.start_date,
            'date_to': filter.end_date,
            'types': filter.types,
            page
        })}`)
            .then(res => {
                console.log(res)
                if (data?.data?.length > 0) {
                    let tmp_data = {
                        data: [...data.data, ...res.data.data],
                        current_page: res.data.current_page,
                        last_page: res.data.last_page
                    }
                    setData(tmp_data)
                } else setData(res.data)
            })
            .catch(err => console.log(err.response))
    }, 250)

    const keyExtractor = React.useCallback((item) => `${item.id}`)

    const renderItem = React.useCallback(({ item }) => (
        <View style={{ flex: 0.5 }} >
            <View style={{
                backgroundColor: 'white', borderRadius: 24, marginTop: 8, padding: 16, flexDirection: 'row', marginHorizontal: 8,
                justifyContent: 'center', alignItems: 'center'
            }}>
                {renderImage(item.type?.value)}
                <View style={{ flex: 1, marginLeft: 12 }}>
                    <View style={{ flexDirection: 'row' }}>
                        <Text style={{ fontSize: 16, fontFamily: fontFamily.semi_bold, flex: 1 }} numberOfLines={1}>{item.type?.name}</Text>
                        <Text style={{
                            fontSize: 16, fontFamily: fontFamily.semi_bold,
                            color: [2, 4, 6, 7].includes(item.type?.value) ? '#FD3C4A' : '#00A86B', marginLeft: 8
                        }}>
                            {[2, 4, 6, 7].includes(item.type?.value) ? '-' : '+'}{Intl.NumberFormat('vi-VN').format(item.amount)}đ
                        </Text>
                    </View>
                    <View style={{ flexDirection: 'row', marginTop: 4 }}>
                        <Text style={{ fontFamily: fontFamily.semi_bold, flex: 1, color: '#91919F' }} numberOfLines={2}>{item.description}</Text>
                        <Text style={{ fontFamily: fontFamily.semi_bold, color: '#91919F', marginLeft: 8 }}>{moment(item.time).format('HH:mm DD/MM')}</Text>
                    </View>
                </View>
            </View>
        </View>
    ))

    const renderImage = (value) => {
        switch (value) {
            case 1: return <Image source={images.icons.history_tang} style={{ width: 48, height: 48 }} />
            case 2: return <Image source={images.icons.history_giam} style={{ width: 48, height: 48 }} />
            case 3: return <Image source={images.icons.history_hoa_hong} style={{ width: 48, height: 48 }} />
            case 4: return <Image source={images.icons.history_dat} style={{ width: 48, height: 48 }} />
            case 5: return <Image source={images.icons.history_huy} style={{ width: 48, height: 48 }} />
            case 6: return <Image source={images.icons.history_thu} style={{ width: 48, height: 48 }} />
            case 7: return <Image source={images.icons.history_phat} style={{ width: 48, height: 48 }} />
            default:
                break
        }
    }

    const setIndex = (tab_index) => {
        setData({})
        let start_date = start_week
        if (tab_index === 1) start_date = start_month
        if (tab_index === 2) start_date = start_year
        setFilter({ ...filter, start_date, tab_index })
    }

    const callbackFilter = (data) => {
        setModalFilter(data.visible)
        if (data.filter) setFilter(data.filter)
    }

    const renderHeaderList = () => {
        return <View style={{ marginBottom: 8 }}>
            {
                data?.data?.length === 0 ? <View style={{ flex: 1, alignItems: 'center', marginTop: 16 }}>
                    <Text style={{ marginTop: 26, fontFamily: fontFamily.regular }}>Chưa có thông tin biến động!</Text>
                </View> : null
            }
        </View>
    }

    const renderFooterList = () => {
        return <View style={{ padding: 4, justifyContent: 'center', alignItems: 'center' }}>
            {
                data?.current_page === data?.last_page ? null : <ActivityIndicator color={colors.primary} />
            }
        </View>
    }

    return (
        <View style={{ flex: 1 }}>
            <View style={{ backgroundColor: colors.primary, paddingTop: insets.top ? insets.top : 0 }}>
                <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', height: 48, position: 'relative' }}>
                    <Text style={{ fontFamily: fontFamily.bold, fontSize: 18 }}>Biến động số dư</Text>
                    <View style={{ position: 'absolute', height: '100%', right: 0, top: 0 }}>
                        <TouchableOpacity onPress={() => setModalFilter(true)}
                            style={{ height: '100%', justifyContent: 'center', alignItems: 'center', paddingRight: 16, flexDirection: 'row' }}>
                            <Image source={images.icons.funnel} style={{ width: 16, height: 16 }} />
                            <Text style={{ marginLeft: 2 }}>Bộ lọc</Text>
                        </TouchableOpacity>
                    </View>
                </View>
                <View style={{ justifyContent: 'center', alignItems: 'center' }}>
                    <Text style={{ fontFamily: fontFamily.bold, fontSize: 18 }}>Tổng số dư</Text>
                    <Text style={{ fontFamily: fontFamily.bold, fontSize: 30 }}>{info?.balance ? Intl.NumberFormat('vi-VN').format(info.balance) : 0}đ</Text>
                </View>
                <View style={{ backgroundColor: colors.background, position: 'relative' }}>
                    <View style={{
                        position: 'absolute', top: 0, width: '100%', height: 40, backgroundColor: colors.primary,
                        borderBottomRightRadius: 16, borderBottomLeftRadius: 16
                    }} />
                    <View style={{ flexDirection: 'row', backgroundColor: 'white', borderRadius: 6, marginHorizontal: 16, height: 48, marginTop: 8 }}>
                        <TouchableOpacity style={{ justifyContent: 'center', alignItems: 'center', flex: 1 }} onPress={() => setIndex(0)}>
                            <Text style={{ fontFamily: fontFamily.semi_bold, marginBottom: 6, marginTop: 4 }}>Tuần</Text>
                            <View style={{ backgroundColor: filter.tab_index === 0 ? colors.primary : 'white', height: 2, borderRadius: 1, width: '80%' }} />
                        </TouchableOpacity>
                        <TouchableOpacity style={{ justifyContent: 'center', alignItems: 'center', flex: 1 }} onPress={() => setIndex(1)}>
                            <Text style={{ fontFamily: fontFamily.semi_bold, marginBottom: 6, marginTop: 4 }}>Tháng</Text>
                            <View style={{ backgroundColor: filter.tab_index === 1 ? colors.primary : 'white', height: 2, borderRadius: 1, width: '80%' }} />
                        </TouchableOpacity>
                        <TouchableOpacity style={{ justifyContent: 'center', alignItems: 'center', flex: 1 }} onPress={() => setIndex(2)}>
                            <Text style={{ fontFamily: fontFamily.semi_bold, marginBottom: 6, marginTop: 4 }}>Năm</Text>
                            <View style={{ backgroundColor: filter.tab_index === 2 ? colors.primary : 'white', height: 2, borderRadius: 1, width: '80%' }} />
                        </TouchableOpacity>
                    </View>
                </View>
            </View>

            {
                data?.data ? (
                    width > 1024 ? <FlatList
                        key={'_'}
                        numColumns={2}
                        style={{ paddingHorizontal: 8 }}
                        refreshControl={
                            <RefreshControl
                                refreshing={refreshing}
                                onRefresh={onRefresh}
                                colors={[colors.primary]}
                            />
                        }
                        initialNumToRender={10}
                        ListHeaderComponent={renderHeaderList}
                        ListFooterComponent={renderFooterList}
                        data={data.data}
                        renderItem={renderItem}
                        keyExtractor={keyExtractor}
                        onEndReached={fetchMoreTransaction}
                        onEndReachedThreshold={0.5} /> :
                        <FlatList
                            key={'#'}
                            numColumns={1}
                            style={{ paddingHorizontal: 8 }}
                            refreshControl={
                                <RefreshControl
                                    refreshing={refreshing}
                                    onRefresh={onRefresh}
                                    colors={[colors.primary]}
                                />
                            }
                            initialNumToRender={10}
                            ListHeaderComponent={renderHeaderList}
                            ListFooterComponent={renderFooterList}
                            data={data.data}
                            renderItem={renderItem}
                            keyExtractor={keyExtractor}
                            onEndReached={fetchMoreTransaction}
                            onEndReachedThreshold={0.5} />
                ) : <View style={{ alignItems: 'center', flex: 1, marginTop: 24 }}>
                    <ActivityIndicator color={colors.primary} />
                </View>
            }

            <ModalFilterChange filter={filter} visible={modal_filter} callback={callbackFilter} />
        </View>
    )
})
