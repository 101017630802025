
import * as React from 'react'
import { useTheme } from '@react-navigation/native'
import images from '../../assets'
import { BackHandler } from 'react-native'
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs'
import { MyTabBar } from '../components'
import TabHome from './TabHome'
import TabTicket from './TabTicket'
import TabAccount from './TabAccount'
import TabChange from './TabChange'
import { useSelector, shallowEqual } from 'react-redux'

const Tab = createBottomTabNavigator()

export default React.memo(function Main() {
    const user_login = useSelector(state => state.user_login, shallowEqual)

    React.useEffect(() => {
        const backAction = () => {
            BackHandler.exitApp()
            return true;
        };
        const backHandler = BackHandler.addEventListener('hardwareBackPress', backAction)
        return () => backHandler.remove()
    }, [])

    return (
        <Tab.Navigator tabBar={props => <MyTabBar {...props} />} >
            <Tab.Screen name="TabHome" component={TabHome}
                options={{
                    headerShown: false,
                    tabBarLabel: 'Đặt vé xe',
                    tabBarImage: [images.icons.tab1, images.icons.tab1_],
                    title: 'Đặt vé xe'
                }} />
            <Tab.Screen name="TabTicket" component={TabTicket}
                options={{
                    headerShown: false,
                    tabBarLabel: 'Vé đã bán',
                    tabBarImage: [images.icons.tab2, images.icons.tab2_],
                    title: 'Vé đã bán'
                }} />
            {
                user_login?.is_admin ? <Tab.Screen name="TabChange" component={TabChange}
                    options={{
                        headerShown: false,
                        tabBarLabel: 'Biến động',
                        tabBarImage: [images.icons.tab3, images.icons.tab3_],
                        title: 'Biến động'
                    }} /> : null
            }

            <Tab.Screen name="TabAccount" component={TabAccount}
                options={{
                    headerShown: false,
                    tabBarLabel: 'Đại lý',
                    tabBarImage: [images.icons.tab4, images.icons.tab4_],
                    title: 'Đại lý'
                }} />
        </ Tab.Navigator>
    )
})
