
import * as React from 'react'
import { Text, View, ScrollView, Image, Dimensions, FlatList } from 'react-native'
import { useTheme } from '@react-navigation/native'
import images from '../../assets'

const width = Dimensions.get('window').width

export default React.memo(function Step({ step }) {
    console.log(step)
    const { colors } = useTheme()
    const steps = [
        'Tìm kiếm chuyến đi', 'Danh sách chuyến đi', 'Chọn ghế', 'Thông tin vé', 'Chọn điểm đến - Điểm đi', 'Thanh Toán'
    ]
    const ref = React.useRef(null)

    React.useEffect(() => {
        try {
            setTimeout(() => {
                ref.current.scrollToIndex({ animated: true, index: step, viewPosition: 0.5 })
            }, 500);
        } catch (error) {
        }
    }, [step])

    const renderItem = ({ item, index }) => {
        return <View
            style={{
                flexDirection: 'row', justifyContent: 'center', alignItems: 'center', opacity: index <= step ? 1 : 0.3,
                flexDirection: 'row', height: 40, justifyContent: 'center'
            }}>
            <View style={{ backgroundColor: '#050505', borderRadius: 8, width: 16, height: 16, justifyContent: 'center', alignItems: 'center' }}>
                <Text style={{ fontSize: 10, color: 'white', fontFamily: 'Nunito_600SemiBold' }}>{index + 1}</Text>
            </View>
            <Text style={{ fontSize: 14, color: '#050505', marginLeft: 8, marginTop: 1, fontFamily: 'Nunito_400Regular' }}>{item}</Text>
            {
                index === 5 ? <View /> :
                    <Image source={images.icons.back} style={{ width: 12, height: 12, marginHorizontal: 8, transform: [{ rotate: '180deg' }] }} />
            }
        </View>
    }

    return <FlatList
        contentContainerStyle={{ paddingHorizontal: 16 }}
        scrollEnabled={false}
        showsVerticalScrollIndicator={false}
        showsHorizontalScrollIndicator={false}
        data={steps}
        renderItem={renderItem}
        keyExtractor={(item) => item}
        horizontal={true}
        ref={ref}
    />
})
