
import * as React from 'react'
import { Text, View, ScrollView, TouchableOpacity, Image, Platform } from 'react-native'
import { CalendarList, Calendar } from 'react-native-calendars'
import moment from 'moment'
import { useTheme, CommonActions } from '@react-navigation/native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import images from '../../assets'
import 'moment-lunar'
import { setDate } from '../redux/ActionCreators'

export default React.memo(function ModalCalendar({ navigation, route }) {
    const { colors, fontFamily } = useTheme()
    const insets = useSafeAreaInsets()
    const date_prop = useSelector(state => state.date, shallowEqual)
    const dispatch = useDispatch()

    let markedDates = {}
    let from_route = route.params?.from_route

    markedDates[date_prop] = {
        customStyles: {
            container: {
                backgroundColor: colors.text,
                borderRadius: 20,
            },
            text: {
                color: 'white',
                fontFamily: 'Nunito_700Bold'
            },
        },
    }

    return (
        <View style={{ flex: 1, backgroundColor: '#F1F1FA' }}>
            <View style={{ backgroundColor: 'white' }}>
                <View style={{
                    flexDirection: 'row', justifyContent: 'center', alignItems: 'center', height: 48, paddingHorizontal: 8,
                    marginTop: Platform.OS === 'android' ? insets.top : 0
                }}>
                    <TouchableOpacity style={{ padding: 8 }} onPress={() => {
                        if (Platform.OS === 'web') window.history.back()
                        else navigation.pop()
                    }}>
                        <Image source={images.icons.close} style={{ width: 16, height: 16 }} />
                    </TouchableOpacity>
                    <Text style={{ flex: 1, textAlign: 'center', color: colors.text, fontFamily: 'Nunito_700Bold', fontSize: 18 }}>
                        Ngày đi
                    </Text>
                    <TouchableOpacity style={{ padding: 8, opacity: 0 }}>
                        <Image source={images.icons.close} style={{ width: 16, height: 16 }} />
                    </TouchableOpacity>
                </View>
            </View>

            <ScrollView showsVerticalScrollIndicator={false}>
                <View style={{ paddingBottom: insets.bottom + 16 }}>
                    <View style={{ flexDirection: 'row', paddingHorizontal: 8 }}>
                        <Text style={{ fontFamily: fontFamily.bold, fontSize: 16, padding: 8, flex: 1, textTransform: 'capitalize' }}>
                            {moment().format('MMMM, YYYY')}
                        </Text>
                        <TouchableOpacity onPress={() => {
                            dispatch(setDate(moment().format('YYYY-MM-DD')))
                            if (Platform.OS === 'web') window.history.back()
                            else navigation.pop()

                        }}>
                            <Text style={{ fontFamily: fontFamily.bold, fontSize: 16, padding: 8 }}>Hôm nay</Text>
                        </TouchableOpacity>
                    </View>

                    <Calendar
                        theme={{
                            'stylesheet.calendar.header': {
                                dayHeader: {
                                    color: colors.text,
                                    fontSize: 16,
                                    fontFamily: fontFamily.bold
                                },
                                header: {
                                    height: 0,
                                    opacity: 0
                                },
                            }
                        }}
                        hideArrows={true}
                        firstDay={1}
                        disableMonthChange={true}
                        dayComponent={({ date }) => {
                            let day_lunar = moment(date.dateString).lunar().format('D')
                            let month_lular = moment(date.dateString).lunar().format('M')
                            if (moment().format('M') == date.month)
                                return <TouchableOpacity style={{
                                    justifyContent: 'center', alignItems: 'center',
                                    opacity: moment().add(-1, 'day') < moment(date.dateString) ? 1 : 0.4
                                }} onPress={() => {
                                    if (moment().add(-1, 'day') < moment(date.dateString)) {
                                        dispatch(setDate(moment(date.dateString).format('YYYY-MM-DD')))
                                        if (navigation.canGoBack())
                                            navigation.navigate({
                                                name: from_route,
                                                params: {
                                                    date: moment(date.dateString).format('YYYY-MM-DD')
                                                },
                                                merge: true,
                                            })
                                        else window.history.back()
                                    }
                                }}>
                                    <View style={{
                                        height: 40, width: 48, position: 'relative',
                                        backgroundColor: date_prop === date.dateString ? colors.primary : 'transparent',
                                        justifyContent: 'center', alignItems: 'center', borderRadius: 8
                                    }}>
                                        <Text style={{ fontSize: 16, top: 4, left: 10, position: 'absolute', lineHeight: 22, fontFamily: 'Nunito_400Regular' }}>{date.day}</Text>
                                        <Text style={{
                                            fontSize: 11, opacity: 0.4, position: 'absolute', bottom: 4, right: 10, lineHeight: 15, fontFamily: 'Nunito_400Regular'
                                        }}>{day_lunar === '1' ? `${day_lunar}/${month_lular}` : day_lunar}</Text>
                                    </View>
                                </TouchableOpacity>
                            else return null
                        }}
                        minDate={moment().format('YYYY-MM-DD')}
                        maxDate={moment().endOf('month').format('YYYY-MM-DD')}
                    />

                    <View style={{ flexDirection: 'row', paddingHorizontal: 8 }}>
                        <Text style={{ fontFamily: fontFamily.bold, fontSize: 16, padding: 8, flex: 1, textTransform: 'capitalize' }}>
                            {moment().add(1, 'month').format('MMMM, YYYY')}
                        </Text>
                    </View>

                    <Calendar
                        theme={{
                            'stylesheet.calendar.header': {
                                dayHeader: {
                                    color: colors.text,
                                    fontSize: 16,
                                    fontFamily: fontFamily.bold
                                },
                                header: {
                                    height: 0,
                                    opacity: 0
                                },
                            }
                        }}
                        hideArrows={true}
                        firstDay={1}
                        disableMonthChange={true}
                        dayComponent={({ date }) => {
                            let day_lunar = moment(date.dateString).lunar().format('D')
                            let month_lular = moment(date.dateString).lunar().format('M')
                            console.log(date)
                            if (moment().add(1, 'month').format('M') == date.month)
                                return <TouchableOpacity style={{
                                    justifyContent: 'center', alignItems: 'center',
                                    opacity: moment().add(1, 'month').format('M') == date.month ? 1 : 0.4
                                }} onPress={() => {
                                    if (moment().add(1, 'month').format('M') == date.month) {
                                        dispatch(setDate(moment(date.dateString).format('YYYY-MM-DD')))
                                        if (navigation.canGoBack())
                                            navigation.navigate({
                                                name: from_route,
                                                params: {
                                                    date: moment(date.dateString).format('YYYY-MM-DD')
                                                },
                                                merge: true,
                                            })
                                        else window.history.back()
                                    }
                                }}>
                                    <View style={{
                                        height: 40, width: 48, position: 'relative',
                                        backgroundColor: date_prop === date.dateString ? colors.primary : 'transparent',
                                        justifyContent: 'center', alignItems: 'center', borderRadius: 8
                                    }}>
                                        <Text style={{ fontSize: 16, top: 4, left: 10, position: 'absolute', lineHeight: 22, fontFamily: 'Nunito_400Regular' }}>{date.day}</Text>
                                        <Text style={{
                                            fontSize: 11, opacity: 0.4, position: 'absolute', bottom: 4, right: 10, lineHeight: 15, fontFamily: 'Nunito_400Regular'
                                        }}>{day_lunar === '1' ? `${day_lunar}/${month_lular}` : day_lunar}</Text>
                                    </View>
                                </TouchableOpacity>
                            else return null
                        }}
                        current={moment().add(1, 'month').format('YYYY-MM-DD')}
                        minDate={moment().add(1, 'month').startOf('month').format('YYYY-MM-DD')}
                        maxDate={moment().add(1, 'month').endOf('month').format('YYYY-MM-DD')}
                    />
                </View>
            </ScrollView>
        </View>
    );
})