
import moment from 'moment'
import { ActionTypes } from './ActionTypes'

const initialState = {
    user_login: null,
    trip: null,
    date: moment().format('YYYY-MM-DD'),
    modal_journey: false,
    modal_trip_info: false,
    list_search: [],
}

export default (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case ActionTypes.SET_USER_LOGIN: {
            return {
                ...state,
                user_login: payload,
            }
        }
        case ActionTypes.SET_TRIP: {
            return {
                ...state,
                trip: payload,
            }
        }
        case ActionTypes.SET_DATE: {
            return {
                ...state,
                date: payload,
            }
        }
        case ActionTypes.SET_MODAL_JOURNEY: {
            return {
                ...state,
                modal_journey: payload,
            }
        }
        case ActionTypes.SET_MODAL_TRIP_INFO: {
            return {
                ...state,
                modal_trip_info: payload,
            }
        }
        case ActionTypes.SET_LIST_SEARCH: {
            return {
                ...state,
                list_search: payload,
            }
        }
    }

    return state
}